import { Injectable } from '@angular/core';
import { LeadService } from '@data/services/lead/lead.service';
import { ReportsService } from '@data/services/reports/reports.service';
import { CommonService } from '@shared/services/common/common.service';
import { first, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private _leadService: LeadService,
    private _reportService: ReportsService,
    private _commonService: CommonService,
  ) { }

  public getExportType(noticeTitle: string): string {
    let exportType: string = 'leadExport';

    switch (noticeTitle) {
      case 'Lead Export Current':
        exportType = 'leadCurrentPlan';
        break;

      case 'Multi-Client Rx Report':
        exportType = 'multiClientRxReport';
        break;

      case 'Multi-Client Pharmacy Report':
        exportType = 'multiClientPharmacyReport';
        break;

      case 'PDP Retention Report':
        exportType = 'leadExportPDPRetention';
        break;

      case 'AEP Disruption Report':
        exportType = 'aepDisruptionReport';
        break;

      default:
        exportType = 'leadExport';
        break;
    }

    return exportType;
  }

  public processExportDownloadNotification(exportType: string, notificationID: string, onError: Function) {
    let downloadExportSub$: Observable<any> = this._leadService.downloadExportFile(notificationID);

    switch (exportType) {
      case 'leadCurrentPlan':
        downloadExportSub$ = this._leadService.downloadLeadExportCurrentFile(notificationID)
        break;
      case 'multiClientRxReport':
        downloadExportSub$ = this._reportService.downloadLeadsByDrug(notificationID)
        break;
      case 'multiClientPharmacyReport':
        downloadExportSub$ = this._reportService.downloadLeadsByPharmacy(notificationID)
        break;
      case 'aepDisruptionReport':
        downloadExportSub$ = this._reportService.downloadLeadsByDisruption(notificationID)
        break;
      case 'leadExportPDPRetention':
        downloadExportSub$ = this._leadService.downloadExportPDPFile(notificationID)
        exportType = 'leadExportPDPRetention';
        break;
      default:
        downloadExportSub$ = this._leadService.downloadExportFile(notificationID)
        break;
    }

    this._parseCSV(downloadExportSub$, exportType, onError)
  }

  private _parseCSV(downloadExportSub$: Observable<any>, type: string, onError: Function) {
    downloadExportSub$.pipe(first())
      .subscribe({
        next: res => { this._commonService.leadExportParseCSV(res, type) },
        error: err => { onError.call(err.error.message) }
      });
  };

}
