import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CONTACT_RELATION, GENDER, MARITAL_STATUS, PHONE_TYPES, validatorPatterns } from '@app/constants';
import { AuthService } from '@app/service/auth.service';
import { AccountSettings } from '@data/admin/account-settings.interface';
import { LeadService } from '@data/services/lead/lead.service';
import * as stateData from '@data/states.json';
import { environment } from '@env/environment';
import { PermissionHistoryComponent } from '@modules/lead/components/permission-history/permission-history.component';
import { CheckDobDate, CheckInvalidDate, CommonService, ValidateDate } from '@shared/services/common/common.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import moment from 'moment';
import { Observable, Subject, Subscription, forkJoin } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-lead-detail',
  templateUrl: './lead-detail.component.html',
  styleUrls: ['./lead-detail.component.scss']
})
export class LeadDetailComponent implements OnInit {
  private accSettings:AccountSettings;
  private isFormChangeSubs: Subscription;
  private isLeadDEtailsSubs: Subscription;
  private leadVendorSearchSubs: Subscription;
  private getleadTypeSubs: Subscription;
  private getCampaignCodeSubs: Subscription;
  private getb2bpartnerSubs: Subscription;
  private getProductTypeSubs: Subscription;
  private getTagSearchSubs: Subscription;
  private getCampaignSegmentSubs: Subscription;
  private getTrackingCodeSubs: Subscription;
  private getContactPermissionHistorySubs: Subscription;
  private saveLeadDetailsSubs: Subscription;
  private _componentDestroyed$ = new Subject<boolean>();
  private requests_list: any = [];
  public leadDeatilsForm: UntypedFormGroup;
  public state_data: any= [];
  public lead_id: any = 0;
  public tags: any = [];
  public addOnBlur = true;
  public lead_vendor_data: any;
  public lead_type: any;
  public campaign_code: any;
  public campaign_segment: any;
  public b2bPartner_data: any;
  public product_type: any;
  public tag_data: any;
  public marital_data = MARITAL_STATUS;
  public relation_data = CONTACT_RELATION;
  public contact_data = PHONE_TYPES;
  public gender_data = GENDER;
  public isLoading: boolean = false;
  public lead_details: any;
  public isCommonFlag: boolean = false;
  public newTag = new FormControl('');
  public filteredTags: Observable<any[]>;
  public lodEnabled:boolean = false;

  public message: any = {};
  public invalidRuleViolation = false;

  public hasChange: boolean = false;
  public isEmergency: boolean = false;
  public tracking_data: any = [];
  public maxDate = new Date();
  public partAB_MinDate = new Date('01/01/1965');

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor(
    @Inject(MAT_DIALOG_DATA) public leadId: any,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private leadService: LeadService,
    private commonService: CommonService,
    private _toasMsgService: ToastMsgService,
    public dialog: MatDialog,
    private http: HttpClient,
    public dialogRef: MatDialogRef<LeadDetailComponent>,
  ) {
    this.lead_id = leadId;
    this.leadDeatilsForm = this.fb.group({
      title: [''],
      fname: [''],
      mname: [''],
      lname: [''],
      dob: ['', [CheckInvalidDate, CheckDobDate]],
      gender: [''],
      marital_status: [''],
      height_feet: [''],
      height_inches: [''],
      weight: [''],
      ssn: [''],
      medicare_name: [''],
      medicaid_number: [''],
      medicaid_state: [''],
      hicn: [''],
      medicare_parta_date: ['', [ValidateDate]],
      medicare_partb_date: ['', [ValidateDate]],
      phones: this.fb.array([this.newPhones()]),
      source_vendor_id: [''],
      source_adcode: [''],
      b2bpartner_id: [''],
      source_code: [''],
      campaign_id: [''],
      campaign_segment_code: [''],
      lead_type_id: [''],
      product_type_id: [''],
      emails: this.fb.array([this.newEmail()]),
      addresses: this.fb.array([this.newAddress()]),
      tags: this.newTag,
      do_not_email: [''],
      do_not_call: [''],
      do_not_text: [''],
      do_not_mail: [''],
      disposition_date: [''],
      dispo_name: [''],
      dispo_log: [''],
      contact_name: [''],
      contact_phone_type: [''],
      contact_phone: [''],
      contact_relationship: [''],
      isEmergencyContact: ['no'],
      emergency_name: [''],
      emergency_phone_type: [''],
      emergency_phone: [''],
      emergency_relationship: ['']
    });
    /* get states data */
    this.state_data = (stateData as any).default;
    this.isFormChangeSubs = this.leadService.getFormChangeAlert().subscribe(response => {
      if (response) {
        this.hasChange = true;
      }
    });
    this.commonService.getAccountSettings(this.auth.account_id)
    .pipe(first())
    .subscribe({
      next: (acc: AccountSettings) => {
        this.accSettings = acc;
        this.lodEnabled = this.accSettings.lead_manage_tracking_number_feature_enabled == "1";
      },
      error: (err) => { },
      complete: () => { }
    })

  }

  public ngOnChanges() {
    this.onCreateGroupFormValueChange();
  }

  ngOnInit(): void {
    this.getLeadVendor();
    this.getleadType();
    this.getCampaignCode();
    this.getB2BPartner();
    this.getProductType();
    this.getTagSearch();
    this.getLeadDetails();
    this.onCreateGroupFormValueChange();

    window.addEventListener('beforeunload', (event) => {
      if (!this.leadDeatilsForm.pristine) {
        setTimeout(function () {
          var returnValue = confirm("You have unsaved content, are you sure you want to exit?");
          if (returnValue == true) {
            return true;
          }
          else {
            window.stop();
            return false;
          }
        });
        return;
      }
     });
  }

  //function to detect any changes in user profile form
  public onCreateGroupFormValueChange() {
    const initialValue = this.leadDeatilsForm.value;
    this.leadDeatilsForm.valueChanges.pipe(takeUntil(this._componentDestroyed$)).subscribe(value => {
      if (this.leadDeatilsForm.dirty && !this.isLoading) {
        if (value.isEmergencyContact == 1 && (!this.leadDeatilsForm.value['contact_name']
            && !this.leadDeatilsForm.value['contact_phone']
            && !this.leadDeatilsForm.value['contact_relationship'])) {
            this.hasChange = false;
          }else if(this.leadDeatilsForm.value['mname'] && !this.leadDeatilsForm.value['mname'].replace(/[^a-zA-Z]/g, '')){
             this.hasChange = false;
          } else {
            this.hasChange = Object.keys(initialValue).some(key =>
              this.leadDeatilsForm.value[key] != initialValue[key]
              );
          }
      }
    });
  }

  private getLeadDetails() {
    this.isLoading = true;
    this.isLeadDEtailsSubs = this.leadService.getLeadDetails(this.lead_id).subscribe({
      next: (res: any) => {
        if (res) {
          this.lead_details = res;
          //Emergency relationship
          let emergencyRelationship = this.lead_details.emergency_relationship;
          if (emergencyRelationship && emergencyRelationship != '') {
            let matched = this.relation_data.some(relationship => relationship.value == emergencyRelationship);
            if (!matched) {
              this.lead_details.emergency_relationship = "Other";
            }
          }

          //Preffered contact relationship
          let prefContactRelationship = this.lead_details.contact_relationship;
          if (prefContactRelationship && prefContactRelationship != '') {
            let matched = this.relation_data.some(relationship => relationship.value == prefContactRelationship);
            if (!matched) {
              this.lead_details.contact_relationship = "Other";
            }
          }
          if (this.lead_details?.b2bpartner_id) {
            this.getTrackingCode(this.lead_details.b2bpartner_id)
          }
          if (this.lead_details) {
            if (this.lead_details.tags) {
              this.tags = this.lead_details.tags;
            }
            if (this.lead_details.campaign_id) {
              this.changeCampaign(this.lead_details.campaign_id);
            }
            if(this.lead_details.emergency_phone_id.length && this.lead_details.emergency_phone_id ==
              this.lead_details.contact_phone_id.length && this.lead_details.contact_phone_id){
              this.isEmergency = false;
            }else{
              this.isEmergency = true;
            }
            this.leadDeatilsForm.patchValue({
              title: this.lead_details.title,
              fname: this.lead_details.fname,
              lname: this.lead_details.lname,
              mname: this.lead_details.mname,
              dob: this.lead_details.dob ? moment(this.lead_details.dob).format('MM/DD/YYYY') : '',
              gender: this.lead_details.gender,
              marital_status: this.lead_details.marital_status,
              height_feet: Math.floor(this.lead_details.height / 12) ? Math.floor(this.lead_details.height / 12) : '',
              height_inches: this.lead_details.height ? this.lead_details.height % 12 : '',
              weight: this.lead_details.weight,
              ssn: this.lead_details.ssn,
              contact_name: this.lead_details.contact_name,
              contact_phone_type: this.lead_details.contact_phone ? this.lead_details.contact_phone.phone_type : 'Home',
              contact_phone: this.lead_details.contact_phone ? this.lead_details.contact_phone.phone : '',
              contact_relationship: this.lead_details.contact_relationship,
              emergency_name: this.lead_details.emergency_name,
              emergency_phone_type: this.lead_details.emergency_phone ? this.lead_details.emergency_phone.phone_type : 'Home',
              emergency_phone: this.lead_details.emergency_phone ? this.commonService.phoneFormatter(this.lead_details.emergency_phone.phone) : '',
              emergency_relationship: this.lead_details.emergency_relationship,
              medicare_name: this.lead_details.medicare_name,
              medicaid_number: this.lead_details.medicaid_number,
              medicaid_state: this.lead_details.medicaid_state,
              hicn: this.lead_details.hicn,
              medicare_parta_date: this.lead_details.medicare_parta_date ? moment(this.lead_details.medicare_parta_date).format('MM/DD/YYYY') : '',
              medicare_partb_date: this.lead_details.medicare_partb_date ? moment(this.lead_details.medicare_partb_date).format('MM/DD/YYYY') : '',
              source_vendor_id: this.lead_details.source_vendor_id,
              source_adcode: this.lead_details.source_adcode,
              b2bpartner_id: this.lead_details.b2bpartner_id,
              source_code: this.lead_details.source_code,
              campaign_id: this.lead_details.campaign_id,
              campaign_segment_code: this.lead_details.campaign_segment_code,
              lead_type_id: this.lead_details.lead_type_id,
              product_type_id: this.lead_details.product_type_id,

              tags: '',
              do_not_email: this.lead_details.do_not_email == '1' ? false : true,
              do_not_call: this.lead_details.do_not_call == '1' ? false : true,
              do_not_text: this.lead_details.do_not_text == '1' ? false : true,
              do_not_mail: this.lead_details.do_not_mail == '1' ? false : true,

              disposition_date: this.lead_details.disposition_date ? this.lead_details.disposition_date : '',
              dispo_name: this.lead_details.dispo_name,
              dispo_log: this.lead_details.dispo_log,
              isEmergencyContact: (this.lead_details.emergency_name.length > 0
                && this.lead_details.emergency_name == this.lead_details.contact_name)
                && (this.lead_details.emergency_phone_id.length > 0 &&
                  this.lead_details.emergency_phone_id == this.lead_details.contact_phone_id)
                || (this.lead_details.emergency_relationship.length > 0 &&
                  this.lead_details.emergency_relationship == this.lead_details.contact_relationship) ? '1' : '0'
            }, { emitEvent: false });
            this.leadDeatilsForm.setControl('emails', this.commonService.setExistingEmail(this.lead_details.emails, this.lead_details.default_email_id, 'notReq'));
            this.leadDeatilsForm.setControl('phones', this.commonService.setExistingPhone(this.lead_details.phones, this.lead_details.default_phone_id, 'notReq'));
            this.leadDeatilsForm.setControl('addresses', this.commonService.setExistingAddress(this.lead_details.addresses, this.lead_details.default_address_id, 'notReq'));
            this.isCommonFlag = true;
            this.isLoading = false;
            this.leadService.sendLeadLedger("refreshLead");
            this.hasChange = false;
          }
        }
      }, error: err => {
        this.isLoading = false;
      }, complete: () => {
        this.hasChange = false;
        setTimeout(() => { this.isLoading = false; }, 2000);
      },
    })
  }

  /* method to get the lead source code */
  private getLeadVendor() {

    let criteria: any = {
      account_id: {
        type: "simple",
        value: [this.auth.account_id]
      }
    };

    if (this.commonService.hasPermission('super_user'))
      criteria.system_level_lead_vendor = {
        type: "simple",
        value: ["1"]
      };

    let payload = {
      "search_type": "simple",
      "criteria": criteria,
      "field_list": "lead_vendor_id,lead_vendor_code,company,account_id"
    }

    this.leadVendorSearchSubs = this.leadService.leadVendorSearch(payload).subscribe({
      next: (response: any) => {
        if (response.length > 0) {
          this.lead_vendor_data = response;
        }
      },
      error: (err) => { },
      complete: () => { }
    });
  }

  /* method to get the lead types */
  private getleadType() {
   this.getleadTypeSubs = this.leadService.getleadType().subscribe({
      next: (response: any) => {
        if (response.length > 0) {
          this.lead_type = response;
        }
      },
      error: (err) => { },
      complete: () => { }
    });
  }

  /* method to get the campaign code */
  private getCampaignCode() {
    let payload = {
      "search_type": "simple",
      "criteria": {
        "account_id": {
          "type": "simple",
          "value": [this.auth.account_id]
        }
      },
      "orderby_field": "campaign_code",
      "orderby_direction": "asc",
      "field_list": ""
    }
   this.getCampaignCodeSubs = this.leadService.getCampaignCode(payload).subscribe({
      next: (response: any) => {
        if (response.length > 0) {
          this.campaign_code = response;
        }
      },
      error: (err) => { },
      complete: () => { }
    });
  }

  /* method to get the client/partner/affiliate */
  private getB2BPartner() {
    this.getb2bpartnerSubs = this.leadService.getb2bpartner('lead-detail').subscribe({
      next: (res: any) => {
        if (res != '' && res.length > 0)
          this.b2bPartner_data = res;
      }, error: (err: any) => { }
    });
  }

  /* method to get the product type */
  private getProductType() {
    let payload = { "search_type": "simple", "criteria": { "product_type_id": { "type": "range", "value": [1, "*"] } }, "field_list": "" }
   this.getProductTypeSubs = this.leadService.getProductType(payload).subscribe({
      next: (res: any) => {
        if (res != '' && res.length > 0)
          this.product_type = res;
      }, error: (err: any) => { }
    });
  }

  /* method to get the tegs */
  private getTagSearch() {
    let payload = {
      "search_type": "simple",
      "criteria": {
        "account_id": {
          "type": "simple",
          "value": [this.auth.account_id]
        }
      },
      "field_list": "",
      "page_number": 1,
    }

   this.getTagSearchSubs =  this.leadService.getTagSearch(payload).subscribe({
      next: (res: any) => {
        if (res != '' && res.length > 0) {
          this.tag_data = res;
          this.filteredTags = this.leadDeatilsForm.get('tags')!.valueChanges
            .pipe(
              filter((input) => { return input && input.length > 0 }),
              map((value: any) => {
                return (typeof value === 'string') ? value : value.tag_name
              }),
              map(tag => this._filter(tag))
            );
        }
      }, error: (err: any) => { }
    });
  }

  /* method to display the tag name */
  public getTagName(tags: any) {
    return tags && tags?.tag_name ? tags?.tag_name : '';
  }

  /* method to select the tag name */
  public selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.value);
    this.tagInput.nativeElement.value = '';
    this.leadDeatilsForm.value.tags = '';
  }

  /* method to filter the tag name */
  private _filter(tagName: string) {
    const filterValue = tagName.toLowerCase();
    return this.tag_data.filter(
      (tag: any) => tag.tag_name.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  /* method to change the campaign */
  public changeCampaign(campaign_id: string) {
    if(campaign_id != ''){
      this.campaign_segment = [];
      let payload = {
        "search_type": "simple",
        "criteria": {
          "campaign_id": {
            "type": "simple",
            "value": [campaign_id]
          }
        },
        "orderby_field": "campaign_segment_code",
        "orderby_direction": "asc",
        "field_list": ""
      }
     this.getCampaignSegmentSubs = this.leadService.getCampaignSegment(payload).subscribe({
        next: (res: any) => {
          if (res != '' && res.length > 0)
            this.campaign_segment = res;
        }, error: (err: any) => { }
      });
    }else{
      this.campaign_segment = [];
    }
  }

  /* ::::::::::::::::::::::::::::: check height/weight ::::::::::::::::::::::::::::: */

  public checkFeetHeight(event: any) {
    if (this.isValidNumber(event.value)) {
      if (event.value > 8) {
        this.leadDeatilsForm.patchValue({ 'height_feet': '8' });

        this.message = this._toasMsgService.showError('The maximum height allowabale (in feet) is 8 feet. The application will not allow for values higher. The height has been set as 8 feet.', true);
        this.clearMessage(30000);
        return false;

      } else
        return true;

    } else {
      this.message = this._toasMsgService.showError('Height will only accept numeric values.', true);
      this.clearMessage(30000);
      return false;
    }
  }

  public checkInchesHeight(event: any) {
    if (this.isValidNumber(event.value)) {
      if (event.value > 12) {
        this.leadDeatilsForm.patchValue({ 'height_inches': '12' });
        this.message = this._toasMsgService.showError('The maximum height allowabale (in inches) is 12 inches. The application will not allow for values higher.The height has been set as 12 inches.', true);
        this.clearMessage(30000);
        return false;

      } else
        return true;

    } else {
      this.message = this._toasMsgService.showError('Height will only accept numeric values.', true);
      this.clearMessage(30000);
      return false;
    }
  }

  public checkWeight(event: any) {
    if (this.isValidNumber(event.value)) {
      if (event.value > 999) {
        this.leadDeatilsForm.patchValue({ 'weight': '999' });
        this.message = this._toasMsgService.showError('The maximum weight allowabale (in lbs) is 999 lbs. The application will not allow for values higher. The weight has been set as 999 lbs.', true);
        this.clearMessage(30000);
        return false;

      } else
        return true;

    } else {
      this.message = this._toasMsgService.showError('Weight will only accept numeric values.', true);
      this.clearMessage(30000);
      return false;
    }
  }

  private isValidNumber(number: any) {
    return (number === '') || this.isNumeric(number);
  }

  private isNumeric(n: any) {
    if ((!isNaN(parseFloat(n)) && isFinite(n)) == false) {
      this.invalidRuleViolation = true;
    }
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  /* ::::::::::::::::::::::::::::: end check height/weight ::::::::::::::::::::::::::::: */

  /* :::::::::::::::::::: add phone & email & address functionality ::::::::::::::::::: */

  private newPhones() {
    return this.fb.group({
      phone_type: ['Home'],
      phone: new FormControl('', [Validators.minLength(14)]),
      phone_ext: [''],
      phone_id: [''],
      phone_class: [''],
      isSelected: ""
    });
  }

  private newEmail() {
    return this.fb.group({
      email_type: ['Home'],
      email: new FormControl('', [Validators.pattern(validatorPatterns.EMAIL)]),
      email_id: [''],
      email_class: [''],
      isSelected: ""
    });
  }

  private newAddress() {
    return this.fb.group({
      address_type_id: ['Home'],
      address1: new FormControl(''),
      address2: '',
      city: new FormControl(''),
      state_id: new FormControl(''),
      zip: new FormControl('', [Validators.minLength(5)]),
      county: '',

      address_id: [''],
      apo: [''],
      country_id: [''],
      county_fips: [''],
      zip_4: [''],
      address_class: [''],
      isSelected: ""
    });
  }

  /* :::::::::::::::::::: end phone & email & address functionality ::::::::::::::::::: */

  /* ::::::::::::::::: clear preferred & emengency contact functionality :::::::::::: */

  public clearPreferredContact() {
    this.hasChange = true;
    this.leadDeatilsForm.patchValue({ 'contact_name': '', 'contact_phone_type': '', 'contact_phone': '', 'contact_relationship': '' })
  }

  public clearEmergencyContact(contact_name?:string) {
    this.hasChange = true;
    this.leadDeatilsForm.patchValue({ 'emergency_name': contact_name ? contact_name : '', 'emergency_phone_type': '', 'emergency_phone': '', 'emergency_relationship': '' })
  }

  public radioChange(event: any) {
    if (event.value == '1') {
      this.isEmergency = false;
      this.clearEmergencyContact(this.leadDeatilsForm.value.contact_name);
    } else {
      this.isEmergency = true;
      this.clearEmergencyContact();
    }
  }

  public b2bChange(b2bpartner_id: string) {
    this.leadDeatilsForm.patchValue({'source_code' : ''});
    if(b2bpartner_id != ''){
      this.getTrackingCode(b2bpartner_id);
    }else{
      this.tracking_data.push({tracking_code: ""});
    }
  }

  private getTrackingCode(b2bpartner_id: string) {
    this.tracking_data.push({tracking_code: ""});
   this.getTrackingCodeSubs = this.leadService.getTrackingCode(b2bpartner_id).subscribe({
      next: (res: any) => {
        if (res != '' && res.length > 0) {
          this.tracking_data = res;
          if (this.lead_details.b2bpartner_id == b2bpartner_id) {
            this.tracking_data.push({
              tracking_code: this.lead_details.source_code
            })
          }
        }else{
          this.tracking_data = [];
        }
      }, error: (err: any) => {
        this.message = {
          type: 'alert',
          text: err?.message,
        };
        this.clearMessage(30000);
      }
    });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.leadDeatilsForm.controls[controlName].hasError(errorName);
  }

  /* ::::::::::::::::: end clear preferred & emengency contact functionality :::::::::::: */

  /* ::::::::::::::::::::::::::::: add tags functionality ::::::::::::::::::::::::::::: */

  public addTag(event: MatChipInputEvent, type: any): void {
    this.hasChange = true;
    let value = (event.value || '').trim();
    // Add our tag
    if (type == 'btn') {
      value = event.toString();
    }
    if (value) {
      this.tags.push({ 'tag_name': value });
    }
    // Clear the input value
    if (event?.chipInput) {
      event?.chipInput!.clear();
      this.leadDeatilsForm.value.tags = '';
      this.tagInput.nativeElement.value = '';
    }
    if (type == 'btn') {
      this.leadDeatilsForm.value.tags = '';
      this.tagInput.nativeElement.value = '';
    }

  }

  public removeTag(tag: any): void {
    this.hasChange = true;
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  /* ::::::::::::::::::::::::::::: end tags functionality ::::::::::::::::::::::::::::: */

  public openPermissionHistory() {
    window.scroll(0, 0);
    let payload = {
      "search_type": "simple",
      "criteria": {},
      "field_list": "",
      "page_number": 1,
      "records_per_page": "",
      "orderby_field": "lead_contact_permission_change_date"
    }
   this.getContactPermissionHistorySubs = this.leadService.getContactPermissionHistory(this.lead_id, payload).subscribe({
      next: (response: any) => {
        const dialogRef = this.dialog.open(PermissionHistoryComponent, {
          width: '70%',
          autoFocus: false,
          data: response
        });
      },
      error: (err) => { }
    })

  }

  public updateLead(type: string) {
    if (this.leadDeatilsForm.invalid) {
      this.leadService.sendErrorAlert('invalidForm');
      return
    } else {
      this.isLoading = true;
      let formData = this.leadDeatilsForm.value;
      let height: any;
      if (isNaN(parseInt(formData.height_feet)) && isNaN(parseInt(formData.height_inches))) {
        height = "";
      } else if (isNaN(parseInt(formData.height_feet))) {
        height = parseInt(formData.height_inches);
      } else if (isNaN(parseInt(formData.height_inches))) {
        height = parseInt(formData.height_feet) * 12;
      } else {
        height = (parseInt(formData.height_feet) * 12) + parseInt(formData.height_inches);
      }

      /* ------------------- addresses functionality ------------------- */
      let newAddressObj: any;
      let addressArray = formData.addresses;
      let defaultAddress = addressArray.filter((obj: any) => obj.isSelected == true && obj.address_class == '');
      addressArray = addressArray.filter((obj: any) => obj.address_class == '');

      /* call delete API the uncheck address */
      let deleteAddresses = formData.addresses;
      let deleteAddress = deleteAddresses.filter((obj: any) => obj.address_class != '');
      let request;
      if (deleteAddress) {
        deleteAddress.forEach((element: any) => {
          request = this.http.get(environment.api + 'lead/' + this.lead_id + '/address/' + element.address_id + '/delete/');
          if (request) this.requests_list.push(request);
        });
      }
      if (addressArray) {
        newAddressObj = this.commonService.getAddresses(addressArray);
      }
      /* ------------------- end addresses functionality ------------------- */

      /* ------------------- email functionality ------------------- */
      let newEmailObj;
      let emailArray = formData.emails;
      let defaultEmail = emailArray.filter((obj: any) => obj.isSelected == true && obj.email_class == "");
      emailArray = emailArray.filter((obj: any) => obj.email_class == '');

      /* call delete API the uncheck email */
      let deleteEmails = formData.emails;
      let deleteEmail = deleteEmails.filter((obj: any) => obj.email_class != '');
      if (deleteEmail) {
        deleteEmail.forEach((element: any) => {
          if (element.email_id) {
            request = this.http.get(environment.api + 'lead/' + this.lead_id + '/email/' + element.email_id + '/delete/');
            if (request) this.requests_list.push(request);
          }
        });
      }

      if (emailArray) {
        newEmailObj = this.commonService.getEmails(emailArray);
      }
      /* ------------------- end email functionality ------------------- */

      /* ------------------- phone functionality ------------------- */
      let newPhoneObj: any;
      let phoneArray = formData.phones;
      let defaultPhone = phoneArray.filter((obj: any) => obj.isSelected == true && obj.phone_class == "");
      phoneArray = phoneArray.filter((obj: any) => obj.phone_class == '');

      /* call delete API the uncheck phone */
      let deletePhones = formData.phones;
      let deletePhone = deletePhones.filter((obj: any) => obj.phone_class != '');
      if (deletePhone) {
        deletePhone.forEach((element: any) => {
          if (element.phone_id) {
            request = this.http.get(environment.api + 'lead/' + this.lead_id + '/phone/' + element.phone_id + '/delete/');
            if (request) this.requests_list.push(request);
          }
        });
      }
      if (phoneArray) {
        newPhoneObj = this.commonService.getPhones(phoneArray);
      }
      /* ------------------- end phone functionality ------------------- */

      if (formData.contact_phone.length < 10) formData.contact_phone = '';
      if (formData.emergency_phone.length < 10) formData.emergency_phone = '';
      this._cleanUpPhoneEmailData(newEmailObj, 'email');
      this._cleanUpPhoneEmailData(newPhoneObj, 'phone');
      formData['dob'] = formData.dob ? moment(formData.dob).format('MM/DD/YYYY') : "",
        formData['age'] = formData.dob ? moment().diff(formData.dob, 'year') : '';
      formData['height'] = height;
      formData['medicare_parta_date'] = formData.medicare_parta_date ? moment(formData.medicare_parta_date).format('MM/DD/YYYY') : "",
        formData['medicare_partb_date'] = formData.medicare_partb_date ? moment(formData.medicare_partb_date).format('MM/DD/YYYY') : "",
        formData['do_not_call'] = formData.do_not_call ? "0" : "1";
      formData['do_not_text'] = formData.do_not_text ? "0" : "1";
      formData['do_not_email'] = formData.do_not_email ? "0" : "1";
      formData['do_not_mail'] = formData.do_not_mail ? "0" : "1";
      formData['account_id'] = this.lead_details?.account_id;
      formData['user_id'] = this.lead_details?.user_id;
      formData['default_address_id'] = defaultAddress[0]?.address_id ? defaultAddress[0]?.address_id : "";
      formData['account_business_name'] = this.lead_details?.account_business_name;
      formData['default_address'] = {
        default_address: true,
        address1: defaultAddress[0]?.address1 ? defaultAddress[0]?.address1 : "",
        address2: defaultAddress[0]?.address2 ? defaultAddress[0]?.address2 : "",
        address_id: defaultAddress[0]?.address_id ? defaultAddress[0]?.address_id : "",
        address_type_id: defaultAddress[0]?.address_type_id ? defaultAddress[0]?.address_type_id : "",
        apo: defaultAddress[0]?.apo ? defaultAddress[0]?.apo : "",
        city: defaultAddress[0]?.city ? defaultAddress[0]?.city : "",
        country_id: defaultAddress[0]?.country_id ? defaultAddress[0]?.country_id : "",
        county: defaultAddress[0]?.county ? defaultAddress[0]?.county : "",

        county_fips: defaultAddress[0]?.county_fips ? defaultAddress[0]?.county_fips : "",
        state_id: defaultAddress[0]?.state_id ? defaultAddress[0]?.state_id : "",
        zip: defaultAddress[0]?.zip ? defaultAddress[0]?.zip : "",
        zip_4: defaultAddress[0]?.zip_4 ? defaultAddress[0]?.zip_4 : ""
      };
      formData['default_email_id'] = defaultEmail[0]?.email_id ? defaultEmail[0]?.email_id : "";
      formData['default_email'] = {
        default_email: true,
        email: defaultEmail[0]?.email ? defaultEmail[0]?.email : "",
        email_id: defaultEmail[0]?.email_id ? defaultEmail[0]?.email_id : "",
        email_type: defaultEmail[0]?.email_type ? defaultEmail[0]?.email_type : ""
      };
      formData['default_phone_id'] = defaultPhone[0]?.phone_id ? defaultPhone[0]?.phone_id : "",
        formData['default_phone'] = {
          default_phone: true,
          phone: defaultPhone[0]?.phone ? defaultPhone[0]?.phone : "",
          phone_ext: defaultPhone[0]?.phone_ext ? defaultPhone[0]?.phone_ext : "",
          phone_id: defaultPhone[0]?.phone_id ? defaultPhone[0]?.phone_id : "",
          phone_type: defaultPhone[0]?.phone_type ? defaultPhone[0]?.phone_type : ""
        },
        formData['addresses'] = newAddressObj,
        formData['dispo_log'] = formData.dispo_log,
        formData['emails'] = newEmailObj,
        formData['lead_instructions'] = this.lead_details?.lead_instructions ? this.lead_details?.lead_instructions : "",
        formData['phones'] = newPhoneObj,
        formData['tags'] = this.tags,
        formData['contact_phone'] = { 'phone_type': formData.contact_phone_type, 'phone': this.commonService.phoneFormatter(formData.contact_phone), 'phone_ext': "" },
        formData['emergency_phone'] = { 'phone_type': formData.emergency_phone_type, 'phone': this.commonService.phoneFormatter(formData.emergency_phone), 'phone_ext': "" },
        formData['lead_vendor_id'] = formData?.source_vendor_id,
        this._cleanupFormData(formData);
      if (this.requests_list.length > 0) {
        forkJoin(this.requests_list).subscribe({
          next: (response: any) => {
            if (response)
              this.saveLeadDetails(this.lead_id, formData, type);
          },
          error: (err: any) => {
            this.message = {
              type: 'alert',
              text: err.error.message
            };
            this.clearMessage(30000);
          },
          complete: () => { }
        });
      } else {
        this.saveLeadDetails(this.lead_id, formData, type);
      }
    }
  }

  private _cleanupFormData(formData: any) {
    if (formData) {
      delete formData.contact_phone_type;
      delete formData.emergency_phone_type;
      delete formData.height_feet;
      delete formData.height_inches;
      delete formData.isEmergencyContact;
    }
    if (formData.contact_phone.phone == '') {
      formData['contact_phone_id'] = "";
      delete formData.contact_phone
    }
    if (formData.emergency_phone.phone == '') {
      this.isEmergency ? formData['emergency_phone_id'] = "" : formData['emergency_phone_id'] = this.lead_details.contact_phone_id
      delete formData.emergency_phone
    }
    return formData;
  }

  private _cleanUpPhoneEmailData(newPhoneEmailObj: any, type: string) {
    let index = newPhoneEmailObj.findIndex((x: any) => x[type] == '');
    if (index != -1) {
      newPhoneEmailObj.splice(index, 1);
    }
    return newPhoneEmailObj;
  }

  public checkForPermissions() {
    return !(!this.lead_details?.lead_vendor_id || (this.commonService.hasPermission('lead_origination_data_edit') && this.lead_vendor_data && this.lead_vendor_data?.length > 0) || this.commonService.hasPermission('super_user'))
  }

  /* clear notice message */
  public clearMessage(timer: number) {
    setTimeout(() => {
      this.message = this._toasMsgService.resetMessage();
    }, timer);
  }

  public captureClose(){
    if(!this.leadDeatilsForm.pristine)
    this.commonService.unsavedChangesNotice();
    else
    this.dialog.closeAll();
  }

  public filterAlphaCharacters(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const alphaOnlyValue = inputElement.value.replace(/[^a-zA-Z]/g, ''); // Remove non-alpha characters
    this.leadDeatilsForm.controls['mname'].setValue(alphaOnlyValue, {
      emitEvent: false, // Prevent triggering value change events
    });
  }

  private saveLeadDetails(leadId: string, payload: any, type: string) {
   this.saveLeadDetailsSubs =  this.leadService.saveLeadDetails(leadId, payload).subscribe({
      next: (response: any) => {
        this.requests_list = [];
        this.leadService.lead_details = response;
        this.message = {
          type: 'success',
          text: "You have successfully updated your lead " + '(' + payload.fname + ' ' + payload.lname + ')'
        };
        this.clearMessage(15000);
        if (type == 'close') {
          this.hasChange = false;
          this.isLoading = false
          this.leadService.lead_details = response;
          this.leadService.sendLeadLedger("refreshLead");
          this.closeModal();
          this._toasMsgService.showSuccess("You have successfully updated your lead " + '(' + payload.fname + ' ' + payload.lname + ')');
        } else {
          this.getLeadDetails();
          this.leadService.lead_details = response;
          this.leadDeatilsForm.markAsPristine();
          this.hasChange = false;
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.hasChange = false;
        this.message = {
          type: 'alert',
          text: err.error.message
        };
        this.clearMessage(30000);
      }
    })
  }

  public closeModal(){
    this.dialog.closeAll();
  }

  public ngOnDestroy() {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
    if (this.isFormChangeSubs) this.isFormChangeSubs.unsubscribe();
    if (this.isLeadDEtailsSubs) this.isLeadDEtailsSubs.unsubscribe();
    if (this.leadVendorSearchSubs) this.leadVendorSearchSubs.unsubscribe();
    if (this.getleadTypeSubs) this.getleadTypeSubs.unsubscribe();
    if (this.getCampaignCodeSubs) this.getCampaignCodeSubs.unsubscribe();
    if (this.getb2bpartnerSubs) this.getb2bpartnerSubs.unsubscribe();
    if (this.getProductTypeSubs) this.getProductTypeSubs.unsubscribe();
    if (this.getTagSearchSubs) this.getTagSearchSubs.unsubscribe();
    if (this.getCampaignSegmentSubs) this.getCampaignSegmentSubs.unsubscribe();
    if (this.getTrackingCodeSubs) this.getTrackingCodeSubs.unsubscribe();
    if (this.getContactPermissionHistorySubs) this.getContactPermissionHistorySubs.unsubscribe();
    if (this.saveLeadDetailsSubs) this.saveLeadDetailsSubs.unsubscribe();
    this.leadDeatilsForm.markAsPristine();
  }

}
