<form autocomplete="off" [formGroup]="emailForm">
  <div class="flex justify-between items-baseline">
    <h4 class="user-profile-heading">Emails</h4>
    <button mat-icon-button matTooltip="Add Entry" [matMenuTriggerFor]="emailMenu">
      <mat-icon class="plus">add</mat-icon>
    </button>
  </div>

  <div class="flex">
    <mat-menu #emailMenu="matMenu">
      <ng-container *ngFor="let content of email_content">
        <button *ngIf="!content.isSelected" (click)="addEmailType(content)" mat-menu-item>{{content.name}}</button>
      </ng-container>
    </mat-menu>
  </div>

  <div formArrayName="emails">
    <ng-container *ngFor="let email of emailListArr?.controls; let i=index" [formGroupName]="i">
      <div class="flex w-full gap-3 place-items-center">
        <mat-form-field class="w-[25%]">
          <mat-label>Type</mat-label>
          <mat-select disableOptionCentering matNativeControl formControlName="email_type" [disabled]="email?.value?.email_class == 'true'" [ngClass]="{'line-through': email?.value?.email_class == 'true'}">
            <mat-option *ngFor="let content of email_content" value="{{content.name}}">{{content.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-[65%]">
          <mat-label>Email</mat-label>
          <input [class]="email?.value?.email_class == 'true' ? 'not-allowed' : ''" [readonly]="email?.value?.email_class == 'true'"  [appInputMaxLength] matInput autocomplete="off" formControlName="email" [ngClass]="{'line-through': email?.value?.email_class == 'true'}" appDisableAutofill>
          <mat-error *ngIf="email?.get('email')?.errors && submitted">
            Please enter correct email
          </mat-error>
        </mat-form-field>
        <button mat-icon-button class="close" title="Undo" *ngIf="email?.value?.email_class == ''" (click)="clearEmail(email.value,i)"><mat-icon>close</mat-icon></button>
        <button mat-icon-button *ngIf="email?.value?.email_class == 'true'" (click)="refreshEmail(email.value,i)"><mat-icon>replay</mat-icon></button>
        <mat-checkbox *ngIf="type!='admin'" (change)="emailCheckBoxvalue($event,email.value,i)" [checked]="email?.value?.isSelected==true || type == 'plan' || type=='add-user'"></mat-checkbox>
      </div>
    </ng-container>
  </div>
</form>
