import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarService } from '@data/services/calendar/calendar.service';
import { CommonService } from '@shared/services/common/common.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss']
})
export class AddEventComponent implements OnInit, OnDestroy {
  private aptChange:boolean = false;
  private taskChange:boolean = false;
  private _componentDestroyed$ = new Subject<boolean>();
  public calendarData: any;
  public selectedTabIndex: number = 0;
  public isAppointment:boolean=false;
  public isMode : boolean = true;
  constructor(
    private calendarService : CalendarService,
    private commonService:CommonService,
    @Inject(MAT_DIALOG_DATA) public event: any,
    public dialogRef: MatDialogRef<AddEventComponent>
  ) {
    this.calendarData = this.event;
    switch (this.event['tabName']) {
      case 'Appointment':
        this.selectedTabIndex = 0;
        break;
      case 'Task':
        this.selectedTabIndex = 1;
        break;
    }
    if(event.type!='add'){
      if(this.event.allEndTime || this.event.end || this.event.allDay == true){
        this.isAppointment = true;
      }
      this.calendarData = this.event;
      this.calendarService.getTitle().pipe(takeUntil(this._componentDestroyed$)).subscribe(response =>{
        if(response){
          this.isMode = false;
        }
      })
    }
  }

  public ngOnInit(): void {
  }

  public tabClick(event: any) {
    this.event['tabName'] = event.tab.textLabel;
  }

  public onAdd(data: any) {
    if (data) {
      this.dialogRef.close();
    }
  }

  public sendAptChange(value:boolean){
     this.aptChange = value;
  }

  public sendTaskChange(value:boolean){
   this.taskChange = value;
 }

  public closeModal(){
    if(this.aptChange || this.taskChange)
     this.commonService.unsavedChangesNotice();
     else
     this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
  }
}
