<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
    <h2 mat-dialog-title>Contact Permission Update History</h2>
    <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>

  <mat-divider></mat-divider>
  <div mat-dialog-content class="mat-typography">
    <div class="table-container">
      <table mat-table [dataSource]="contactDataSource" matSortActive="lead_contact_permission_change_date" matSortDirection="asc" #contactSort="matSort" matSort>
        <ng-container matColumnDef="lead_contact_permission_type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact Permission </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row?.lead_contact_permission_type == 'do_not_call'">Call</span>
            <span *ngIf="row?.lead_contact_permission_type == 'do_not_email'">Email</span>
            <span *ngIf="row?.lead_contact_permission_type == 'do_not_mail'">Mail</span>
            <span *ngIf="row?.lead_contact_permission_type == 'do_not_text'">Text</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="lead_contact_permission_to_contact">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row?.lead_contact_permission_to_contact == 0">ON</span>
            <span *ngIf="row?.lead_contact_permission_to_contact == 1">OFF</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="lead_contact_permission_change_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date/Time </th>
          <td mat-cell *matCellDef="let row"> {{row?.lead_contact_permission_change_date | date : 'MM/dd/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="login">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
          <td mat-cell *matCellDef="let row">{{row?.lead_contact_permission_change_user.login}}</td>
        </ng-container>

        <ng-container matColumnDef="fname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row">{{row?.lead_contact_permission_change_user.fname + ' ' + row?.lead_contact_permission_change_user.lname}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="contactDisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: contactDisplayedColumns;">
        </tr>
      </table>
    </div>
  </div>
  <div class="p-2" mat-dialog-actions align="end">
    <button mat-button mat-raised-button mat-dialog-close>Close</button>
  </div>
</div>