import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TOOLBAR } from '@app/constants';
import { AuthService } from '@app/service/auth.service';
import { Lead } from '@data/lead/lead.interface';
import { CalendarService } from '@data/services/calendar/calendar.service';
import { MomentService } from '@shared/services/moment/moment.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import moment from 'moment';
import { Editor } from 'ngx-editor';
import { LeadService } from '@data/services/lead/lead.service';
import { ConfirmDeleteComponent } from '@shared/modals/confirm-delete/confirm-delete.component';
import { Router } from '@angular/router';
import { StartEndTimeValidator } from '@shared/helper/start-end-time.validator';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CommonService } from '@shared/services/common/common.service';
@Component({
  selector: 'app-add-appointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.scss']
})
export class AddAppointmentComponent implements OnInit,OnDestroy {
  @Input() isFromCalendar: any;
  @Output() addEvent = new EventEmitter<string>();
  @Output() aptChange = new EventEmitter();
  private lead_detail: any = [];
  private _componentDestroyed$ = new Subject<boolean>();
  private isAddConvAptSubs: Subscription;
  private isAddAptSubs: Subscription;
  private isAddDelSubs: Subscription;
  public appointment_id: string = "";
  public editor: Editor; // Editor;
  public toolbar: any = TOOLBAR;
  public addAppointmentForm!: FormGroup;
  public displayAssignLead: boolean = false;
  public selectedLead: any;
  public isAddAppointment: boolean = false;
  public isNewApt: boolean = true;
  public hasChange: boolean = false;
  public leadName: string = "";
  public clearSearch: boolean = false;
  public errorMsg: string | null = null;
  public isAssignLead: boolean = true;
  public isConversationApt: boolean = false;
  public isButtonClicked:boolean=false;
  constructor(
    private formBuilder: FormBuilder,
    private commonService:CommonService,
    private dialog: MatDialog,
    private momentService: MomentService,
    private calendarService: CalendarService,
    private toastMsgService: ToastMsgService,
    private authService: AuthService,
    private leadService: LeadService,
    private router: Router,
  ) {
    this.editor = new Editor();
  }

  public ngOnChanges() {
    this.onCreateGroupFormValueChange();
  }

  //function to detect any changes in user profile form
  public onCreateGroupFormValueChange() {
    const initialValue = this.addAppointmentForm?.value;
    this.addAppointmentForm?.valueChanges.pipe(takeUntil(this._componentDestroyed$)).subscribe(value => {
      if (this.addAppointmentForm?.dirty) {
        this.hasChange = Object.keys(initialValue).some(key => this.addAppointmentForm?.value[key] != initialValue[key]);
        this.aptChange.emit(true);
      }
    });
  }

  public ngOnInit(): void {
    if ((this.isFromCalendar.type) && (this.isFromCalendar.type === 'add')) {
      this.isAddAppointment = true;
    }
    if ((this.isFromCalendar.type) && (this.isFromCalendar.type === 'conversationLog')) {
      this.isAddAppointment = false;
      this.isAssignLead = false;
      this.isConversationApt = true;
      this.lead_detail = this.isFromCalendar.leadDetail;
      this.createFormConversationTable();
    } else {
      this.createForm();
    }
    this.onCreateGroupFormValueChange();
  }

  public showSearchPanel() {
    this.displayAssignLead = true;
  }

  public removeSelectedLead() {
    this.clearSearch = true;
    this.selectedLead = {} as Lead;
    this.leadName = "";
    this.displayAssignLead = false;
  }

  public hideSearchPanel() {
    this.displayAssignLead = false;
  }

  private createForm() {
    if (this.isAddAppointment) {
      this.isNewApt = true;
      let starTdate = this.isFromCalendar?.selectedDate?.date || new Date();
      const d = moment(starTdate);
      let hour = d.hours();
      let min = d.minutes();
      let hr;
      let m;
      let lastHr;
      if (hour < 10) {
        lastHr = hour + 1;
        if (lastHr < 10) {
          lastHr = "0" + lastHr.toString();
        }
        hr = "0" + hour.toString();
      } else {
        lastHr = hour + 1;
        hr = hour;
      }
      if (min < 10) {
        m = "0" + min.toString();
      } else {
        m = min;
      }
      let time = hr + ":" + m;
      if (lastHr == 24) {
        lastHr = '00';
      }
      lastHr = lastHr + ":" + m;
      this.addAppointmentForm = this.formBuilder.group({
        allday_flag: [false],
        description: [''],
        end_time: [this.isFromCalendar ? lastHr : '', Validators.required],
        importance: [false],
        dismiss: [false],
        start_time: [this.isFromCalendar ? time : '', Validators.required],
        title: ['', Validators.required],
        startDate: [this.isFromCalendar ? starTdate : '', Validators.required],
        endDate: [this.isFromCalendar ? starTdate : '', Validators.required],
      }, {
        validator: StartEndTimeValidator('start_time', 'end_time', 'startDate', 'endDate')
      });
    } else {
      this.isAddAppointment = false;
      this.isNewApt = false;
      this.appointment_id = this.isFromCalendar.id;
      this.isAssignLead = false;
      if (this.isFromCalendar.extendedProps.fname != '') {
        this.leadName = this.isFromCalendar.extendedProps.fname + " " + this.isFromCalendar.extendedProps.lname;
      }
      this.addAppointmentForm = this.formBuilder.group({
        allday_flag: [this.isFromCalendar.allDay == '0' ? false : true],
        description: [this.isFromCalendar.extendedProps.description],
        end_time: [(this.isFromCalendar.allDay != '0' || !this.isFromCalendar.allDay) ?
        moment(this.isFromCalendar.allEndTime).format("HH:mm") : this.isFromCalendar.end ?
        moment(this.isFromCalendar.end).format("HH:mm") : '', Validators.required],
        importance: [this.isFromCalendar.extendedProps.importance == '1' ? true : false],
        dismiss: [this.isFromCalendar.extendedProps.completed_date == '' ? false : true],
        start_time: [this.isFromCalendar.start ?
          this.isFromCalendar.allStartTime ? moment(this.isFromCalendar.allStartTime).format("HH:mm") :
          moment(this.isFromCalendar.start).format("HH:mm") : '', Validators.required],
        title: [this.isFromCalendar ? this.isFromCalendar.title : '', Validators.required],
        startDate: [this.isFromCalendar.start ? this.isFromCalendar.start : '', Validators.required],
        endDate: [(this.isFromCalendar.allDay != '0' || !this.isFromCalendar.allDay) && this.isFromCalendar.end ? this.isFromCalendar.end :
        (this.isFromCalendar.allDay != '0' || !this.isFromCalendar.allDay) && !this.isFromCalendar.end ? this.isFromCalendar.start :
        this.isFromCalendar.allDay == '0' && this.isFromCalendar.end ? this.isFromCalendar.end : '', Validators.required],
      }, {
        validator: StartEndTimeValidator('start_time', 'end_time', 'startDate', 'endDate')
      });
    }
    this.hasChange = false;
  }

  public selectLead(lead: Lead) {
    this.selectedLead = lead;
    this.leadName = lead?.fname + " " + lead?.lname;
  }

  public addAppointment() {
    if (this.addAppointmentForm.invalid) {
      return;
    } else {
      if ((this.isFromCalendar.type) && (this.isFromCalendar.type === 'conversationLog')) {
        let formValue = this.addAppointmentForm?.value;
        const d = moment(formValue?.start_time);
        let hour = d.hours();
        let min = d.minutes();
        let hr;
        let m;
        let lastHr;
        if (hour < 10) {
          lastHr = hour + 1;
          if (lastHr < 10) {
            lastHr = "0" + lastHr.toString();
          }
          hr = "0" + hour.toString();
        } else {
          lastHr = hour + 1;
          hr = hour;
        }
        if (min < 10) {
          m = "0" + min.toString();
        } else {
          m = min;
        }
        let time = hr + ":" + m;
        if (lastHr == 24) {
          lastHr = '00';
        }
        lastHr = lastHr + ":" + m;

        let endDate = formValue.endDate;
        let end_time = formValue.end_time;
        if (!endDate) {
          endDate = formValue.startDate;
        }
        if (!end_time) {
          end_time = lastHr
        }

        let payLoad = {
          appointment_type_id: "lead",
          title: formValue?.title,
          allday_flag: formValue?.allday_flag ? 1 : 0,
          importance: formValue?.importance ? 1 : 5,
          description: formValue?.description,
          completed_date: formValue?.dismiss ? moment().toISOString() : '',
          start_time: this.momentService.serverDateTime(formValue.startDate, formValue?.start_time),
          end_time: this.momentService.serverDateTime(endDate, end_time),
          user_id: this.authService.getToken().user_id,
          lead_id: this.lead_detail.lead_id,
        }
        this.isButtonClicked = true;
        this.isAddConvAptSubs = this.calendarService.addLeadAppointment(payLoad).subscribe({
          next: (response: any) => {
            if (response) {
              this.isButtonClicked = false;
              this.addAppointmentForm.markAsPristine();
              this.aptChange.emit(false);
              this.leadService.sendRefreshConvTable('refreshTable');
              this.closeModal();
              this.toastMsgService.showSuccess("Appointment saved successfully.");
            }
          },
          error: (err: any) => {
            this.isButtonClicked = false;
            this.showErrorMessage(err.error.message);
          },
          complete: () => { }
        });
      }else{
        let params;
        let formValue = this.addAppointmentForm?.value;
        params = {
          appointment_id: this.appointment_id != '' ? this.appointment_id : '',
          title: formValue?.title,
          allday_flag: formValue?.allday_flag ? 1 : 0,
          importance: formValue?.importance ? 1 : 5,
          description: formValue?.description,
          completed_date: formValue?.dismiss ? moment().toISOString() : '',
          start_time: this.momentService.serverDateTime(formValue.startDate, formValue?.start_time),
          end_time: this.momentService.serverDateTime(formValue.endDate, formValue?.end_time),
          appointment_type_id: this.selectedLead ? 'lead' : 'agent', // "agent" or "lead"  (if assign "lead" then lead otherwise "agent")
          user_id: this.isFromCalendar && this.isFromCalendar.extendedProps ? this.isFromCalendar.extendedProps?.user_id: `${this.authService.getToken().user_id}`,
          lead_id: this.selectedLead? this.selectedLead.lead_id : this.isFromCalendar?.extendedProps?.lead_id
        }
        this.isButtonClicked = true;
        this.isAddAptSubs = this.calendarService.addAppointment(params).subscribe({
          next: (response: any) => {
            this.isButtonClicked = false;
            this.closeModal();
            this.addEvent.emit('true');
            this.aptChange.emit(false);
            this.addAppointmentForm.markAsPristine();
            this.calendarService.sendEventData(response);
            if (this.appointment_id == '') {
              this.toastMsgService.showSuccess("Appointment saved successfully.");
              if (this.isFromCalendar?.activeModule == 'dashboard')
              this.leadService.sendLeadLedger('refreshDashboard');
            } else {
              this.toastMsgService.showSuccess("Appointment updated successfully.");
            }
            this.leadService.sendRefreshConvTable('refreshTable');
          },
          error: (err) => {
            this.isButtonClicked = false;
            this.showErrorMessage(err.error.message);
          },
          complete: () => { }
        })
      }

    }
  }

  public copyAppointment() {
    this.isNewApt = true;
    this.isAssignLead = false;
    this.isAddAppointment = true;
    this.hasChange = true;
    this.appointment_id = "";
    this.calendarService.sendTitle('refreshTitle');
  }

  public deleteApt() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '30%',
      data: {
        'text': 'Are you sure you want to delete these records?'
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this._componentDestroyed$)).subscribe(res => {
      if (res) {
        this.isAddDelSubs = this.calendarService.deleteEvent(this.appointment_id).subscribe({
          next: (response: any) => {
            if (response) {
              this.toastMsgService.showSuccess("Appointment deleted successfully.");
              this.calendarService.sendDeleteData(this.appointment_id);
              this.leadService.sendRefreshConvTable('refreshTable');
              this.closeModal();
            }
          },
          error: (err) => {
            this.showErrorMessage(err.error.message);
          },
          complete: () => { }
        })
      }
    });
  }

  private showErrorMessage(errorMsg: string) {
    this.errorMsg = errorMsg;
    setTimeout(() => {
      this.errorMsg = null;
    }, 30000);
  }

  private createFormConversationTable() {
    this.addAppointmentForm = this.formBuilder.group({
      allday_flag: [false],
      description: [''],
      end_time: ['', Validators.required],
      importance: [false],
      dismiss: [false],
      start_time: ['', Validators.required],
      title: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  public clearMessage(){
    this.errorMsg = null;
  }

  public goToLeadLedger(lead_id:string, event:any){
    lead_id = (this.selectedLead?.lead_id) ? this.selectedLead.lead_id : lead_id;
    this.closeModal();
    event.preventDefault();
    this.commonService.goToLeadLedger(lead_id);
  }

  public closeModal(){
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
    this.editor.destroy();
    if(this.isAddConvAptSubs) this.isAddConvAptSubs.unsubscribe();
    if(this.isAddAptSubs) this.isAddAptSubs.unsubscribe();
    if(this.isAddDelSubs) this.isAddDelSubs.unsubscribe();
  }

}

