<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
    <h2 mat-dialog-title> Scope of Appointment </h2>
    <button type="button" mat-icon-button class="leading-none" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <p>A CMS-compliant Scope of Appointment (SOA) must be obtained prior to meeting with a potential Medicare Health Plan (MA/PDP) enrollee, whether meeting face to face or remotely. Generally, SOA must be completed at least 48 hours prior to a scheduled appointment. Please note, CMS and Medicare Plan Sponsors may change SOA requirements each plan year.</p>

    <p class="vsig-reminder">***For an <strong class="underline">UNSCHEDULED</strong> appointment, such as an inbound call, you may record and store for 10 years this voice signature (VSIG) Scope of Appointment script prior to discussing plan benefits.***</p>

    <div class="vsig-script">
      <p>Agent: Mr/Mrs__________ I want to remind you that all of our calls are recorded due to Medicare regulations. Also, Medicare requires me to ask….do we have your permission to discuss Medicare Supplement, Medicare Advantage and/or Medicare Part D Prescription Drug coverage today, <span>{{todaysDate | date:'MM/dd/YYYY'}}</span>?</p>
      <p>Client: [client must state “Yes” or verbally agree]</p>
      <p>Agent: Can I ask you to please state your full name and today's date?</p>
      <p>Client: [client states name and today's date]</p>
      <p>Agent: Also, please state your phone number with area code.</p>
      <p>Client: [client states their phone number]</p>
      <p>Agent: Thank you. For the record, my name is [agent full name] and you can contact me at this phone number: [agent phone number]. One last item…I need to inform you that agreeing to discuss these products does not obligate you to enroll in a plan, affect your current or future Medicare enrollment status, or automatically enroll you in the plans discussed. I am contracted by one or more Medicare plans but I do not work directly for the Federal government. I may be paid based on your enrollment in a plan.</p>
    </div>
    <br />
    <h2><strong>OR</strong></h2>
    <p class="vsig-reminder">***For a <strong class="underline">SCHEDULED</strong> appointment, recording and storing for 10 years this voice signature (VSIG) Scope of Appointment script is CMS-compliant when obtained at least 48 hours in advance.***</p>

    <div class="vsig-script">
      <p>Agent: Mr/Mrs__________ I want to remind you that all of our calls are recorded due to Medicare regulations. Also, Medicare requires me to ask….do we have your permission to discuss Medicare Supplement, Medicare Advantage and/or Medicare Part D Prescription Drug coverage on the following date [agent states appointment date]?</p>
      <p>Client: [client must state “Yes” or verbally agree]</p>
      <p>Agent: Can I ask you to please state your full name and today's date?</p>
      <p>Client: [client states name and today's date]</p>
      <p>Agent: Also, please state your phone number with area code.</p>
      <p>Client: [client states their phone number]</p>
      <p>Agent: Thank you. For the record, my name is [agent full name] and you can contact me at this phone number: [agent phone number]. One last item… I need to inform you that agreeing to discuss these products does not obligate you to enroll in a plan, affect your current or future Medicare enrollment status, or automatically enroll you in the plans discussed. I am contracted by one or more Medicare plans but I do not work directly for the Federal government. I may be paid based on your enrollment in a plan.</p>
    </div>

  </div>
  <div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Close</button>
  </div>
</div>