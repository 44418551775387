<div>
  <div>
    <div style="text-align: center">
      <h2>
        We're sorry. We're on a planned exploration to better your experience.
      </h2>
      <p>Please plan to try again later.</p>
    </div>
  </div>
</div>
