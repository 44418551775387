import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/service/auth.service';
import { PhoneDialerBehaviorType } from '@data/services/integration/phone-dialer.interface';
import { PhoneDialerService } from '@data/services/integration/phone-dialer.service';
import { UserService } from '@data/services/user/user.service';
import { IUserSettings } from '@data/user-profile/user-profile.interface';
import { CommonService } from '@shared/services/common/common.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { Subject, combineLatest, first, takeUntil } from 'rxjs';

@Component({
  selector: 'app-phone-dialer-user',
  templateUrl: './phone-dialer-user.component.html',
  styleUrls: ['./phone-dialer-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PhoneDialerUserComponent implements OnInit, OnDestroy {

  private _componentDestroyed$ = new Subject<boolean>;
  public phoneDialerBehaviorTypes: PhoneDialerBehaviorType[];
  public phoneDialerUserSettingForm: FormGroup;

  public isLoading = true;

  constructor(
    private _authService: AuthService,
    private _commonService: CommonService,
    private _fb: FormBuilder,
    private _phoneDialerSvc: PhoneDialerService,
    private _toastMessageSvc: ToastMsgService,
  ) {
    this.phoneDialerUserSettingForm = this._fb.group({
      'phone_dialer_provider_active': new FormControl(),
      'phone_dialer_provider_behavior_type_id': new FormControl(),
    });

    this._commonService.userSettings$
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(userSettings => {
        this._setupForm(userSettings);
      })
  }

  ngOnInit(): void {
    
    combineLatest([
      this._commonService.getLoggedInUserSettings(this._authService.getToken().user_id),
      this._phoneDialerSvc.getPhoneDialerBehaviourList()
    ])
      .pipe(first())
      .subscribe({
        next: ([userSettings, phoneDialerBehaviorTypes]) => {
          this.phoneDialerBehaviorTypes = phoneDialerBehaviorTypes;
        }
    });  

  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
  }

  public updateUserPhoneDialerSettings() {
    let updatedUserSettings = this.phoneDialerUserSettingForm.value;

    updatedUserSettings.phone_dialer_provider_active = (updatedUserSettings.phone_dialer_provider_active) ? '0' : '1';

    this._commonService.updateLoggedInUserSettings(this._authService.getToken().user_id, updatedUserSettings)
      .pipe(first())
      .subscribe({
        next: () => {
          this._toastMessageSvc.showSuccess('Phone dialer settings updated.')
        },
        error: (err) => {
          this._toastMessageSvc.showError(err.message);
        }
      });
  }

  public redirectToDialerLandingPage() {
    this._phoneDialerSvc.redirectToDialerLandingPage();
  }
  
  private _setupForm(userSettings: IUserSettings) {
    let userSettingsFormPatch = {
      ...userSettings,
      ...{
        phone_dialer_provider_active: (userSettings?.phone_dialer_provider_active != '1')
      }
    }
        
    this.phoneDialerUserSettingForm.patchValue(userSettingsFormPatch);
    this.isLoading = false;
  }
  
}
