export const CONSTANTS = {
  EXPORT_LEAD: {
    account: {
      export_type: '1',
    },
    lead_list: {
      export_type: '4',
    },
    recent_lead: {
      export_type: '2',
    },
    search_criteria: {
      export_type: '3',
    },
  }
};

export const TOOLBAR = [
  ['bold', 'italic'],
  ['underline', 'strike'],
  ['ordered_list', 'bullet_list'],
  [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
  ['align_left', 'align_center', 'align_right', 'align_justify'],
];

export const CUSTOM_TOOLBAR = [
  ['bold', 'italic'],
  ['underline', 'strike'],
  ['ordered_list', 'bullet_list'],
  [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
  ['link'],
  ['align_left', 'align_center', 'align_right', 'align_justify'],
];

export const RELATION = [{
  text: 'Spouse',
  value: '1',
}, {
  text: 'Parent',
  value: '2',
}, {
  text: 'Child',
  value: '3',
}, {
  text: 'Sibling',
  value: '4',
}, {
  text: 'Grandparent',
  value: '5',
}, {
  text: 'Grandchild',
  value: '6',
}, {
  text: 'Referred',
  value: '7'
}, {
  text: 'Referred by',
  value: '8'
}
];

export const NOTICE_TYPE = {
  Success: 'success',
  Alert: 'alert',
  Warning: 'warn',
  Close: 'close',
  Announcement: 'announcement'
};

export const MARITAL_STATUS = [
  {
    text: 'Single',
    value: 'S'
  },
  {
    text: 'Married',
    value: 'M'
  },
  {
    text: 'Divorced',
    value: 'D'
  },
  {
    text: 'Widowed',
    value: 'W'
  }
];

export const PHONE_TYPES = [
  { text: "Home", value: "Home", isSelected: false },
  { text: "Fax", value: "Fax", isSelected: false },
  { text: "Work", value: "Work", isSelected: false },
  { text: "Mobile", value: "Mobile", isSelected: false },
  { text: "Other", value: "Other", isSelected: false },
];

export const GENDER = [
  {
    text: 'Male',
    value: 'M'
  },
  {
    text: 'Female',
    value: 'F'
  }
]

export const CONTACT_RELATION = [
  { value: 'Sibling', text: 'Sibling' },
  { value: 'Parent', text: 'Parent' },
  { value: 'Child', text: 'Child' },
  { value: 'Spouse', text: 'Spouse' },
  { value: 'Grandparent', text: 'Grandparent' },
  { value: 'Grandchild', text: 'Grandchild' },
  { value: 'Friend', text: 'Friend'},
  { value: 'Other', text: 'Other' }
];

export const PRODUCT_INFO = [
  {
    'text': 'Yes',
    'value': 'yes'
  }, {
    'text': 'No',
    'value': 'no'
  }
]
export const enrollmentTitle = {
  'EAPP': 'The E-App enrollment method will single sign you into the carrier\'s e-app. This will save you time by not having to log into the carrier\'s e-app. For most of the carriers the client information will also be transferred automatically into the carrier\'s e-app platform. Many of the carrier e-apps will allow you to sell in a face to face or remote environment.',
  'AICOE': 'Agent Initiated Consumer Online Enrollment (AICOE) is designed for selling remotely using email and/or TEXT message to deliver plan documents and enrollment forms to applicants for electronic signature. Agent writing number embedded to ensure agent of record.',
  'PDF': 'The PDF enrollment method is designed when selling face to face or in a remote setting. When selecting PDF you can download a fillable PDF of the application. You can complete the application and print off for signature if in a face to face setting or can email the completed application to the client. The client will receive an email that will direct them to a secure website to review and print the application to sign and return. This email will also contain important documents including the summary of benefits, star ratings, etc..',
  'F2F': 'Face to Face (F2F) is designed to use when selling face to face. When using the F2F enrollment method you can complete the application electronically and have the client digitally sign the application. When starting the F2F enrollment method you can complete the application on your current device or can use a touch screen device to complete the enrollment by going to signtheapp.com. The Face to Face enrollment method must be used in a face to face setting and the signature must be executed by the consumer.',
  'VSIG': 'Agent MUST RECORD the ENROLLMENT CONVERSATION and store the enrollment voice conversation or audiovisual web meeting between agent and applicant. No requirement to obtain applicant electronic signature via email or text message. No requirement for agent to follow verbatim script unless instructed to read state statements to applicant in enrollment form.'
}

export const LDF_LEAD_SWITCH_KEY = 'lapro.switch.perm.enable-in-app-navigation-for-lead-switch-scenario';
export const LDF_CONFIGURABLE_SETTINGS = 'leadadvantage-temp-feb-24-enable-ssp-reminder-available';
export const LDF_ENABLE_AEP_DISRUPTION_REPORT = 'lapro.switch.perm-enable-aep-disruption-report-module';
export const LDF_SHOW_ENV_WARNING_BANNER = 'lapro.switch.perm.laweb-show-environment-warning-banner';

export const productTypes = {
  MED_ADV: "8",
  MED_PARTD: "11",
  FEX:"3",
  MED_SUP:"9"
}

export const productTypesArray={
  "2": "Dental",
  "3":"Fex",
  "4":"Medical",
  "5":"Life",
  "6":"LTC",
  "8":"MA",
  "9":"Medsup",
  "10":"Other",
  "11":"Part D",
  "15":"Travel",
  "17":"HospIndem",
  "254":"Health",
  "255":"Vision"
}

export const EXTRA_BENEFITS={

  'e31':{name:'Dental',img:'assets/images/dentistry.png'},
  'e33':{name:'Vision',icon:'visibility'},
  'e32':{name:'Hearing',icon:'hearing'},
  'e388':{name:'Part B Giveback',icon:'format_bold'},
  'e55':{name:'OTC',img:'assets/images/pill.png'},
  'e1542':{name:"Flex Card",icon:'credit_card_heart'},
  'e319':{name:'Fitness Benefit',icon:'directions_run'},
  'e36':{name:'Transportation',icon:'airport_shuttle'},
  'e375':{name:'Meals',icon:'restaurant_menu'},
  'e10':{name:'Chiropractic',img:'assets/images/orthopedics.png'},
  'e37':{name:'Acupuncture',img:'assets/images/accupuncture.png'},
  'e405':{name:'Bathroom Safety',icon:'bathtub'},
  'e333':{name:'Telehealth',icon:'call'},
  'e328':{name:"In-Home Support",img:'assets/images/family_home.png'}

}

export const validatorPatterns = {
  EMAIL: /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+(\.[\w\-\+_]{2,})+\s*$/
}

export const MONTH_ARRAY = [
  { id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' }
]

export const hospIndemUrl = 'https://hippd.lbig.com/';
