import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { PhoneNumberMaskDirective } from './phone-number-mask.directive';
import { DateDirective } from './date.directive';
import { SocialSecurityMaskDirective } from './social-security-mask.directive';
import { InputMaxLengthDirective } from './input-max-length.directive';
import { DisableAutofillDirective } from './disable-autofill.directive';
import { DialogScrollDirective } from './modal-class.directive';
import { PreventDoubleClickDirective } from './prevent-double-click.directive';
import { LAPButtonDirective } from './lap-button/lap-button.directive';
import { AlphaCharacterOnlyDirective } from './alpha-character-only.directive';

@NgModule({
  declarations: [
    AlphaCharacterOnlyDirective,
    NumbersOnlyDirective,
    PhoneNumberMaskDirective,
    AlphaCharacterOnlyDirective,
    DateDirective,
    SocialSecurityMaskDirective,
    InputMaxLengthDirective,
    DisableAutofillDirective,
    DialogScrollDirective,
    PreventDoubleClickDirective,
    LAPButtonDirective
  ],
  imports: [
    CommonModule
  ],
  exports : [
    AlphaCharacterOnlyDirective,
    NumbersOnlyDirective,
    PhoneNumberMaskDirective,
    AlphaCharacterOnlyDirective,
    DateDirective,
    SocialSecurityMaskDirective,
    InputMaxLengthDirective,
    DisableAutofillDirective,
    DialogScrollDirective,
    PreventDoubleClickDirective,
    LAPButtonDirective,
  ]
})
export class DirectivesModule { }
