<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div mat-dialog-content>
    <div class="flex flex-row gap-4">
      <span class="text-3xl text-red-500" *ngIf="!data?.confirm"><mat-icon inline>warning</mat-icon></span>
      <p [innerHTML]="data.text"></p>
    </div>
    <div *ngIf="data?.counties?.length">
      <ng-container *ngFor="let state of data?.counties">
        <p class="state-name-position font-bold">{{ state.state_name }}</p>
        <div>
          <ul *ngFor="let county of state.counties" class="county-list">
            <li class="list-position">{{ county.county_name }}</li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>

  <div mat-dialog-actions align="end" *ngIf="data.type != 'pick-role'">
    <button *ngIf="data?.type != 'ok'" mat-flat-button class="lap-warn" (click)="yesDelete()">Yes</button>
    <button *ngIf="data?.confirm=='yes';else noConfirm" mat-flat-button mat-dialog-close>Confirm</button>
    <ng-template #noConfirm><button mat-raised-button mat-dialog-close>{{data?.type != 'ok' ? 'No' : 'Ok'}}</button></ng-template>
  </div>
  <div mat-dialog-actions align="end" *ngIf="data.type == 'pick-role'">
    <button mat-flat-button mat-dialog-close class="lap-warn">Ok</button>
    <button mat-raised-button mat-dialog-close>Cancel</button>
  </div>
</div>
