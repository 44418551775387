import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from '@app/service/auth.service';
import { UserService } from '@data/services/user/user.service';
import { RegistrationModule } from '@modules/registration/registration.module';
import { initializeAppFactory } from '@shared/factories/app.initializer';
import { LaunchdarklyService } from '@shared/services/LaunchDarkly/launchdarkly.service';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HeaderComponent } from './layout/header/header.component';
import { QuickLinksComponent } from './layout/sidebar/quick-links/quick-links.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderModalsModule } from './modal/header-modals/header-modals.module';
import { SidebarModalsModule } from './modal/sidebar-modals/sidebar-modals.module';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    QuickLinksComponent
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    SharedModule,
    HeaderModalsModule,
    SidebarModalsModule,
    RegistrationModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService, LaunchdarklyService, UserService, CookieService],
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule { }
