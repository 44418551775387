import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vsig-soa',
  templateUrl: './vsig-soa.component.html',
  styleUrls: ['./vsig-soa.component.scss']
})
export class VsigSoaComponent implements OnInit {
  public todaysDate: any = new Date();

  constructor() { }

  ngOnInit(): void {
  }

}
