import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sms-spinner',
  templateUrl: './sms-spinner.component.html',
  styleUrls: ['./sms-spinner.component.scss'],
})
export class SmsSpinnerComponent implements OnInit {
  // Accepted values: sm, 2x, 3x
  @Input() public data: string = '';
  @Input() public size: string = '';
  constructor() {}

  ngOnInit(): void {}
}
