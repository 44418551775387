import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './service/auth.service';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { ErrorModule } from './error/error.module';
import { RefreshTokenService } from './service/refresh-token.service';
import { HttpsInterceptor } from './interceptor/https.interceptor';

@NgModule({
  declarations: [],
  providers: [
    AuthService,
    RefreshTokenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  imports: [CommonModule, ErrorModule],
})
export class CoreModule {}
