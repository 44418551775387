<mat-sidenav-container autosize>
  <mat-sidenav #sidenav id="main-navigation" class="drop-shadow-md" *ngIf="showSidebar" fixedInViewport="true" mode="side" opened="true" [disableClose]="true" [ngClass]="{ 'slim': !isExpanded }">
    <div class="flex flex-col h-full">
      <a class="block overflow-hidden shrink-0 h-14" routerLink="">
        <img [src]="isExpanded ? defaultLogo : defaultLogoSmall" alt="Lead Advantage Pro" />
      </a>

      <div class="flex flex-col h-full justify-between m-2">
        <div>
          <ng-container *ngIf="!isExpanded; else quickLinksExpanded">
            <button type="button" mat-mini-fab color="primary" id="qlBtn" matTooltip="Open Quick Links" class="mb-1" (click)="openQuickLinks()">
              <mat-icon>expand_more</mat-icon>
            </button>

            <button type="button" mat-mini-fab color="primary" matTooltip="Open Quick Actions" [matMenuTriggerFor]="quickActionsMenu">
              <mat-icon>add</mat-icon>
            </button>
          </ng-container>
          <ng-template #quickLinksExpanded>
            <button type="button" class="w-full" mat-flat-button id="qlBtn" matTooltip="Open Quick Links" (click)="openQuickLinks()">Quick Links</button>
          </ng-template>

          <mat-nav-list>
            <div mat-subheader *ngIf="isExpanded">Menu</div>

            <a mat-list-item [routerLink]="['/']" title="Go back to Dashboard" class="min-h-12">
              <mat-icon matListItemIcon>dashboard</mat-icon>
              <span matListItemTitle *ngIf="isExpanded">Dashboard</span>
            </a>

            <a mat-list-item [routerLink]="['/lead']" title="Display Leads" [activated]="isActiveUrl('/lead')" (click)="redirectTo(lead_url)" class="min-h-12">
              <mat-icon matListItemIcon>group</mat-icon>
              <span matListItemTitle *ngIf="isExpanded">Leads</span>

              <button matListItemMeta *ngIf="isExpanded" mat-icon-button (click)="addLead($event);" aria-label="Create New Lead">
                <mat-icon>add</mat-icon>
              </button>
            </a>

            <a mat-list-item [routerLink]="['/calendar']" title="Display Calendar" [activated]="isActiveUrl('/calendar')" (click)="redirectTo(calendar_url)" class="min-h-12">
              <mat-icon matListItemIcon>calendar_month</mat-icon>
              <span matListItemTitle *ngIf="isExpanded">Calendar</span>

              <button matListItemMeta *ngIf="isExpanded" mat-icon-button (click)="addEvent($event);" aria-label="Add Task/Appointment">
                <mat-icon>add</mat-icon>
              </button>
            </a>

            <a mat-list-item [routerLink]="['/quotes']" title="Display Quotes" [activated]="isActiveUrl('quotes/')" (click)="redirectTo(quote_url)" class="min-h-12">
              <mat-icon matListItemIcon>event</mat-icon>
              <span matListItemTitle *ngIf="isExpanded">Quotes</span>

              <button matListItemMeta *ngIf="isExpanded" mat-icon-button aria-label="Create Quote" [matMenuTriggerFor]="quickQuoteMenu" (click)="preventRedirectButton($event)">
                <mat-icon>add</mat-icon>
              </button>
            </a>

            <a mat-list-item [routerLink]="['/forms']" title="Display Forms" (click)="redirectTo(form_url)" [activated]="isActiveUrl('forms/')" class="min-h-12">
              <mat-icon matListItemIcon>description</mat-icon>
              <span matListItemTitle *ngIf="isExpanded">Forms</span>
            </a>

            <a mat-list-item [routerLink]="['/reports']" title="Display Reports" (click)="redirectTo(report_url)" [activated]="isActiveUrl('/reports')" class="min-h-12">
              <mat-icon matListItemIcon >bar_chart</mat-icon>
              <span matListItemTitle *ngIf="isExpanded">Reports</span>
            </a>
          </mat-nav-list>
        </div>

        <div>
          <mat-nav-list>
            <a mat-list-item [routerLink]="['/admin']" title="Display Admin" *ngIf="commonService.hasPermission('ui_admin')" (click)="redirectTo(admin_url)" [activated]="isActiveUrl('/admin')" class="min-h-12">
              <mat-icon matListItemIcon>admin_panel_settings</mat-icon>
              <span matListItemTitle *ngIf="isExpanded">Admin</span>
            </a>
            <a mat-list-item [routerLink]="['/help']" title="Display Help" class="min-h-12">
              <mat-icon matListItemIcon>help_outline</mat-icon>
              <span matListItemTitle *ngIf="isExpanded">Help</span>
            </a>
            <a mat-list-item [routerLink]="['/privacy_policy']" title="Display Privacy Policy" class="min-h-12">
              <mat-icon matListItemIcon>lock</mat-icon>
              <span matListItemTitle *ngIf="isExpanded">Privacy Policy</span>
            </a>
          </mat-nav-list>
          <mat-list>
            <mat-list-item *ngIf="isExpanded">
              <span class="text-xs text-slate-800">{{'v'+ versionNumber }}</span>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>

  <!-- sidenav used currently for additional filters for quoting -->
  <mat-sidenav #panel class="drop-shadow-md" fixedInViewport="true" mode="over" class="panel" [disableClose]="true" position="end">
    <ng-container #content></ng-container>
  </mat-sidenav>

</mat-sidenav-container>

<mat-menu #quickActionsMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="addLead($event)">Create New Lead</button>
    <button mat-menu-item (click)="addEvent($event)">Create New Calendar Event</button>
    <button mat-menu-item *ngIf="leadDetails" (click)="addQuote(true)">Create Quote for <strong>{{leadDetails.fname + ' ' + leadDetails.lname}}</strong></button>
    <button mat-menu-item (click)="addQuote()">Create New Quote</button>
  </ng-template>
</mat-menu>

<mat-menu #quickQuoteMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngIf="leadDetails" (click)="addQuote(true)">Create Quote for <strong>{{leadDetails.fname + ' ' + leadDetails.lname}}</strong></button>
    <button mat-menu-item (click)="addQuote()">Create New Quote</button>
  </ng-template>
</mat-menu>

