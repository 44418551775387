import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ICobrandDetails } from '@data/admin/cobrand-details';
import { environment } from "@env/environment";
import { IUser } from '@data/user-profile/user-profile.interface';
import { AuthToken } from './auth-token.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private data = new BehaviorSubject(null);
  private laAuth: AuthToken | null = null;

  public authUser = this.data.asObservable();
  public cobrandDetails$ = new Subject<ICobrandDetails>();
  public userDetails$ = new BehaviorSubject<IUser | null>(null);

  public account_id!: string;
  public user_details!: IUser;

  constructor(
    private _cookieService: CookieService
  ) {}

  // Start of User Cookie functions
  public setCookie(): void {
    const laAuthTransitionCookie = this._cookieService.get('LAAuth2LAWeb');
    let laWebCookie = this._cookieService.get('LAWEB');

    // console.debug('no auth cookie: ' + decodeURIComponent(laAuthTransitionCookie));
    // console.debug('current auth cookie: ' + decodeURIComponent(laWebCookie));

    //TODO: remove the debug token
    // laWebCookie = (window.location.href.includes("localhost"))
    //   ? {
    //       "access_token": "2a10giTYklX7mbVIGATh3XuMOVaCMp3y8LIqjw6ELZV5VCfKhi",
    //       "expires_in": 1200,
    //       "token_type": "Bearer",
    //       "user_id": 10973,
    //       "refresh_token": "2a10HSx3SFIBCnYGjNKR2782y8ckDsBpdHETF7LTGdWVaaX1Lg",
    //       "context": "LApro"
    //     }.toString()
    //   : laWebCookie;

    if (laAuthTransitionCookie.length > 0) // Just logged in
      this.setToken(JSON.parse(decodeURIComponent(laAuthTransitionCookie)));

    else if (laWebCookie.length > 0) // Reloaded the page (refresh or opened the browser)
      this.setToken(JSON.parse(decodeURIComponent(laWebCookie)));

    this._cookieService.delete('LAAuth2LAWeb', '/', '.leadadvantagepro.com'); // Always attempt to delete the LAAuth2LAWeb

    if (this.laAuth === null) // If nothing was set, no LAWEB nor LAAuth2LAWeb cookies found, kick back to LA-Auth
      this.killCookie()

  }

  public setToken(token: AuthToken): void {
    //delete current auth token first
    this._deleteAuthCookie();

    this._cookieService.set(
      'LAWEB', encodeURIComponent(JSON.stringify(token)),
      { path: '/', secure: true, sameSite: 'Strict' }
    );

    this.laAuth = { ...token };
  }

  public getToken(): any {
    return this.laAuth;
  }

  public killCookie(): void {
    this._deleteAuthCookie();
    this.redirectToAuth();
  }

  public redirectToAuth(): void {
    window.location.href = environment.authClient + '?referrer=' + encodeURIComponent(window.location.href);
  }

  public getAuthCookie(): string {
    return this._cookieService.get('LAWEB');
  }

  private _deleteAuthCookie() {
    this._cookieService.delete('LAWEB', '/', '.leadadvantagepro.com', true); // delete legacy cookie
    this._cookieService.delete('LAWEB', '/'); // delete environment specific cookie
  }
}
