import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickSearchComponent } from './quick-search/quick-search.component';
import { SharedModule } from '../../shared/shared.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { DatePipe } from '@angular/common';
import { NotificationComponent } from './notification/notification.component';
import { ImportantNoticeComponent } from './important-notice/important-notice.component';
import { PhoneDialerDisplayComponent } from './phone-dialer-display/phone-dialer-display.component';

@NgModule({
  declarations: [
    QuickSearchComponent,
    UserProfileComponent,
    NotificationComponent,
    ImportantNoticeComponent,
    PhoneDialerDisplayComponent
  ],
  imports: [CommonModule, SharedModule],
  providers: [DatePipe],
  exports: [
    QuickSearchComponent,
    UserProfileComponent,
    NotificationComponent,
    ImportantNoticeComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HeaderModalsModule {}
