import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { LDF_CONFIGURABLE_SETTINGS, LDF_SHOW_ENV_WARNING_BANNER } from '@app/constants';
import { AuthService } from '@app/service/auth.service';
import { HeaderService } from '@data/services/header/header.service';
import { UserService } from '@data/services/user/user.service';
import { environment } from '@env/environment';
import { ImportantNoticeComponent } from '@modal/header-modals/important-notice/important-notice.component';
import { NotificationComponent } from '@modal/header-modals/notification/notification.component';
import { QuickSearchComponent } from '@modal/header-modals/quick-search/quick-search.component';
import { UserProfileComponent } from '@modal/header-modals/user-profile/user-profile.component';
import { ConfigurableSettingsComponent } from '@modules/configurable-settings/configurable-settings/configurable-settings.component';
import { PhoneDialerUserComponent } from '@modules/integration/components/phone-dialer-user/phone-dialer-user.component';
import { ConfirmDeleteComponent } from '@shared/modals/confirm-delete/confirm-delete.component';
import { CommonService } from '@shared/services/common/common.service';
import { LaunchdarklyService } from '@shared/services/LaunchDarkly/launchdarkly.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, combineLatest, filter, first, Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  private _componentDestroyed$ = new Subject<boolean>();
  private _componentLoaded$ = new Subject<boolean>();
  private _peproSub:Subscription;

  private _pollingInterval: number = 120000;
  private _userId: string;
  private _isDismissedNotify: boolean = false;
  private _isNotifyDialog: boolean = false;

  public showPhoneDialer$ = new BehaviorSubject<boolean>(false);
  public configurableSettingsFlag: boolean;

  public hasPeproAccess: boolean = false;
  public isDisabled: boolean = false;
  public isExpanded = true;
  public isLoading: boolean = true;
  public isLoadingNotifications: boolean = false;
  public isSubMenuLoading: boolean = true;
  public showHeader: boolean;
  public showSubmenu: boolean = false;
  public showEnvBanner: boolean;

  public date: any;
  public month: any;
  public year: any;
  public day: any;
  public time: any;
  public userName: string = '';

  public notification_count: number = 0;

  @ViewChild('sidenav') sidenav: MatSidenavModule;

  constructor(
    private headerService: HeaderService,
    private commonService: CommonService,
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private toastMsgService: ToastMsgService,
    private userService: UserService,
    private _launchDarklySvc: LaunchdarklyService,
    public dialog: MatDialog,
    private _commonService: CommonService
  ) {
    // Set configurableSettingsFlag for SSPR
    this._launchDarklySvc.getFlag(LDF_CONFIGURABLE_SETTINGS)
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe((flag) => {
        this.configurableSettingsFlag = flag;
      });

    this._launchDarklySvc.getFlag(LDF_SHOW_ENV_WARNING_BANNER, true)
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe((flag) => {
        this.showEnvBanner = flag;
      });

    this.router.events
      .pipe(
        takeUntil(this._componentDestroyed$),
        filter(event => event instanceof NavigationEnd),
        first(),
      ).subscribe(navigation => {
        const url = (navigation as NavigationEnd).url
        this.showHeader = url.indexOf('registration') < 0;

        if (this.showHeader) {
          this._userId = this.authService.getToken().user_id;

          this._checkPeproAccess();
          this._getUserDetails();
          this._notificationCount();

          //Polling after every 2 mins
          setInterval(() => {
            this._getNotificationCount();
          }, this._pollingInterval);

          setInterval(() => {
            this._currentDateTime();
          }, 1000);

        }

      });

    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);
    if (isMobileDevice) {
      this.isDisabled = true;
      this.isExpanded = false;
    } else {
      this.isDisabled = false;
      this.isExpanded = true;
    }
    this.toastMsgService.isExpanded = this.isExpanded;

    this._commonService.accountSettings$
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: accountSettings => {
          if (accountSettings)
            this.showPhoneDialer$.next(accountSettings.phone_dialer_provider_active == '1');

        }
      });

    this.authService.userDetails$
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next:( userDetails => {
          if (userDetails)
            this.userName = userDetails.fname + ' ' + userDetails.lname;

        })
      });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event:any): void {
    if (this.sidenav !== undefined) {
      if (event.target.innerWidth <= 980) { // This can be any value.
        this.isExpanded = false;
      } else {
        this.isExpanded = true;
      }
      this.commonService.setMenuExpand(this.isExpanded);
      this.toastMsgService.isExpanded = this.isExpanded;
    }
  }

  private _notificationCount() {
    this.headerService.getNotifyAlert()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (response: any) => {
          if (response) {
            if(!this._isDismissedNotify) {
              this._getNotificationCount();
            }
            this._isDismissedNotify = true;
          }
        },
        error: (err) => { },
        complete: () => { }
      })
  }

  public ngOnInit(): void {
    this._currentDateTime();
  }

  public ngOnDestroy() {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
    this._componentLoaded$.next(true);
    this._componentLoaded$.complete();
  }

  //Check if user has pepro link access
  private _checkPeproAccess() {
    this._peproSub = this._peproSub ? this._peproSub : this.headerService.getPeproAccess()
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.result === "True") {
            this.hasPeproAccess = true;
          }
        }
      );
  }

  private _currentDateTime() {
    let date = new Date();
    this.date = date.getDate();
    this.month = this.getFormattedValue('MMMM');
    this.day = this.getFormattedValue('dddd');
    this.year = this.getFormattedValue('YYYY');
    this.time = this.getFormattedValue("hh:mm a");
  }

  private getFormattedValue(format: string) {
    return moment().format(format);
  }

  public expandMenu() {
    this.isExpanded = !this.isExpanded;
    this.commonService.setMenuExpand(this.isExpanded);
    this.toastMsgService.isExpanded = this.isExpanded;
  }

  public openMenu() {
    this.isSubMenuLoading = true;
    this.showSubmenu = true;

    this._commonService.getLoggedInAccountSettings(this.commonService.getAccountId(), true)
      .pipe(first())
      .subscribe({
        next: () => {
          this.isSubMenuLoading = false;
        }
      });

  }

  public openUserProfile() {
    this.dialog.open(UserProfileComponent, {
      width: '100%',
      autoFocus: false,
      data: {
        user_id: this._userId
      },
    });
  }

  public openConfigurableSettings() {
    this.dialog.open(ConfigurableSettingsComponent, {
      width: '55%',
      autoFocus: true
    });
  }

  public openPepro() {
    const laWebCookie = this.authService.getAuthCookie();

    // Set PEP cookie
    this.cookieService.set(
      'LAWeb2PEWeb', laWebCookie,
      { path: '/', domain: '.leadadvantagepro.com', secure: true }
    )

    window.open(environment.PEPro, '_openPEProLink');
  }

  public openCallRecordingUserSettings() {
    this.dialog.open(PhoneDialerUserComponent, {
      width: '55%',
      autoFocus: true
    });

  }

  /* to open the search dialog */
  public openSearchDialog() {
    window.scroll(0, 0);
    this.dialog.open(QuickSearchComponent, {
      width: '60%',
      autoFocus: false,
    })
  }

  private _getUserDetails() {
    combineLatest([
      this.userService.getUserDetailsAndPermissions(this.authService.getToken().user_id)
    ])
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: ([userDetailsResponse]) => {
          const userDetails = userDetailsResponse.body!;

          this.isLoading = false;

          this.userName = userDetails.fname + ' ' + userDetails.lname;
          this.authService.user_details = userDetails;
          this.authService.userDetails$.next(userDetails);
          this.authService.account_id = userDetails.account_id;

          this.commonService.setAccountId(userDetails.account_id);

          this.authService.user_details['rolePermission'] = JSON.parse(userDetailsResponse.headers.get('x-lapro-acl-array') || '[]');
          this.authService.user_details['hash'] = userDetailsResponse.headers.get('x-lapro-acl');

          this._componentLoaded$.next(true);

          // this._getCobrandDetails(userDetails.account_id);
          this._commonService.getLoggedInAccountSettings(userDetails.account_id)
            .pipe(first())
            .subscribe();
        },
        error: (err) => { },
        complete: () => { }
      });
  }

  /* notification functionality */
  private _getNotificationCount() {
    this.headerService.getNotificationCount()
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          this.notification_count = response.notice_outstanding_count;
          this.commonService.tempPollingInterval = this._pollingInterval;
          if (response['includes_important'] == '1') {
            this.headerService.getUserNotice(0, 'quickNotify')
              .pipe(takeUntil(this._componentDestroyed$))
              .subscribe({
                next: (response: any) => {
                  if (response?.length) {
                    if(this._isNotifyDialog){
                      return;
                    }
                    this._isNotifyDialog = true;

                    this.dialog.open(ImportantNoticeComponent, {
                      width: '60%',
                      data: response
                    }).afterClosed().subscribe((elem:any)=>{
                      this._isDismissedNotify = false;
                      this._isNotifyDialog = false;
                    })
                  }
                },
                error: (err) => {
                },
                complete: () => { }
              })
          }
        },
        error: (err) => { },
        complete: () => { }
      });
  }

  public openNotification() {
    this.isLoadingNotifications = true;
    this.headerService.getUserNotice(0, 'openNotify')
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          let data: any;
          if (response.result == '') {
            data = 'No result';
          } else {
            data = response;
          }
          this.dialog.open(NotificationComponent, {
            width: '65%',
            data: data,
          });
        },
        complete: () => {
          this.isLoadingNotifications = false;
        }
      });
  }

  public logout(): void {
    this.dialog.open(ConfirmDeleteComponent, {
      width: '30%',
      data: {
        'text': 'Are you sure you want to logout?'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.authService.killCookie();
        this.dialog.closeAll();
        localStorage.removeItem('printSettingsCheck');
        this.authService.redirectToAuth();
      }
    });
  }

  public isProd() {
    return environment.production;
  }
}
