<div id="userAgreement" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle>
    <div class="flex justify-between items-baseline pr-4">
      <h2 mat-dialog-title>User Agreement</h2>
      <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
    <p class="mx-6 mb-4">User must agree to terms of service outlined below.&nbsp;<span class="font-bold">A copy of this agreement will be emailed to the email associated with this account upon completion.</span></p>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-content>
    <ng-container *ngIf="isLoading; else regForm">
      <app-sms-spinner size="1x"></app-sms-spinner>
    </ng-container>

    <ng-template #regForm>
      <div [innerHTML]="document"></div>
    </ng-template>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button mat-raised-button mat-dialog-close>Do Not Agree</button>
    <button mat-button mat-flat-button [mat-dialog-close]="true">Agree</button>
  </div>

</div>