<form autocomplete="off" [formGroup]="addressForm">
  <div class="flex justify-between items-baseline">
    <h4 class="user-profile-heading">Addresses</h4>
    <button mat-icon-button matTooltip="Add Entry" [matMenuTriggerFor]="addressMenu">
      <mat-icon class="plus">add</mat-icon>
    </button>
  </div>
  <div class="flex">
    <mat-menu #addressMenu="matMenu">
      <ng-container *ngFor="let content of address_content">
        <button *ngIf="!content.isSelected" (click)="addAddressType(content)" mat-menu-item>{{ content.name }}</button>
      </ng-container>
    </mat-menu>
  </div>

  <div formArrayName="addresses">
    <ng-container *ngFor="let address of addresseListArr?.controls; let i=index" [formGroupName]="i">
      <div class="flex flex-row w-full gap-3 place-items-center">
 <!-- address type -->
        <mat-form-field class="w-[25%]">
          <mat-label>Type</mat-label>
          <mat-select [disabled]="address?.value?.address_class == 'true'" matNativeControl formControlName="address_type_id" [ngClass]="{'line-through': address?.value?.address_class == 'true'}">
            <mat-option *ngFor="let content of address_content" value="{{content.name}}">{{content.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- address1 -->
        <mat-form-field class="grow">
          <mat-label>Address</mat-label>
          <input [class]="address?.value?.address_class == 'true' ? 'not-allowed' : ''"  [readonly]="address?.value?.address_class == 'true'" [appInputMaxLength] matInput formControlName="address1" [ngClass]="{'line-through': address?.value?.address_class == 'true'}" appDisableAutofill>
          <mat-error *ngIf="address?.get('address1')?.errors && submitted">
            Please enter address
          </mat-error>
        </mat-form-field>
        <button mat-icon-button class="close" title="Undo" *ngIf="address?.value?.address_class == ''" (click)="clearAddress(address.value,i)"><mat-icon>close</mat-icon></button>
        <button mat-icon-button *ngIf="address?.value?.address_class == 'true'" (click)="refreshAddress(address.value,i)"><mat-icon>replay</mat-icon></button>
        <mat-checkbox title="Default Address" *ngIf="type!='admin'" (change)="addressCheckBoxvalue($event,address.value,i)" [checked]="address?.value?.isSelected==true || type == 'plan' || type=='add-user'">
        </mat-checkbox>
      </div>

      <div class="flex flex-row w-full gap-3">
        <!-- address2 -->
        <mat-form-field class="w-[23%]">
          <mat-label>Address 2</mat-label>
          <input [class]="address?.value?.address_class == 'true' ? 'not-allowed' : ''" [readonly]="address?.value?.address_class == 'true'"  [appInputMaxLength] matInput formControlName="address2" [ngClass]="{'line-through': address?.value?.address_class == 'true'}" appDisableAutofill>
        </mat-form-field>

        <!-- city -->
        <mat-form-field class="w-[20%]">
          <mat-label>City</mat-label>
          <input [class]="address?.value?.address_class == 'true' ? 'not-allowed' : ''" [readonly]="address?.value?.address_class == 'true'"  [appInputMaxLength] matInput formControlName="city" [ngClass]="{'line-through': address?.value?.address_class == 'true'}" appDisableAutofill>
          <mat-error *ngIf="address?.get('city')?.errors && submitted">Please enter city</mat-error>
        </mat-form-field>

        <!-- state_id -->
        <mat-form-field class="w-[20%]">
          <mat-label>State</mat-label>
          <mat-select [disabled]="address?.value?.address_class == 'true'" placeholder="State" #State formControlName="state_id" [ngClass]="{'line-through': address?.value?.address_class == 'true'}">
            <mat-option *ngFor="let el of state_data" [value]="el.abbreviation">{{ el.abbreviation }}</mat-option>
          </mat-select>
          <mat-error *ngIf="address?.get('state_id')?.errors && submitted">Please select state</mat-error>
        </mat-form-field>

        <!-- zip -->
        <mat-form-field class="w-24">
          <mat-label>Zip</mat-label>
          <input matInput [class]="address?.value?.address_class == 'true' ? 'not-allowed' : ''" [readonly]="address?.value?.address_class == 'true'"  formControlName="zip" [minlength]="5" [maxlength]="5" appNumbersOnly (input)="getCounties(i, '', true)" [ngClass]="{'line-through': address?.value?.address_class == 'true'}" appDisableAutofill>
          <mat-error *ngIf="address?.get('zip')?.errors && submitted">Please enter correct zip</mat-error>
        </mat-form-field>

        <!-- county -->
        <mat-form-field class="w-[22%]" *ngIf="type!='profile' && type!= 'admin' && type!='add-user'">
          <mat-label>County</mat-label>
          <mat-select [disabled]="address?.value?.address_class == 'true'" placeholder="County" formControlName="county" [ngClass]="{'line-through': address?.value?.address_class == 'true'}">
            <mat-option *ngIf="address?.value?.county.length>0" class="selected-county" [value]="address?.value?.county">{{ address?.value?.county }}</mat-option>
            <mat-optgroup *ngFor="let country of countiesData" label="{{ country.state_name }}">
              <mat-option *ngFor="let county of country.counties" (click)="changeOption(country,i)" [value]="county?.county_name">{{ county?.county_name }}</mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
  </div>

</form>
