export class IPhoneType {
    public static Home: string = 'Home';
    public static Mobile: string = 'Mobile';
    public static Work: string = 'Work';
    public static Fax: string = 'Fax';
    public static Other: string = 'Other';

    public static getPhoneTypes() {
        return [
            { id: IPhoneType.Home, value: IPhoneType.Home.toLowerCase() },
            { id: IPhoneType.Mobile, value: IPhoneType.Mobile.toLowerCase() },
            { id: IPhoneType.Work, value: IPhoneType.Work.toLowerCase() },
            { id: IPhoneType.Fax, value: IPhoneType.Fax.toLowerCase() },
            { id: IPhoneType.Other, value: IPhoneType.Other.toLowerCase() },
        ]
    }
}