import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '@app/service/auth.service';
import { UserService } from '@data/services/user/user.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { Subject, first, takeUntil } from 'rxjs';

@Component({
  selector: 'app-configurable-settings',
  templateUrl: './configurable-settings.component.html',
  styleUrls: ['./configurable-settings.component.scss']
})
export class ConfigurableSettingsComponent implements OnInit {
  private _componentDestroyed$ = new Subject<boolean>;

  public isLoading = true;
  public configurableSettingForm: FormGroup;
  public message: any = {};
  public apiFail: boolean = false;

  constructor(
    private _authService: AuthService,
    private _fb: FormBuilder,
    private _toastMessageSvc: ToastMsgService,
    private _userService: UserService
  ) {
    this.configurableSettingForm = this._fb.group({
      //email reminder setting
      'sspr_configurable_settings': ['0'],
      //personal information update setting
      'sspup_configurable_settings': ['0']
    });
  }

  ngOnInit(): void {
    this.selfServiceOptionSearch();
  }

  public selfServiceOptionSearch() {
    let payload: any = {
      "search_type": "simple",
      "criteria": {
        "user_id": {
          "type": "simple",
          "value": [this._authService.user_details.user_id]
        }
      }
    }
    this._userService.selfServiceConfig(this._authService.user_details.user_id, payload).pipe(first()).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        response.forEach((configOption: any) => {
          if (configOption.self_service_config_option_type_name === "Self-Service Portal send reminder emails") {
            //email reminder setting value
            this.configurableSettingForm.patchValue({'sspr_configurable_settings': configOption.self_service_config_option_bit})
          } else if (configOption.self_service_config_option_type_name === "Self-Service Portal Personal Information Update") {
            //personal information update setting value
            this.configurableSettingForm.patchValue({'sspup_configurable_settings': configOption.self_service_config_option_bit})
          }
        })
      },
      error: (err) => {
        this.isLoading = false;
        this.apiFail = true;
        this.message = {
          type: 'alert',
          text: err.error.message
        };
        this.clearMessage(30000);
      }
    })
  }

  public selfServiceConfigUpdate(ssprValue: number, sspupValue: number) {
    let criteria: any = [{
        "self_service_config_option_type_id": "3",//email reminder setting
        "self_service_config_option_bit": ssprValue ? ssprValue : '0'
      },
      {
        "self_service_config_option_type_id": "4",//personal information update setting
        "self_service_config_option_bit": sspupValue ? sspupValue : '0'
      }];
    this._userService.selfServiceConfigUpdate(this._authService.user_details.user_id, criteria).pipe(first()).subscribe({
      next: (response: any) => {
        this._toastMessageSvc.showSuccess('Settings updated.')
      },
      error: (err) => {
        this.apiFail = true;
        this.message = {
          type: 'alert',
          text: err.error.message
        };
        this.clearMessage(30000);
      }
    })
  }

   /* clear notice message */
   public clearMessage(timer: number) {
    setTimeout(() => {
      this.message = this._toastMessageSvc.resetMessage();
    }, timer);
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
  }

}
