import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataSelfService } from '@data/data-self-service/data-self-service.service';
import { LastUpdatedBy } from '@data/lead/lead.interface';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-lead-last-updated',
  templateUrl: './lead-last-updated.component.html',
  styleUrls: ['./lead-last-updated.component.scss']
})
export class LeadLastUpdatedComponent implements OnInit, OnDestroy {

  private _componentDestroyed$ = new Subject<boolean>();
  @Input() lead_id: string;
  public displayedColumns: string[] = ['keyField', 'oldVal', 'newVal'];
  public updatedDataSource: any;
  public lastUpdatedDate: any;
  public showLeadLastUpdated: boolean = true;
  public lastUpdatedByUser: boolean = true;
  public leadLastUpdatedBy: any

  constructor(
    private _dataSelfService: DataSelfService,
  ) {
  }

  ngOnInit(): void {
    if (this.lead_id) {
     this.getLastUpdated()
    }
  }

  public getLastUpdated() {
    this._dataSelfService.updatedLeadDataSSP(this.lead_id).pipe(takeUntil(this._componentDestroyed$)).subscribe({
      next: (response: any) => {
        if (response?.lead_last_updated) {
          this.lastUpdatedDate = response.lead_last_updated;
        }
        if (response?.lead_last_updated_by) {
          this.leadLastUpdatedBy = response.lead_last_updated_by;
        }
        if (response?.Updated?.length > 0) {
          this.lastUpdatedByUser = false;
          const updates: LastUpdatedBy[] = response.Updated.map((update: any) => {
            const key = Object.keys(update)[0];
            return {
              keyField: key,
              newVal: update[key].new,
              oldVal: update[key].old
            };
          });
          this.updatedDataSource = new MatTableDataSource(updates);
        } else {
          this.lastUpdatedByUser = true;
        }
      },
      error: (err: any) => console.error(err),
    })
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
  }
}
