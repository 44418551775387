<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
    <h2 mat-dialog-title>Quick Search</h2>
    <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>

  <mat-divider></mat-divider>
  <form [formGroup]="leadSearchForm" (submit)="searchLead(leadSearchForm.value)">
    <ng-container *ngIf="isLoading; else quickSearch">
      <div class="loading-indicator p-4">
        <app-sms-spinner></app-sms-spinner>
      </div>
    </ng-container>
    <ng-template #quickSearch>
      <mat-dialog-content class="mat-typography">
        <div class="basic-container">
          <ng-container *ngIf="message.type">
            <app-sms-notice-banner #messageBanner [type]="message.type" (closeBanner)="clearMessage(0)">
              <p>{{ message.text }}</p>
            </app-sms-notice-banner>
          </ng-container>
          <mat-tab-group class="admin-sub-tab quick-search-tab" mat-align-tabs="start" headerPosition="above" animationDuration="0ms" [selectedIndex]="0" (selectedTabChange)="onTabChange($event)">
            <mat-tab label="Lead">
              <div class="flex flex-row gap-3 selectBox-top-margin">
                <mat-form-field class="w-1/4">
                  <mat-label>First Name</mat-label>
                  <input type="text" formControlName="fname" matInput autocomplete="off" (mousedown)="$event.stopPropagation()" [appInputMaxLength] appDisableAutofill />
                </mat-form-field>
                <mat-form-field class="w-1/4">
                  <mat-label>Last Name</mat-label>
                  <input type="text" formControlName="lname" matInput autocomplete="off" (mousedown)="$event.stopPropagation()" [appInputMaxLength] appDisableAutofill />
                </mat-form-field>
                <mat-form-field class="w-1/4">
                  <mat-label>Phone</mat-label>
                  <input type="text" formControlName="phone" [maxlength]="14" matInput autocomplete="off" appPhoneNumberMask (mousedown)="$event.stopPropagation()" appDisableAutofill />
                </mat-form-field>
                <mat-form-field class="w-1/4">
                  <mat-label>Email</mat-label>
                  <input type="text" formControlName="email" matInput autocomplete="off" (mousedown)="$event.stopPropagation()" [appInputMaxLength] appDisableAutofill />
                </mat-form-field>
              </div>
              <div class="flex flex-row gap-3 selectBox-top-margin">
                <mat-form-field class="w-1/4">
                  <mat-label>City</mat-label>
                  <input type="text" formControlName="city" matInput autocomplete="off" (mousedown)="$event.stopPropagation()" [appInputMaxLength] appDisableAutofill />
                </mat-form-field>
                <mat-form-field class="w-1/4">
                  <mat-label>County</mat-label>
                  <input type="text" formControlName="county" matInput type="text" autocomplete="off" (mousedown)="$event.stopPropagation()" [appInputMaxLength] appDisableAutofill />
                </mat-form-field>
                <mat-form-field class="w-1/4">
                  <mat-select formControlName="state_id" multiple placeholder="Select State" #leadState (openedChange)="triggerEvent(state)">
                    <input class="custom-input" #state placeholder="Search" autocomplete="off" />
                    <mat-option *ngFor="let el of state_data" [class.hide]="state.value !== '' && el.name ?.toLowerCase().indexOf(state?.value?.toLowerCase()) === -1" [value]="el.abbreviation">
                      {{ el.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-1/4">
                  <mat-label>Zip Code</mat-label>
                  <input formControlName="zip" matInput [minlength]="5" [maxlength]="5" type="text" autocomplete="off" appNumbersOnly (mousedown)="$event.stopPropagation()" appDisableAutofill />
                </mat-form-field>
              </div>
              <div class="flex flex-row gap-3 selectBox-top-margin">
                <mat-form-field class="w-1/3">
                  <mat-select [disabled]="false" placeholder="Birth Month" #birthMonth formControlName="dob_month">
                    <mat-option *ngFor="let month of monthsArray" [value]="month.id">{{ month.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-1/3">
                  <mat-label>Date of Birth (From)</mat-label>
                  <input formControlName="dobFrom" [max]="maxDate" matInput [matDatepicker]="dobFrom" appDate (mousedown)="$event.stopPropagation()" />
                  <mat-datepicker-toggle matSuffix [for]="dobFrom"></mat-datepicker-toggle>
                  <mat-datepicker #dobFrom></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="w-1/3">
                  <mat-label>Date of Birth (To)</mat-label>
                  <input formControlName="dobTo" [max]="maxDate" matInput [matDatepicker]="dobTo" appDate (mousedown)="$event.stopPropagation()" />
                  <mat-datepicker-toggle matSuffix [for]="dobTo"></mat-datepicker-toggle>
                  <mat-datepicker #dobTo></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="flex flex-row gap-3 selectBox-top-margin">
                <mat-form-field class="w-1/2">
                  <mat-label>Lead Date (From)</mat-label>
                  <input formControlName="leadDateFrom" matInput [matDatepicker]="leadFrom" appDate (mousedown)="$event.stopPropagation()" />
                  <mat-datepicker-toggle matSuffix [for]="leadFrom"></mat-datepicker-toggle>
                  <mat-datepicker #leadFrom></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="w-1/2">
                  <mat-label>Lead Date (To)</mat-label>
                  <input formControlName="leadDateTo" matInput [matDatepicker]="leadTo" appDate (mousedown)="$event.stopPropagation()" />
                  <mat-datepicker-toggle matSuffix [for]="leadTo"></mat-datepicker-toggle>
                  <mat-datepicker #leadTo></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="flex flex-row gap-3 selectBox-top-margin">
                <mat-form-field class="w-1/4">
                  <mat-select formControlName="lead_dispo_id" multiple placeholder="Select Dispositions" #leadDisp (openedChange)="triggerEvent(lead_disp)">
                    <input class="custom-input" #lead_disp placeholder="Search" autocomplete="off" />
                    <mat-option *ngFor="let el of total_lead_dispos" [class.hide]="lead_disp.value !== '' && el.dispo_name ?.toLowerCase().indexOf(lead_disp?.value?.toLowerCase()) === -1" [value]="el.dispo_id">
                      {{ el.dispo_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-1/4">
                  <mat-select formControlName="user_id" multiple placeholder="Select Agent" #leadAgent (openedChange)="triggerEvent(agent)">
                    <input class="custom-input" #agent placeholder="Search" autocomplete="off" />
                    <mat-option *ngFor="let el of total_agents" [class.hide]="agent.value !== '' && el.fname ?.toLowerCase().indexOf(agent?.value?.toLowerCase()) === -1" [value]="el.user_id">
                      {{ el.fname + " " + el.lname }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-1/4">
                  <mat-label>Tracking Code</mat-label>
                  <input formControlName="lead_vendor_source_code" matInput autocomplete="off" (mousedown)="$event.stopPropagation()" [appInputMaxLength] />
                </mat-form-field>
                <mat-form-field class="w-1/4">
                  <mat-select formControlName="tag_id" multiple placeholder="Select Tag" #leadTag (openedChange)="triggerEvent(tag)">
                    <input class="custom-input" #tag name="searchText" placeholder="Search" autocomplete="off" />
                    <mat-option *ngFor="let el of total_tags" [class.hide]="tag.value !== '' && el.tag_name ?.toLowerCase().indexOf(tag?.value?.toLowerCase()) === -1" [value]="el.tag_id">
                      {{ el.tag_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-tab>
            <mat-tab label="Updated Rx + Current Plan">
              <div class="flex flex-row gap-3 selectBox-top-margin">
                <mat-form-field class="w-1/2">
                  <select matNativeControl formControlName="user_id" placeholder="Select Agent" #leadAgent (openedChange)="triggerEvent(agent)">
                    <option *ngFor="let el of total_agents" [class.hide]="
                            agent.value !== '' &&
                            el.fname?.toLowerCase().indexOf(agent?.value?.toLowerCase()) === -1
                          " [value]="el.user_id" [selected]="el.user_id === leadAgent.value">
                      {{ el.fname + " " + el.lname }}
                    </option>
                  </select>
                </mat-form-field>
              </div>
              <div class="flex flex-row gap-3 selectBox-top-margin">
                <div class="w-1/2">
                  <mat-radio-group aria-label="Drug List Updated?" formControlName="drug_list_updated_flag" class="flex flex-row gap-3">
                    <p class="w-1/2">Drug List Updated?</p>
                    <span class="w-1/2">
                      <mat-radio-button value="1" (click)="drugUpdate('yes')">Yes</mat-radio-button>
                      <mat-radio-button value="0" (click)="drugUpdate('no')">No</mat-radio-button>
                    </span>
                  </mat-radio-group>
                  <div class="flex flex-row gap-3 selectBox-top-margin">
                    <mat-form-field class="w-1/2">
                      <mat-label>Date (From)</mat-label>
                      <input formControlName="drug_list_updated_date_from" matInput [matDatepicker]="dateFrom" appDate (mousedown)="$event.stopPropagation()" [required]="dateRequired" />
                      <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                      <mat-datepicker #dateFrom></mat-datepicker>
                      <mat-error *ngIf="checkError('drug_list_updated_date_from', 'required')">
                        This field is required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-1/2">
                      <mat-label>Date (To)</mat-label>
                      <input formControlName="drug_list_updated_date_to" matInput [matDatepicker]="dateTo" appDate (mousedown)="$event.stopPropagation()" [required]="dateRequired" />
                      <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
                      <mat-datepicker #dateTo></mat-datepicker>
                      <mat-error *ngIf="checkError('drug_list_updated_date_to', 'required')">
                        This field is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div>
                    <p>Date Range Applies to Recent Updates for Rx</p>
                  </div>
                </div>
                <div class="w-1/2">
                  <mat-radio-group aria-label="Has Current Plan?" formControlName="has_current_plan_flag" class="flex flex-row gap-3">
                    <p class="w-1/2">Has Current Plan?</p>
                    <span class="w-1/2">
                      <mat-radio-button value="1" (click)="leadPlanCurrent('yes', leadSearchForm.value.drug_list_updated_flag)">Yes</mat-radio-button>
                      <mat-radio-button value="0" (click)="leadPlanCurrent('no', leadSearchForm.value.drug_list_updated_flag)">No</mat-radio-button>
                    </span>
                  </mat-radio-group>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Application">
              <div class="flex flex-row selectBox-top-margin">
                <mat-form-field class="w-1/2">
                  <mat-select multiple formControlName="app_dispo_id" placeholder="Select Dispositions" #appDisp (openedChange)="triggerEvent(disp)">
                    <input class="custom-input" #disp name="searchText" placeholder="Search" autocomplete="off" (keydown)="$event.stopPropagation()" />
                    <mat-option *ngFor="let el of total_app_dispos" [class.hide]="
                            disp.value !== '' &&
                            el.dispo_name?.toLowerCase().indexOf(disp?.value?.toLowerCase()) === -1
                          " [value]="el.dispo_id">
                      {{ el.dispo_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-dialog-content>
    </ng-template>
    <mat-dialog-actions>
      <div class="w-full flex justify-end">
        <button mat-raised-button type="button" (click)="closePopover()">
          Cancel
        </button>
        <button mat-raised-button type="button" (click)="resetForm()">
          Reset
        </button>
        <button mat-flat-button type="submit" [disabled]="isDisabled && (!leadSearchForm.value.lead_plan_current
            || !leadSearchForm.value.updated_drug_list)">Search</button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
