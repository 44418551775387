<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <div cdkDragHandle class="flex justify-between items-baseline pr-4">
        <h2 mat-dialog-title>Quick Links</h2>
        <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>

    <mat-divider></mat-divider>


    <ng-container *ngIf="isLoading; else componentLoaded">
        <div class="loading-spinner">
            <app-sms-spinner [size]="'sm'">
                <span *ngIf="!isSubmit; else savingMessage">Retrieving Quick Links</span>
                <ng-template #savingMessage>Saving Changes</ng-template>
            </app-sms-spinner>
        </div>

    </ng-container>

    <ng-template #componentLoaded>
        <mat-dialog-content class="p-1">
                <ng-container *ngIf="isFFIGUser">
                    <mat-nav-list class="ql-list target-leads">
                        <a (click)="openSingleSignOn()" class="ql-item p-2 custom-link">Target Leads</a>
                    </mat-nav-list>
                </ng-container>

                <div cdkDropList (cdkDropListDropped)="rearrangeLinks($event)" cdkDropListLockAxis="y" [cdkDropListDisabled]="!isEdit">
                    <mat-nav-list class="ql-list">
                        <div *ngFor="let link of quickLinks">
                            <mat-list-item cdkDrag *ngIf="link?.display == '1' || isEdit">
                                <div class="flex flex-row items-center justify-between pl-1">
                                    <mat-icon matListItemIcon class="drag-handle w-[10%]" *ngIf="isEdit">menu</mat-icon>
                                    
                                    <span class="w-[70%]">
                                        <a *ngIf="!link.isCMSLink; else cmsLink" class="ql-item w-[100%] custom-link" [href]="link?.quicklink_url" target="_blank">{{ link.quicklink_title }}</a>
                                        <ng-template #cmsLink>
                                            <a class="ql-item w-[100%] custom-link" (click)="callPost($event)">{{ link.quicklink_title }}</a>
                                        </ng-template>
                                    </span>
                                    
                                    <span *ngIf="isEdit" class="w-[20%] flex justify-end">
                                        <ng-container *ngIf="link?.quicklink_hierarchy_type_name != 'System'">
                                            <button mat-icon-button matTooltip="Edit Link" color="primary" (click)="addLink(link)"><mat-icon>edit</mat-icon></button>
                                            <button mat-icon-button matTooltip="Delete Link" color="primary" (click)="confirmDelete(link.quicklink_id)"><mat-icon>delete</mat-icon></button>
                                        </ng-container>
                                    
                                        <button mat-icon-button color="primary" (click)="toggleDisplay(link)">
                                            <mat-icon *ngIf="link?.display == '1'" matTooltip="Hide Link">remove</mat-icon>
                                            <mat-icon *ngIf="link?.display == '0'" matTooltip="Show Link">add</mat-icon>
                                        </button>
                                    </span>
                                </div>
                            </mat-list-item>
                        </div>
                    </mat-nav-list>

                </div>

        </mat-dialog-content>

        <div mat-dialog-actions>
            <div class="w-full flex justify-between">
                <span>
                    <button mat-raised-button class="ql-cancel" (click)="toggleActionButtons()">
                        <span *ngIf="isEdit">Cancel</span>
                        <span *ngIf="!isEdit">Edit</span>
                    </button>
                    <button mat-icon-button matTooltip="Add New Link" (click)="addLink()"><mat-icon>add</mat-icon></button>
                </span>
                
                <button mat-flat-button *ngIf="isEdit" (click)="saveChanges()">Save</button>
            </div>
        </div>
    </ng-template>

</div>