import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, FormControl, Validators, FormGroupDirective, FormArray } from '@angular/forms'
import { validatorPatterns } from '@app/constants';
import { AdminService } from '@data/services/admin/admin.service';
import { LeadService } from '@data/services/lead/lead.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  private isErrorSubs: Subscription;
  private _componentDestroyed$ = new Subject<boolean>();
  private checkedIndex: number;
  private deletedItems: any = [];
  private getLeadLedgerSubs: Subscription;
  private getAdminFormArraySubs: Subscription;
  public emailForm: UntypedFormGroup;
  public email_content = [{
    name: 'Home',
    isSelected: false
  }, {
    name: 'Other',
    isSelected: false
  }, {
    name: 'Work',
    isSelected: false
  }];
  public submitted: boolean = false;
  public hasChange: boolean = false;
  @Input() formArrayName!: string;
  public emailListArr!: FormArray;
  @Input() type!: string;
  constructor(
    private fb: UntypedFormBuilder,
    private rootFormGroup: FormGroupDirective,
    private leadService: LeadService,
    private adminService: AdminService
  ) {
    this.isErrorSubs = this.leadService.getErrorAlert().subscribe(response => {
      if (response) {
        this.submitted = true;
        if (this.emailListArr) {
          this.emailListArr.controls.find((x: any) => {
            if (x.controls.email.status) { x.controls.email.touched = true }
          })
        }
      }
    })
    this.getLeadLedgerSubs = this.leadService.getLeadLedger().subscribe(response => {
      this.emailForm = this.rootFormGroup.control;
      this.emailListArr = this.emailForm.get(this.formArrayName) as FormArray;
    });
    this.getAdminFormArraySubs = this.adminService.getAdminFormArray().subscribe(response => {
      this.emailForm = this.rootFormGroup.control;
      this.emailListArr = this.emailForm.get(this.formArrayName) as FormArray;
    });
    /* code for to refresh the delete itmes */
    this.leadService.getFormChangeAlert().pipe(takeUntil(this._componentDestroyed$)).subscribe((elem: string) => {
      if (elem == 'clearArray') {
        this.deletedItems = [];
        if (this.emailListArr?.value?.length) {
          this.emailListArr.value.forEach((element: any) => {
            this.email_content.forEach((ele: any) => {
              if (element.phone_type == ele.name) {
                ele['isSelected'] = ele.name == 'Other' ? false : true;
              } else {
                ele['isSelected'] = false;
              }
            });
          });
        }
      }
    });

    /* code for to add the line through when add the phone || address */
    this.leadService.getCrossedItems().pipe(takeUntil(this._componentDestroyed$)).subscribe((elem: string) => {
      if (elem == 'phoneAdded' || elem == 'addressAdded') {
        this.emailForm = this.rootFormGroup.control;
        this.emailListArr = this.emailForm.get(this.formArrayName) as FormArray;
        if (this.emailListArr?.value) {
          this.emailListArr?.value.forEach((element: any, index: number) => {
            this.deletedItems.forEach((ele: number) => {
              if (index == ele) {
                element['email_class'] = 'true'
              }
            });
          });
        }
      }
    })
  }

  ngOnInit(): void {
    this.emailForm = this.rootFormGroup.control;
    this.emailListArr = this.emailForm.get(this.formArrayName) as FormArray;
    if (!this.emailListArr?.value?.length) {
      this.addEmailType({ name: 'Home' });
    } else {
      this.emailListArr.value.forEach((element: any) => {
        this.email_content.forEach((ele: any) => {
          if (element.email_type == ele.name) {
            ele['isSelected'] = ele.name == 'Other' ? false : true;
          }
        });
      });
    }
  }

  public addEmailType(emailType: any) {
    this.emails.push(
      this.fb.group({
        email_type: emailType.name,
        email: new FormControl('', [Validators.pattern(validatorPatterns.EMAIL)]),
        email_id: [''],
        email_class: [''],
        isSelected: ""
      })
    );
    emailType.isSelected = emailType.name == 'Other' ? false : true;
    /* code to select the previous default email when adding new email */
    this.emailListArr.value.find((x: any, i: number) => {
      if (this.checkedIndex) {
        if (i == this.checkedIndex) {
          x.isSelected = true;
        } else {
          x.isSelected = false
        }
      }
      if (this.deletedItems.includes(i)) {
        x.email_class = "true"
      } else {
        x.email_class = ""
      }
    })
    this.leadService.setCrossedItems('emailAdded');
  }

  public get emails(): UntypedFormArray {
    return this.emailForm.get("emails") as UntypedFormArray
  }

  //function to Strike-through the email value
  public clearEmail(value: any, index: number) {
    this.hasChange = true;
    value.email_class = "true";
    this.deletedItems.push(index)
    this.leadService.sendFormChangeAlert('valueChange');
  }

  //function to undo Strike-through the email value
  public refreshEmail(value: any, index: number) {
    if (this.deletedItems.includes(index)) {
      this.deletedItems.splice(index)
    }
    value.email_class = "";
    this.leadService.sendFormChangeAlert('');
  }

  //function called when checkbox is unchecked for email
  public emailCheckBoxvalue(checkbox: any, item: any, index: number) {
    this.hasChange = true;
    this.emails.value.forEach((val: any) => {
      if (val.email == item.email) {
        this.checkedIndex = index;
        val.isSelected = !val.isSelected;
      } else {
        val.isSelected = false;
      }
    });
    this.leadService.sendFormChangeAlert('valueChange');
  }

  public ngOnDestroy() {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
    if (this.isErrorSubs) this.isErrorSubs.unsubscribe();
    if (this.getLeadLedgerSubs) this.getLeadLedgerSubs.unsubscribe();
    if (this.getAdminFormArraySubs) this.getAdminFormArraySubs.unsubscribe();
  }
}
