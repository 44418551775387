import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { catchError, map, throwError } from 'rxjs';
import { DataSelfServiceWorkingLog } from './data-self-service.interface';

@Injectable({
  providedIn: 'root'
})
export class DataSelfService {

  constructor(
    private _http: HttpClient,
  ) { }

  public getWorkingTableList(payload: any) {
    return this._http.post<DataSelfServiceWorkingLog[]>((environment.api) + 'SelfServiceAgent/search', payload)
      .pipe(
        catchError(error => throwError(() => error))
      );
  }

  public overrideWorkingTable(payload: any) {
    return this._http.post<any>((environment.api) + 'SelfServiceAgent/Override', payload)
      .pipe(
        catchError(error => throwError(() => error)),
      );
  }
  
  public updatedLeadDataSSP(lead_id: any) {
    return this._http.get<any>((environment.api) + 'SelfServiceHistoryLead/' + lead_id)
      .pipe(
        catchError(error => throwError(() => error)),
      );
  }

}
