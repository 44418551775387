<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
    <h2 mat-dialog-title> Select an Outbound Dial Method </h2>
    <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-content>
    <div class="flex flex-col gap-2 mb-4">
      <h2 class="text-lg font-bold">Attention</h2>
      <p>Centers for Medicare and Medicaid Services (CMS) requires agents to record all calls with beneficiaries that are part of the chain of enrollment into a Medicare Advantage or Part D Plan.</p>
      <p>Phone.com provides a turnkey solution for satisfying CMS call recording requirements.</p>
    </div>

    <div class="flex justify-center gap-4">
      <mat-card class="w-80 px-2 py-4 text-center">
        <div class="h-full flex flex-col gap-4">
          <span class="text-4xl leading-none"><mat-icon inline>phone</mat-icon></span>
          <div class="grow flex flex-col justify-end items-center">
            <img src="assets/images/phonecom_glyph.png" class="w-10 leading-none mb-2" aria-hidden="true" />
            <p>Phone.com</p>
            <p class="italic">(automated call recording)</p>
          </div>
          <button mat-button type="button" class="phone-dialer-setup-button phone-dialer-option-action" mat-dialog-close (click)="dialWithPhoneDialer()">Dial with Automated Call Recording</button>
        </div>
      </mat-card>

      <mat-card class="w-80 px-2 py-4 text-center">
        <div class="h-full flex flex-col gap-4">
          <span class="text-4xl leading-none"><mat-icon inline>phone</mat-icon></span>
          <div class="grow flex flex-col justify-end items-center">
            <p>Standard Default</p>
            <p>(no call recording)</p>
          </div>
          <button mat-button mat-flat-button class="phone-dialer-option-action" (click)="dialWithBrowserDefault()">Dial with my Default Phone Dialer</button>
        </div>
      </mat-card>
    </div>
  </div>

  <div mat-dialog-actions align="center">
    <mat-checkbox (change)="updatePreference($event)">Remember my selection</mat-checkbox>
  </div>

</div>
