<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
    <h2 mat-dialog-title>Lead Details<ng-container *ngIf="lead_details">&nbsp;&dash;&nbsp;{{ lead_details?.fname }}&nbsp;{{ lead_details?.lname }}</ng-container></h2>
    <button type="button" *ngIf="!isLoading" mat-icon-button class="leading-none" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="!isLoading" class="flex items-center gap-2 px-4 mb-2">
    <app-lead-last-updated [lead_id]="lead_id"></app-lead-last-updated>
    <button mat-icon-button type="button" class="leading-none" matTooltip='If changes are submitted via Client Self-Service, the date of the most recent change to client information will display in this box. When the box is expanded, fields with changed values will show. If the "Confirm and Save" button is clicked in Client Self-Service and no change is made to the client&apos;s information, then the date and changes displayed in this expandable box will reflect changes as of the date of the most recent change, which may be in the past. See Conversation Log below to confirm most recent date client completed review of their information, even if they made no changes.'>
      <mat-icon class="text-emerald-800">help</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-content>
    <ng-container *ngIf="message.type">
      <app-sms-notice-banner #messageBanner [type]="message.type" (closeBanner)="clearMessage(0)">
        <p>{{ message.text }}</p>
      </app-sms-notice-banner>
    </ng-container>

    <ng-container *ngIf="isLoading; else contentLoaded">
      <div class="mx-auto w-96 h-32 loading-indicator">
        <app-sms-spinner></app-sms-spinner>
      </div>
    </ng-container>

    <ng-template #contentLoaded>
      <p class="update_msg" *ngIf="hasChange">Changes have been made. Select <a class="custom-link" title="Update Lead Detail Changes" (click)="updateLead('updateOnly')"><strong>"Update"</strong></a> to save them.</p>

      <form class="flex justify-between gap-2" autocomplete="off" [formGroup]="leadDeatilsForm">
        <!-- Left Column -->
        <div class="w-4/6">
          <!-- Lead Basic Info -->
          <div>
            <h4 class="user-profile-heading">Lead Information</h4>
            <div class="flex gap-2">
              <mat-form-field class="w-20">
                <mat-label>Prefix</mat-label>
                <input matInput formControlName="title" [maxlength]="6">
              </mat-form-field>
              <mat-form-field class="grow">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="fname" [maxlength]="25" appDisableAutofill>
                <mat-hint *ngIf="leadDeatilsForm.value.fname.length == 25">Max length is 25 characters</mat-hint>
              </mat-form-field>
              <mat-form-field class="w-20">
                <mat-label>Initial</mat-label>
                <input matInput formControlName="mname" [maxlength]="1" (input)="filterAlphaCharacters($event)">
              </mat-form-field>
              <mat-form-field class="grow">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lname" [maxlength]="25" appDisableAutofill>
                <mat-hint *ngIf="leadDeatilsForm.value.lname.length == 25">Max length is 25 characters</mat-hint>
              </mat-form-field>
            </div>
            <div class="flex gap-2">
              <mat-form-field class="grow">
                <mat-label>Birthdate</mat-label>
                <input matInput formControlName="dob" [max]="maxDate" appDate>
                <mat-error *ngIf="checkError('dob', 'invalidDate')">You must enter a valid date.</mat-error>
              </mat-form-field>
              <mat-form-field class="w-1/5">
                <mat-label>Gender</mat-label>
                <mat-select formControlName="gender" panelWidth="">
                  <mat-option *ngFor="let gender of gender_data" value="{{gender.value}}">{{gender.text}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="w-1/5">
                <mat-label>Marital Status</mat-label>
                <mat-select formControlName="marital_status" panelWidth="">
                  <mat-option *ngFor="let marital of marital_data" value="{{marital.value}}">{{marital.text}}</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="flex gap-2 w-36">
                <mat-form-field class="w-1/2">
                  <mat-label>Height</mat-label>
                  <input matInput title="Height in feet" formControlName="height_feet" (input)="checkFeetHeight($event.target)" appNumbersOnly>
                  <span matTextSuffix>ft</span>
                </mat-form-field>
                <mat-form-field class="w-1/2">
                  <mat-label></mat-label>
                  <input matInput title="Height in inches" formControlName="height_inches" (input)="checkInchesHeight($event.target)" appNumbersOnly>
                  <span matTextSuffix>in</span>
                </mat-form-field>
              </div>
              <mat-form-field class="w-28">
                <mat-label>Weight</mat-label>
                <input matInput formControlName="weight" (input)="checkWeight($event.target)" appNumbersOnly>
                <span matTextSuffix>lbs</span>
              </mat-form-field>
              <mat-form-field class="grow">
                <mat-label>SSN</mat-label>
                <input matInput formControlName="ssn" [maxLength]="11" appSocialSecurityMask>
              </mat-form-field>
            </div>
          </div>

          <!-- Lead Phone number list -->
          <app-phone *ngIf="isCommonFlag" formArrayName="phones" type="lead"></app-phone>

          <!-- Lead Email list -->
          <app-email *ngIf="isCommonFlag || !isLoading" formArrayName="emails" type="lead"></app-email>

          <!-- Lead Address list -->
          <app-address *ngIf="isCommonFlag" formArrayName="addresses" type="lead"></app-address>

          <div>
            <!-- Lead Preferred contact -->
            <h4 class="flex w-full user-profile-heading">Preferred Contact</h4>
            <div class="flex gap-2 place-items-center">
              <mat-form-field class="grow">
                <mat-label>Name</mat-label>
                <input matInput formControlName="contact_name" [maxlength]="50" appDisableAutofill>
                <mat-hint *ngIf="leadDeatilsForm.value.contact_name.length == 50">Max length is 50 characters</mat-hint>
              </mat-form-field>
              <mat-form-field class="grow-0">
                <mat-label>Type</mat-label>
                <mat-select formControlName="contact_phone_type">
                  <mat-option *ngFor="let contact of contact_data" value="{{contact.value}}">{{contact.text}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="grow">
                <mat-label>Telephone #</mat-label>
                <input matInput formControlName="contact_phone" appPhoneNumberMask [maxlength]="14" appDisableAutofill>
              </mat-form-field>
              <mat-form-field class="grow">
                <mat-label>Relationship</mat-label>
                <mat-select formControlName="contact_relationship">
                  <mat-option *ngFor="let relation of relation_data" value="{{relation.value}}">{{relation.text}}</mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-icon-button class="close" title="Clear Preferred Contact" (click)="clearPreferredContact()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div class="flex gap-2 w-full place-items-center">
              <label>Use as emergency contact?</label>
              <mat-radio-group formControlName="isEmergencyContact" (change)="radioChange($event)">
                <mat-radio-button value="1">Yes</mat-radio-button>
                <mat-radio-button value="0">No</mat-radio-button>
              </mat-radio-group>
            </div>

            <ng-container *ngIf="isEmergency"><!-- Lead Emergency contact -->
              <h4 class="user-profile-heading">Emergency Contact</h4>
              <div class="flex gap-2 place-items-center">
                <mat-form-field class="grow">
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="emergency_name" [maxlength]="50" appDisableAutofill>
                  <mat-hint *ngIf="leadDeatilsForm.value.emergency_name.length == 50">Max length is 50 characters</mat-hint>
                </mat-form-field>
                <mat-form-field class="grow-0">
                  <mat-label>Type</mat-label>
                  <mat-select formControlName="emergency_phone_type">
                    <mat-option *ngFor="let contact of contact_data" value="{{contact.value}}">{{contact.text}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="grow">
                  <mat-label>Telephone #</mat-label>
                  <input matInput formControlName="emergency_phone" appPhoneNumberMask [maxlength]="14" appDisableAutofill>
                </mat-form-field>
                <mat-form-field class="grow">
                  <mat-label>Relationship</mat-label>
                  <mat-select formControlName="emergency_relationship">
                    <mat-option *ngFor="let relation of relation_data" value="{{relation.value}}">{{relation.text}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-icon-button class="close" title="Clear Emergency Contact" (click)="clearEmergencyContact()">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </ng-container>
          </div>

        </div>

        <!-- Right Column -->
        <div class="w-2/6">
          <!-- Lead Medicare Card -->
          <h4 class="user-profile-heading">Medicare Card</h4>
          <div class="flex gap-2">
            <mat-form-field class="grow">
              <mat-label>Medicare Name</mat-label>
              <input matInput formControlName="medicare_name" [maxlength]="50" appDisableAutofill>
              <mat-hint *ngIf="leadDeatilsForm.value.medicare_name.length == 50">Max length is 50 characters</mat-hint>
            </mat-form-field>
            <mat-form-field class="grow">
              <mat-label>Medicare Number</mat-label>
              <input matInput formControlName="hicn" [maxlength]="15">
              <mat-hint *ngIf="leadDeatilsForm.value.hicn.length == 15">Max length is 15 characters</mat-hint>
            </mat-form-field>
          </div>
          <div class="flex gap-2">
            <mat-form-field class="grow">
              <mat-label>Medicaid Number</mat-label>
              <input matInput formControlName="medicaid_number" [maxlength]="20" appDisableAutofill>
              <mat-hint *ngIf="leadDeatilsForm.value.medicaid_number.length == 20">Max length is 20 characters</mat-hint>
            </mat-form-field>
            <mat-form-field class="grow">
              <mat-label>Medicaid State</mat-label>
              <mat-select #State formControlName="medicaid_state">
                <mat-option value="">None</mat-option>
                <mat-option *ngFor="let el of state_data" [value]="el.abbreviation">{{ el.abbreviation }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex gap-2">
            <mat-form-field class="grow">
              <mat-label>Part A Date</mat-label>
              <input matInput formControlName="medicare_parta_date" appDate [min]="partAB_MinDate" (mousedown)="$event.stopPropagation()">
              <mat-error *ngIf="leadDeatilsForm.get('medicare_parta_date')?.invalid">Part A date should be first of the month & can't be before 1965</mat-error>
            </mat-form-field>
            <mat-form-field class="grow">
              <mat-label>Part B Date</mat-label>
              <input matInput formControlName="medicare_partb_date" appDate [min]="partAB_MinDate" (mousedown)="$event.stopPropagation()">
              <mat-error *ngIf="leadDeatilsForm.get('medicare_partb_date')?.invalid">Part B date should be first of the month & can't be before 1965</mat-error>
            </mat-form-field>
          </div>

          <h4 class="w-full user-profile-heading">Lead Origination Data</h4><!-- Lead Origination Data -->
          <div class="flex gap-2" [ngClass]="checkForPermissions() ? 'not-allowed' : ''">
            <mat-form-field class="grow"><!-- Lead Source -->
              <mat-label>Lead Source</mat-label>
              <mat-select formControlName="source_vendor_id" [disabled]="checkForPermissions()">
                <mat-option *ngFor="let source of lead_vendor_data" value="{{source.lead_vendor_id}}">{{source?.lead_vendor_code + ' [' + source?.company + ']'}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="grow" [ngClass]="checkForPermissions() ? 'pointer-event' : ''"><!-- Advertising Code -->
              <mat-label>Advertising Code</mat-label>
              <input matInput formControlName="source_adcode" [readonly]="checkForPermissions()" [appInputMaxLength]>
            </mat-form-field>
          </div>
          <div class="flex gap-2" [ngClass]="checkForPermissions() ? 'not-allowed' : ''">
            <mat-form-field class="grow"><!-- B2B Partner ID -->
              <mat-label>Client/Partner/Affiliate</mat-label>
              <mat-select formControlName="b2bpartner_id" (selectionChange)="b2bChange($event.value)" [disabled]="checkForPermissions()">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let b2b of b2bPartner_data" value="{{b2b.b2bpartner_id}}">{{b2b.company}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="grow" *ngIf="lodEnabled"><!-- Tracking Code -->
              <mat-label>Tracking Code</mat-label>
              <mat-select formControlName="source_code" [disabled]="checkForPermissions()">
                <ng-container *ngFor="let tCode of tracking_data">
                  <mat-option *ngIf="tCode.active == '1'" value="{{tCode.tracking_code}}">{{tCode.tracking_code}}</mat-option>
                </ng-container>
                <mat-option *ngIf="lead_details && lead_details.source_code && tracking_data.length==0" value="{{lead_details.source_code}}">{{lead_details.source_code}}</mat-option>
              </mat-select>
              <mat-error *ngIf="checkError('source_code', 'required')">Tracking Code is a required field.</mat-error>
            </mat-form-field>
            <mat-form-field class="grow" *ngIf="!lodEnabled">
              <mat-label>Tracking Code</mat-label>
              <input matInput formControlName="source_code">
            </mat-form-field>
          </div>

          <div class="flex gap-2" [ngClass]="checkForPermissions() ? 'not-allowed' : ''">
            <mat-form-field class="grow"><!-- Campaign ID -->
              <mat-label>Campaign</mat-label>
              <mat-select formControlName="campaign_id" (selectionChange)="changeCampaign($event.value)" [disabled]="checkForPermissions()">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let camp of campaign_code" value="{{camp.campaign_id}}">{{camp.campaign_code}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="grow"><!-- Campagn Segment ID -->
              <mat-label>Segment</mat-label>
              <mat-select formControlName="campaign_segment_code" [disabled]="checkForPermissions()">
                <mat-option *ngFor="let seg of campaign_segment" value="{{seg.campaign_segment_code}}">{{seg.campaign_segment_code}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex gap-2" [ngClass]="checkForPermissions() ? 'not-allowed' : ''">
            <mat-form-field class="grow"><!-- Lead Type ID -->
              <mat-label>Lead Type</mat-label>
              <mat-select formControlName="lead_type_id" [disabled]="checkForPermissions()">
                <mat-option *ngFor="let type of lead_type" value="{{type.lead_type_id}}">{{type.lead_type_desc | titlecase}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="grow"><!-- Product Type -->
              <mat-label>Product Type</mat-label>
              <mat-select formControlName="product_type_id" [disabled]="checkForPermissions()">
                <mat-option *ngFor="let product of product_type" value="{{product.product_type_id}}">{{ product.product_type_name | titlecase }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Lead Contact Instuctions -->

          <!-- Lead Instruction Info -->
          <h4 class="user-profile-heading">Lead Instruction</h4>
          <div class="flex">
            <ul *ngIf="lead_details?.lead_instructions">
              <li *ngFor="let ins of lead_details?.lead_instructions">{{ins.lead_instruction_text}}</li>
            </ul>
          </div>


          <div class="flex gap-2 mt-1">
            <mat-form-field class="grow"><!-- Tags -->
              <mat-label>Tag</mat-label>
              <mat-chip-grid #chipList aria-label="Tag selection">
                <mat-chip-row *ngFor="let tag of tags" (removed)="removeTag(tag)">
                  <span>{{tag.tag_name}}</span>
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                <input placeholder="" class="tag-input" #tagInput formControlName="tags" [matAutocomplete]="auto" [matChipInputFor]="chipList" [maxlength]="20" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addTag($event,'')">
                <mat-error *ngIf="leadDeatilsForm.value.tags.length == 20" class="max-length-error">Max length is 20 characters</mat-error>
              </mat-chip-grid>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="getTagName" (optionSelected)="selected($event)">
                <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">{{tag.tag_name}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <button class="grow-0 text-nowrap" mat-flat-button color="primary" [disabled]="leadDeatilsForm.value.tags ==''" (click)="addTag(leadDeatilsForm.value.tags,'btn')">Add Tag</button>
          </div>


          <!-- Contact Permissions  -->
          <h4 class="user-profile-heading">Contact Permissions</h4>

          <p>I attest that I received client's permission to contact regarding <strong>Medicare Advantage</strong> and <strong>Medicare Part D</strong> via:</p>
          <div class="my-2">
            <mat-checkbox formControlName="do_not_email">Email</mat-checkbox>
            <mat-checkbox formControlName="do_not_call">Phone</mat-checkbox>
            <mat-checkbox formControlName="do_not_text">Text</mat-checkbox>
            <mat-checkbox formControlName="do_not_mail">Mail</mat-checkbox>
          </div>
          <span class="mr-2">Last updated by: {{ lead_details?.agent_fname + ' ' + lead_details?.agent_lname }}</span>
          <a class="linked-td" (click)="openPermissionHistory()" title="Show Permission History">Permission History</a>

          <!-- Lead Disposition -->
          <div>
            <h4 class="user-profile-heading">Latest Disposition</h4>
            <div class="flex gap-2">
              <mat-form-field class="grow">
                <mat-label>Date</mat-label>
                <input matInput formControlName="disposition_date" [matDatepicker]="disposition_date" appDate readonly class="not-allowed">
                <mat-datepicker-toggle matSuffix [for]="disposition_date"></mat-datepicker-toggle>
                <mat-datepicker #disposition_date></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="grow">
                <mat-label>Title</mat-label>
                <input matInput formControlName="dispo_name" readonly class="not-allowed">
              </mat-form-field>
              <mat-form-field class="grow">
                <mat-label>Description</mat-label>
                <input matInput formControlName="dispo_log" readonly class="not-allowed">
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </ng-template>

  </div>

  <div mat-dialog-actions align="end">
    <ng-container *ngIf="!isLoading">
      <button mat-raised-button (click)="closeModal()">{{ hasChange ? 'Cancel' : 'Close' }}</button>
      <button *ngIf="hasChange" mat-flat-button (click)="updateLead('updateOnly')">Update</button>
      <button *ngIf="hasChange" mat-flat-button (click)="updateLead('close')">Update + Close</button>
    </ng-container>
  </div>
</div>
