import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '@app/service/auth.service';
import { environment } from '@env/environment';
import { IQuickLink } from './quick-link.interface';

@Injectable({
  providedIn: 'root'
})
export class QuickLinksService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  public getQuickLinks() {
    let params = {
      criteria: { user_id: { type: "simple", value: [`${this.authService.getToken().user_id}`] } },
      search_type: "simple"
    }
    return this.http.post<IQuickLink[]>((environment.api) + `user/${this.authService.getToken().user_id}/quickLink/search`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public addAccountQuickLink(params: any) {
    return this.http.post((environment.api) + `account/${this.authService.account_id}/quicklink/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public addUserQuickLink(params: any) {
    return this.http.post((environment.api) + `user/${this.authService.getToken().user_id}/quicklink/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public updateAccountQuickLink(params: any, quicklink_id: number) {
    return this.http.post((environment.api) + `account/${this.authService.account_id}/quicklink/${quicklink_id}/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public updateUserQuickLink(params: any, quicklink_id: number) {
    return this.http.post((environment.api) + `user/${this.authService.getToken().user_id}/quicklink/${quicklink_id}/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public deleteQuickLink(quicklink_id: number) {
    return this.http.get((environment.api) + `user/${this.authService.getToken().user_id}/quicklink/${quicklink_id}/delete/`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public hideQuickLink(quicklink_id: number) {
    return this.http.get((environment.api) + `account/${this.authService.account_id}/quicklink/${quicklink_id}/accountHide/`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public revealQuickLink(quicklink_id: number) {
    return this.http.get((environment.api) + `account/${this.authService.account_id}/quicklink/${quicklink_id}/accountReveal/`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public displaySaveQuickLink(params: any) {
    return this.http.post((environment.api) + `user/${this.authService.getToken().user_id}/quicklink/displaySave/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public targetLeadsSSO() {
    return this.http.get((environment.api) + `quicklink/targetLeadsSSO`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

}
