<mat-toolbar id="lap-header" *ngIf="showHeader">
  <mat-toolbar-row>
    <div class="w-full flex items-center justify-between">
      <div class="flex items-center gap-1">
        <button mat-icon-button [disabled]="isDisabled" (click)="expandMenu()" aria-label="Menu">
          <mat-icon>menu</mat-icon>
        </button>

        <ng-container *ngIf="isLoading; else quickSearch">
          <app-sms-spinner size="sm"></app-sms-spinner>
        </ng-container>
        <ng-template #quickSearch>
          <button mat-icon-button (click)="openSearchDialog()" aria-label="Search">
            <mat-icon title="Quick Search">search</mat-icon>
          </button>
        </ng-template>

        <div class="flex items-center gap-1">
          <span class="font-bold text-4xl">{{ date }}</span>
          <span class="flex flex-col text-xs">
            <span class="font-bold">{{ month }} {{ year }}</span>
            <span>{{ day }} {{ time | uppercase }}</span>
          </span>
        </div>

      </div>

      <div class="flex items-center">
        <button mat-icon-button [disabled]="isLoadingNotifications" (click)="openNotification()" aria-label="Number of Unread Notification">
          <mat-icon [matBadgeHidden]="notification_count == 0" matBadgeColor="warn" [matBadge]="notification_count">notifications</mat-icon>
        </button>

        <button mat-button aria-label="Display More Options" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" (menuOpened)="openMenu()" (menuClosed)="showSubmenu = false">
          <mat-icon [ngClass]="{ 'rotate-90': showSubmenu }">chevron_right</mat-icon>
          <span>{{ userName }}</span>
        </button>
        <mat-menu #menu="matMenu" [overlapTrigger]="false">
          <ng-template matMenuContent>
            <ng-container *ngIf="isSubMenuLoading; else openedMenu">
              <div mat-menu-item [disabled]="true">
                <app-sms-spinner [size]="'sm'">
                  <span>Loading Menu Items</span>
                </app-sms-spinner>
              </div>
            </ng-container>
            <ng-template #openedMenu>
              <button mat-menu-item (click)="openUserProfile()">
                <mat-icon>manage_accounts</mat-icon> <span>Profile</span>
              </button>

              <button mat-menu-item *ngIf="configurableSettingsFlag" (click)="openConfigurableSettings()">
                <mat-icon>build</mat-icon> <span>Configurable Settings</span>
              </button>

              <button mat-menu-item *ngIf="hasPeproAccess" (click)="openPepro()">
                <mat-icon>explore</mat-icon> <span>Plan Explorer</span>
              </button>

              <button mat-menu-item *ngIf="(showPhoneDialer$ | async)" (click)="openCallRecordingUserSettings()">
                <mat-icon>voicemail</mat-icon> <span>Call Recording</span>
              </button>

              <button mat-menu-item (click)="logout()">
                <mat-icon>exit_to_app</mat-icon> <span>Logout</span>
              </button>
            </ng-template>

          </ng-template>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row *ngIf="!isProd() && showEnvBanner">
    <div class="w-[calc(100%+32px)] h-full -mx-4 px-4 flex gap-1 items-center bg-orange-400 text-black">
      <mat-icon>warning</mat-icon>
      <span class="text-lg"><span class="uppercase">Warning</span>: This is <span class="uppercase">not</span> a Production environment. Use for <span class="uppercase">testing</span> purposes <span class="uppercase">only</span>.</span>
    </div>
  </mat-toolbar-row>

</mat-toolbar>