import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sms-notice-banner',
  templateUrl: './sms-notice-banner.component.html',
  styleUrls: ['./sms-notice-banner.component.scss']
})
export class SmsNoticeBannerComponent implements OnInit {
  @Input() public type: string = 'announcement';
  @Input() public showIcon: boolean = true;
  @Input() public position: string = 'static';
  @Input() public canDismiss: boolean = true;
  @Output() public closeBanner = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() { }

  public closeNotice() {
    this.closeBanner.emit(true);
  }

}
