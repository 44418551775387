import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TOOLBAR } from '@app/constants';
import { AuthService } from '@app/service/auth.service';
import { Lead } from '@data/lead/lead.interface';
import { MomentService } from '@shared/services/moment/moment.service';
import { CalendarService } from '@data/services/calendar/calendar.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import moment from 'moment';
import { Editor } from 'ngx-editor';
import { LeadService } from '@data/services/lead/lead.service';
import { ConfirmDeleteComponent } from '@shared/modals/confirm-delete/confirm-delete.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CommonService } from '@shared/services/common/common.service';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent implements OnInit, OnDestroy {
  private lead_detail: any = [];
  private isAddConvTaskSubs: Subscription;
  private isAddTaskSubs: Subscription;
  private isAddDelSubs: Subscription;
  private _componentDestroyed$ = new Subject<boolean>();
  @Input() isFromCalendar: any;
  @Output() addEvent = new EventEmitter<string>();
  @Output() taskChange = new EventEmitter();
  public editor: Editor; // Editor;
  public toolbar: any = TOOLBAR;
  public displayAssignLead: boolean = false;
  public selectedLead: Lead;
  public clearSearch: boolean = false;
  public addTaskForm!: FormGroup;
  public leadName: string = "";
  public isEditAppointment: boolean = false;
  public isAssignLead: boolean = true;
  public appointment_id: string = "";
  public isAddTask: boolean = false;
  public errorMsg: string | null = null;
  public isNewApt: boolean = true;
  public isDateTime: boolean = true;
  public isConversationTask: boolean = false;
  public hasChange: boolean = false;
  public isButtonClicked:boolean=false;
  constructor(
    private formBuilder: FormBuilder,
    private momentService: MomentService,
    private authService: AuthService,
    private calendarService: CalendarService,
    private toastMsgService: ToastMsgService,
    private dialog: MatDialog,
    private leadService: LeadService,
    private commonService: CommonService
  ) {
    this.editor = new Editor();
  }

  public ngOnChanges() {
    this.onCreateGroupFormValueChange();
  }

  /* function to detect any changes in user profile form */
  public onCreateGroupFormValueChange() {
    const initialValue = this.addTaskForm?.value;
    this.addTaskForm?.valueChanges.pipe(takeUntil(this._componentDestroyed$)).subscribe(value => {
      if (this.addTaskForm?.dirty) {
        this.hasChange = Object.keys(initialValue).some(key => this.addTaskForm?.value[key] != initialValue[key]);
        this.taskChange.emit(true);
      }
    });
  }

  public ngOnInit(): void {
    if ((this.isFromCalendar.type) && (this.isFromCalendar.type === 'add')) {
      this.isAddTask = true;
    }
    if ((this.isFromCalendar.type) && (this.isFromCalendar.type === 'conversationLog')) {
      this.isAddTask = false;
      this.isAssignLead = false;
      this.isConversationTask = true;
      this.lead_detail = this.isFromCalendar.leadDetail;
      this.createCoversationTaskForm();
    } else {
      this.createForm();
    }
    this.onCreateGroupFormValueChange();
  }

  private createForm() {
    if (this.isAddTask) {
      this.isNewApt = true;
      let starTdate = this.isFromCalendar?.selectedDate?.date || new Date();
      const d = moment(starTdate);
      let hour = d.hours();
      let min = d.minutes();
      let hr;
      let m;
      let lastHr;
      if (hour < 10) {
        lastHr = hour + 1;
        if (lastHr < 10) {
          lastHr = "0" + lastHr.toString();
        }
        hr = "0" + hour.toString();
      } else {
        lastHr = hour + 1;
        hr = hour;
      }
      if (min < 10) {
        m = "0" + min.toString();
      } else {
        m = min;
      }
      let time = hr + ":" + m;
      if (lastHr == 24) {
        lastHr = '00';
      }
      lastHr = lastHr + ":" + m;
      this.addTaskForm = this.formBuilder.group({
        title: ['', Validators.required],
        completed: [false],
        importance: [false],
        startDate: [this.isFromCalendar ? starTdate : ''],
        start_time: [this.isFromCalendar ? time : ''],
        description: ['']
      });
    } else {
      this.isAddTask = false;
      this.isNewApt = false;
      this.appointment_id = this.isFromCalendar.id;
      this.isAssignLead = false;
      if (this.isFromCalendar.extendedProps.fname != '') {
        this.leadName = this.isFromCalendar.extendedProps.fname + " " + this.isFromCalendar.extendedProps.lname;
      }
      this.addTaskForm = this.formBuilder.group({
        title: [this.isFromCalendar ? this.isFromCalendar.title : '', Validators.required],
        completed: [this.isFromCalendar.extendedProps.completed_date == '' ? false : true],
        importance: [this.isFromCalendar.extendedProps.importance == '1' ? true : false],
        startDate: [this.isFromCalendar.start ? this.isFromCalendar.start : ''],
        start_time: [this.isFromCalendar.start ? this.convertTaskTime(this.isFromCalendar.start) : ''],
        description: [this.isFromCalendar.extendedProps.description]
      });
    }
    this.hasChange = false;
  }

  private convertTaskTime(start:any){
    const convertedTime = moment(start).format("HH:mm");
    return (convertedTime == '00:00') ? ' ' :  convertedTime;
  }

  public showSearchPanel() {
    this.displayAssignLead = true;
  }

  public removeSelectedLead() {
    this.clearSearch = true;
    this.selectedLead = {} as Lead;
    this.leadName = "";
    this.displayAssignLead = false;
  }

  public hideSearchPanel() {
    this.displayAssignLead = false;
  }

  public selectLead(lead: Lead) {
    this.selectedLead = lead;
    this.leadName = lead?.fname + "" + lead?.lname
  }

  /* method to check the date field is empty or not */
  public dateCheck(event: any) {
    if(!event.target.value && this.addTaskForm?.value.start_time){
      this.isDateTime = false;
    }else{
      this.isDateTime = true;
    }
  }

  /* method to check the time field is empty or not */
  public timeCheck(event: any) {
    if (event?.code == 'Backspace') {
      this.addTaskForm.patchValue({start_time: ''});
    }
    if(event.target.value && !this.addTaskForm?.value.startDate){
      this.isDateTime = false;
    }else{
      this.isDateTime = true;
    }
  }

  /* add tasks */
  public addTask() {
    if (this.addTaskForm.invalid) {
      return;
    } else {
      if ((this.isFromCalendar.type) && (this.isFromCalendar.type === 'conversationLog')) {
        let formValue = this.addTaskForm?.value;
        let payLoad = {
          appointment_type_id: "lead",
          completed_date: formValue?.completed ? moment().toISOString() : '',
          importance: formValue?.importance ? 1 : 5,
          description: formValue?.description,
          lead_id: this.lead_detail.lead_id,
          start_time: this.momentService.serverDateTime(formValue.startDate, formValue?.start_time),
          title: formValue?.title,
          user_id: this.authService.getToken().user_id
        }
        this.isButtonClicked = true;
        this.isAddConvTaskSubs = this.calendarService.addLeadAppointment(payLoad).subscribe({
          next: (response: any) => {
            if (response) {
              this.isButtonClicked = false;
              this.addTaskForm.markAsPristine();
              this.taskChange.emit(false);
              this.leadService.sendRefreshConvTable('refreshTable');
              this.closeModal();
              this.toastMsgService.showSuccess("Task saved successfully.");
            }
          },
          error: (err: any) => {
            this.isButtonClicked = false;
            this.showErrorMessage(err.error.message);
          },
          complete: () => { }
        });
      }else{
        let formValue = this.addTaskForm?.value;
        let params;
        params = {
          appointment_id: this.appointment_id != '' ? this.appointment_id : '',
          title: formValue?.title,
          completed_date: formValue?.completed ? moment().toISOString() : '',
          importance: formValue?.importance ? 1 : 5,
          description: formValue?.description,
          start_time: this.momentService.serverDateTime(formValue.startDate, formValue?.start_time?formValue?.start_time:'00:00'),
          appointment_type_id: this.selectedLead?.lead_id ? 'lead' : 'agent', // "agent" or "lead"  (if assign "lead" then lead otherwise "agent")
          user_id: `${this.authService.getToken().user_id}`,
          lead_id: this.selectedLead? this.selectedLead.lead_id : this.isFromCalendar?.extendedProps?.lead_id
        }
        this.isButtonClicked = true;
        this.isAddTaskSubs = this.calendarService.addAppointment(params).subscribe({
          next: (response: any) => {
            this.isButtonClicked = false;
            this.closeModal();
            this.addEvent.emit('true');
            this.calendarService.sendEventData(response);
            if (this.appointment_id == '') {
              this.toastMsgService.showSuccess("Task saved successfully.");
              if (this.isFromCalendar?.activeModule == 'dashboard')
              this.leadService.sendLeadLedger('refreshDashboard');
            } else {
              this.toastMsgService.showSuccess("Task updated successfully.");
            }
            this.leadService.sendRefreshConvTable('refreshTable');
          },
          error: (err) => {
            this.isButtonClicked = false;
            this.showErrorMessage(err.error.message);
          },
          complete: () => { }
        })
      }
    }
  }

  public copyTask() {
    this.isNewApt = true;
    this.isAssignLead = false;
    this.isAddTask = true;
    this.hasChange = true;
    this.appointment_id = "";
    this.calendarService.sendTitle('refreshTitle');
  }

  private showErrorMessage(errorMsg: string) {
    this.errorMsg = errorMsg;
    setTimeout(() => {
      this.errorMsg = null;
    }, 30000);
  }

  public deleteTask() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '30%',
      data: {
        'text': 'Are you sure you want to delete these records?'
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this._componentDestroyed$)).subscribe(res => {
      if (res) {
        this.isAddDelSubs = this.calendarService.deleteEvent(this.appointment_id).subscribe({
          next: (response: any) => {
            if (response) {
              this.toastMsgService.showSuccess("Task deleted successfully.");
              this.calendarService.sendDeleteData(this.appointment_id);
              this.leadService.sendRefreshConvTable('refreshTable');
              this.closeModal();
            }
          },
          error: (err) => {
            this.showErrorMessage(err.error.message);
          },
          complete: () => { }
        })
      }
    });
  }

  private createCoversationTaskForm() {
    this.addTaskForm = this.formBuilder.group({
      title: ['', Validators.required],
      completed: [false],
      importance: [false],
      startDate: [''],
      start_time: [''],
      description: ['']
    });
  }

  public goToLeadLedger(lead_id:string,event:any){
    lead_id = (this.selectedLead?.lead_id) ? this.selectedLead.lead_id : lead_id;
    this.closeModal();
    event.preventDefault();
    this.commonService.goToLeadLedger(lead_id);
  }

  public closeModal(){
    this.dialog.closeAll();
  }

  public clearMessage(){
    this.errorMsg = null;
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
    this.editor.destroy();
    if(this.isAddConvTaskSubs) this.isAddConvTaskSubs.unsubscribe();
    if(this.isAddTaskSubs) this.isAddTaskSubs.unsubscribe();
    if(this.isAddDelSubs) this.isAddDelSubs.unsubscribe();
  }

}
