import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddQuickLinkComponent } from '@modal/sidebar-modals/add-quick-link/add-quick-link.component';
import { ConfirmDeleteComponent } from '@shared/modals/confirm-delete/confirm-delete.component';
import { QuickLinksService } from '@data/services/quick-links/quick-links.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { SingleSignOnComponent } from '@modules/quotes/components/single-sign-on/single-sign-on.component';
import { Subject, Subscription, combineLatest, map, takeUntil, first } from 'rxjs';
import { CommonService } from '@shared/services/common/common.service';
import { IQuickLink } from '@data/services/quick-links/quick-link.interface';
import { IUserSettings } from '@data/user-profile/user-profile.interface';
import { AccountSettings } from '@data/admin/account-settings.interface';
import { AuthService } from '@app/service/auth.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit {

  private _quicklink_id_active_list: any[] = [];

  private _componentDestroyed$ = new Subject<boolean>() ;
  private _accountSettings: AccountSettings;
  private _userSettings: IUserSettings;
  private _responseToTargetLead: any;

  public ssoForm: FormGroup;
  public isEdit: boolean = false;
  public quickLinks: IQuickLink[];
  public isLoading: boolean = true;
  public isSubmit: boolean = false;
  public isFFIGUser: boolean = false;

  constructor(
    private _auth: AuthService,
    private _dialog: MatDialog,
    private _qlService: QuickLinksService,
    private _toastMsgService: ToastMsgService,
    public commonService: CommonService
  ) {
    combineLatest([
      this.commonService.accountSettings$,
      this.commonService.userSettings$,
    ])
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(([accountSettings, userSettings]) => {
        this._accountSettings = accountSettings;
        this._userSettings = userSettings;
      });

  }

  public ngOnInit(): void {
    this._checkFFIG_User();
    this.getQuickLinks();
  }

  public toggleActionButtons() {
    this.isEdit = !this.isEdit;
  }

  public rearrangeLinks(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.quickLinks, event.previousIndex, event.currentIndex);
  }

  public getQuickLinks(isReload: boolean = false) {
    this.isLoading = true;
    this.isEdit = false;

    combineLatest([
      this.commonService.getLoggedInAccountSettings(this.commonService.getAccountId()),
      this.commonService.getLoggedInUserSettings(this._auth.getToken().user_id),
      this._qlService.getQuickLinks()
    ])
      .pipe(
        first(),
        map( ([accountSettings, userSettings, quickLinks]) => {
          let ql = quickLinks;

          ql = this._hidePhoneDialerLink(ql);

          return {
            accountSettings: accountSettings,
            userSettings: userSettings,
            quickLinks: ql
          };
        })

      )
      .subscribe({
        next: (res) => {
          this.quickLinks = res.quickLinks;
          this.quickLinks.map(link => {
            link.isCMSLink = this._checkForCMSLink(link?.quicklink_url)
          });
          this.isLoading = false;
        },
        error: err => {
          this.isLoading = false;
        }

      });

  }

  private _checkForCMSLink(link: string = '') {
     return link.includes('cmscheck.seniormarketsales.com');
  }

  public callPost(e: Event) {
    e.preventDefault();
    this.commonService.callCMSPostCall();
  }

  private _hidePhoneDialerLink(quicklinks: IQuickLink[]) {
    let returnLinks = quicklinks;

    if (this._accountSettings.phone_dialer_provider_active != '1'
      || this._userSettings.phone_dialer_provider_active != '1')
        returnLinks = quicklinks.filter(ql => {
          return !ql.quicklink_url.includes('phone.com')
        });

    return returnLinks;
  }

  public addLink(link?: any) {
    let dialogRef = this._dialog.open(AddQuickLinkComponent, {
      width: '50%',
      data: link
    });

    dialogRef.afterClosed().pipe(takeUntil(this._componentDestroyed$)).subscribe(res => {
      if (res) {
        this.getQuickLinks();
      }
    });
  }

  public confirmDelete(quicklink_id: number) {
    let dialogRef = this._dialog.open(ConfirmDeleteComponent, {
      width: '30%',
      data: {
        'text': 'Are you sure you want to delete these records?'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this._componentDestroyed$)).subscribe(res => {
      if (res) {
        this._deleteQuickLink(quicklink_id)
      }
    });
  }

  private _deleteQuickLink(quicklink_id: number) {
   this._qlService.deleteQuickLink(quicklink_id)
    .pipe(first())
    .subscribe((res: any) => {
      if (res) {
        this._toastMsgService.showSuccess(res?.message ? res?.message : "Quick Link deleted successfully")
        this.getQuickLinks();
      }
    });
  }

  // show/hide +/- buttons against each link
  public toggleDisplay(link: any) {
    link.display = 1 - link.display;
  }

  public saveChanges() {
    this._quicklink_id_active_list = [];

    this.quickLinks.map(link => {
      this._quicklink_id_active_list.push({
        quicklink_id: link?.quicklink_id,
        active: link?.display
      })
    });

    let params = {
      quicklink_id_active_list: this._quicklink_id_active_list
    }

    this.isLoading = true;
    this.isSubmit = true;
    this.isEdit = false;

    this._qlService.displaySaveQuickLink(params)
      .pipe(first())
      .subscribe((res: any) => {
        this.isLoading = false;
        this.isSubmit = false;
      });
  }

  //Check for FFIG user
  private _checkFFIG_User() {
    this._qlService.targetLeadsSSO()
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          if (res?.url) {
            this.isFFIGUser = (res && res.url);
            this._responseToTargetLead = res;
          }
        },
        error: err => { }
      });
  }

  public openSingleSignOn() {
    this._dialog.open(SingleSignOnComponent, {
      width: '50%',
      autoFocus: false,
      data: {
        'data': this._responseToTargetLead,
        'plan': '',
        'quickLinkText': 'Select "Continue" to autosign onto the portal for Target Leads'
      }
    });
  }

  public ngOnDestroy(){
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
  }

}
