import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(rawNum : string) {
    const phoneString = rawNum.toString();
    const phonePattern = /(\d{3})(\d{3})(\d{4})/g;
    const formattedPhone: Array<string> = phoneString.split(phonePattern);

    return '(' + formattedPhone[1] + ') ' + formattedPhone[2] + '-' + formattedPhone[3] 
  }

}
