import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ICobrandDetails } from '@data/admin/cobrand-details';
import { Subject, first, catchError, map, shareReplay, throwError } from 'rxjs';
import { AccountSettings } from '@data/admin/account-settings.interface';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private _cachedCobrandDetails: ICobrandDetails;

  public cobrandDetails$ = new Subject<ICobrandDetails>();

  constructor(
    private _http: HttpClient
  ) { }

  public getAccountSettings(accountId: string) {
    return this._http.get<AccountSettings>((environment.api) + "account/" + accountId + "/settings").pipe(
      shareReplay(1),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public updateAccountSettings(account_id: string, payload: AccountSettings) {
    return this._http.post<AccountSettings>((environment.api) + "account/" + account_id + "/settings", payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public getCobrandDetails(accountId: string) {
    return this._http.get<ICobrandDetails>(environment.api + 'cobrand/load/' + accountId).pipe(
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  public getAccountAgents(accountId: string) {
    return this._http.get<ICobrandDetails>(environment.api + 'account/' + accountId + '/totalAgents/')
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  public getLoggedInCobrandDetails(accountId: string) {
    const cachedCobrandDetails = this._cachedCobrandDetails;

    if (!cachedCobrandDetails && accountId)
      return this.getCobrandDetails(accountId)
        .pipe(
          map( cobrandDetails => {
            this._cachedCobrandDetails = cobrandDetails;
            this.cobrandDetails$.next(cobrandDetails);

            return cobrandDetails;
          })
        );
    else
      setTimeout(() => {
        this.cobrandDetails$.next(cachedCobrandDetails);
      });

    return this.cobrandDetails$.asObservable();

  }

}
