<div class="expendable-panel" [ngClass]="{'display-hide': !lastUpdatedDate}">
    <mat-expansion-panel (opened)="showLeadLastUpdated = false" (closed)="showLeadLastUpdated = true"
        [disabled]="lastUpdatedByUser">
        <mat-expansion-panel-header [ngClass]="{'bkgrd-color': showLeadLastUpdated && !lastUpdatedByUser}">
            <mat-panel-title>
                <div class="lead-last-updated">

                    <ng-container *ngIf="lastUpdatedDate">
                        <span>Lead Detail Last Updated {{lastUpdatedDate}} by {{leadLastUpdatedBy}} </span>
                    </ng-container>

                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <span>*Only fields with updated values are displayed below.</span>
        <div class="table-container">
            <table mat-table [dataSource]="updatedDataSource" class="mat-elevation-z8 last-updated-table table-striped">
                <!-- Key Column -->
                <ng-container matColumnDef="keyField">
                    <th mat-header-cell *matHeaderCellDef class="bold"> Field </th>
                    <td mat-cell *matCellDef="let element" class="bold"> {{element.keyField}} </td>
                </ng-container>

                <!-- New Column -->
                <ng-container matColumnDef="oldVal">
                    <th mat-header-cell *matHeaderCellDef> Previous Information </th>
                    <td mat-cell *matCellDef="let element"> {{element.oldVal}} </td>
                </ng-container>

                <!-- Old Column -->
                <ng-container matColumnDef="newVal">
                    <th mat-header-cell *matHeaderCellDef> Updated Information </th>
                    <td mat-cell *matCellDef="let element"> {{element.newVal}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <span>Once an agent updates client information via LA Pro, this ledger will go away.</span>

    </mat-expansion-panel>
</div>
