import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Notice } from '@data/notice/notice.interface';
import { NotificationService } from '@data/notification/notification.service';
import { HeaderService } from '@data/services/header/header.service';
import { CommonService, formatLeadExportData } from '@shared/services/common/common.service';
import moment from 'moment';
import { first, Subscription } from 'rxjs';
@Component({
  selector: 'app-important-notice',
  templateUrl: './important-notice.component.html',
  styleUrls: ['./important-notice.component.scss'],
})
export class ImportantNoticeComponent implements OnInit {
  private dismissNoticeSubs: Subscription;
  public notice: Notice;
  public errorMsg: string | null = null;
  @ViewChild('leadExportDiv') csvDownloadLinkDiv: ElementRef;

  constructor(
    private headerService: HeaderService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<ImportantNoticeComponent>,
    private _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  @HostListener('click', ['$event.target']) public onClick(targetElement: any) {
    const notificationID = targetElement.getAttribute('id') || null;
    let exportType = this._notificationService.getExportType(this.notice.notice_title || '');

    if (this.csvDownloadLinkDiv?.nativeElement?.contains(targetElement) && notificationID) {
      this._notificationService.processExportDownloadNotification(exportType, notificationID, (this.showErrorMessage).bind(this));
    }
  }

  public ngOnInit(): void {
    this.data = formatLeadExportData(this.data);
    this.getQuickNotify();
  }

  public checkForCMS(notice: Notice) {
    if (
      notice?.notice_title?.indexOf(
        'IMPORTANT COMPLIANCE INFORMATION – CMS DISCLAIMER'
      ) != -1
    )
     this.commonService.callCMSPostCall();
  }
  /* get the quick notification message details */
  private getQuickNotify() {
    this.notice = this.data[0];
  }

  /* displaying the message when post is read and posted time */
  public displayedMessageFrom(toDate: any) {
    //get the moment time from but show "today" for anything less than a day ago
    var momentFrom = moment(toDate).fromNow();
    if (
      momentFrom.indexOf('second') > -1 ||
      momentFrom.indexOf('minute') > -1 ||
      momentFrom.indexOf('hour') > -1
    )
      return 'today';
    else return momentFrom;
  }

  /* dismiss the notification when close the popup */
  public dismissNotify(noticeid: string) {
    let data = {
      notice_ids: [noticeid],
    };
    this.dismissNoticeSubs = this.headerService
      .dismissNotice(data)
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.headerService.sendNotifyAlert('dismissNotify');
          this.dialogRef.close();
        },
        error: (err) => {
          this.showErrorMessage(err.error.message);
        },
        complete: () => {
          this.dismissNoticeSubs.unsubscribe();
        },
      });
  }

  private showErrorMessage(errorMsg: string) {
    this.errorMsg = errorMsg;
    setTimeout(() => {
      this.errorMsg = null;
    }, 30000);
  }

  /* clear notice message */
  public clearMessage(timer: number) {
    setTimeout(() => {
      this.errorMsg = null;
    }, timer);
  }
}
