import { Injectable, OnDestroy } from '@angular/core';
import { IUser } from '@data/user-profile/user-profile.interface';
import { environment } from '@env/environment';
import { initialize, LDClient, LDFlagValue, LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LaunchdarklyService implements OnDestroy {

  private _client: LDClient;

  constructor () { }

  // This function intializes the flag
  public init(userDetails: IUser | null): boolean {
    //Set up the user context
    const user = {
      kind: 'user'
    } as LDSingleKindContext;

    if (userDetails) {
      user.key = 'LAP.' + (environment.env).toUpperCase() + '.USER.' + userDetails?.user_id;
      user.name = userDetails?.fname + ' ' + userDetails?.lname;

    } else
      user.anonymous = true;

    this._client = initialize(environment.launchDarklyClientId, user)

    return !!this._client;
  }

  public getFlag(flagKey: string, defaultValue: LDFlagValue = false): Observable<LDFlagValue> {
    const fetchFlag = new Subject<void>();

    this._client.on(`change:${flagKey}`, () => {
      fetchFlag.next();
    });

    this._client.waitUntilReady().then(() => {
      fetchFlag.next();
    });

    return fetchFlag.pipe(
      map(() => {
        return this._client.variation(flagKey, defaultValue);
      }),
    );
  }

  async ngOnDestroy() {
    await this._client.close();
  }
}
