import { Injectable } from '@angular/core';
import { NOTICE_TYPE } from '@app/constants';
import { Subject } from 'rxjs';
import { INoticeMessage } from './notice-message.interface';

@Injectable({
  providedIn: 'root'
})
export class ToastMsgService {

  public subject = new Subject<INoticeMessage|null>();
  public isExpanded: boolean = false;

  private _timeout: NodeJS.Timeout;

  constructor() {}

  public showSuccess(msg: string, isFromComponent: boolean = false) {
    return this._showMessage({
      type: NOTICE_TYPE.Success,
      text: msg
    }, isFromComponent);
  }

  public showAnnouncement(msg: string, isFromComponent: boolean = false) {
    return this._showMessage({
      type: NOTICE_TYPE.Announcement,
      text: msg
    }, isFromComponent);

  }

  public showError(msg: string, isFromComponent: boolean = false) {
    return this._showMessage({
      type: NOTICE_TYPE.Alert,
      text: msg
    }, isFromComponent);

  }

  public showWarning(msg: string, isFromComponent: boolean = false) {
    return this._showMessage({
      type: NOTICE_TYPE.Warning,
      text: msg
    }, isFromComponent);

  }

  public closeNotice(msg: string) {
    this.subject.next({
      type: NOTICE_TYPE.Close,
      text: msg
    });

    this._clearMessage(NOTICE_TYPE.Close);
  }

  public resetMessage(): INoticeMessage {
    this.subject.next({
      type: '',
      text: ''
    });

    return {
      'type': '',
      'text': ''
    };
  }

  private _showMessage(
    msgObj: INoticeMessage,
    isFromComponent: boolean = false) : void | INoticeMessage {

    this._clearMessage();

    if (!isFromComponent) {
      this.subject.next(msgObj);
      this._clearMessage(msgObj.type)
      return;
    }

    return msgObj;
  }

  private _clearMessage(messageType?: string) {
    const maxTimeout: any = {
      'alert': 30000,
      'warning': 20000,
      'success': 15000,
      'announcement': 3000,
    }

    const timer = (messageType) ? maxTimeout[messageType] || 0 : 0;

    if (this._timeout)
      clearTimeout(this._timeout);

    this._timeout = setTimeout(() => {
      this.resetMessage();
    }, timer);
  }

}
