<div id="registrationForm">
    <div class="content leading-5">
        <img id="registration-logo" [src]="logo" aria-hidden="true" />

        <div class="regFormContainer">
            <ng-container *ngIf="!componentLoaded || inProgress; else regForm">
                <app-sms-spinner [data]="loadingMessage" size="1x"></app-sms-spinner>
            </ng-container>

            <ng-template #regForm>
                <p *ngIf="!displayInvalidAcct" class="p-2">Leverage your time, make more money and put your business in a position of distinction with <span class="_font--bold">Lead Advantage Pro!</span></p>

                <ng-container *ngIf="displayInvalidAcct || displayRoleExpired || displayAcctInfo ||  (registrationMessage$ | async)">
                    <mat-card appearance="outlined" *ngIf="(registrationMessage$ | async) == ''" class="regFormNotice" role="alert" [ngClass]="{
                            'alert': displayInvalidAcct || displayRoleExpired
                        }">
                        <mat-card-content>
                            <div class="flex flex-row gap-4 items-start">
                                <span class="text-4xl">
                                    <mat-icon inline class="icon" aria-hidden="true" [ngClass]="{ 'warn-alt': displayAcctInfo }">
                                        <ng-container *ngIf="displayInvalidAcct || displayRoleExpired; else infoIcon">warning</ng-container>
                                        <ng-template #infoIcon>warning_amber</ng-template>
                                    </mat-icon>
                                </span>
                                <p>
                                    <span *ngIf="displayInvalidAcct">
                                        The registration link used is no longer valid. Please contact Lead Advantage Pro support at 1-877-645-0147. If you'd like to register for Lead Advantage Pro please visit Lead Advantage Pro at <a href="https://seniormarketsales.com" target="_blank">https://seniormarketsales.com</a>.
                                    </span>
                                    <span *ngIf="displayRoleExpired">
                                        Please contact {{ accountOwnerName }} at {{ accountOwnerEmail }} or <span>{{ accountOwnerPhone | phone }}</span> as the user role is no longer valid for this link.
                                    </span>
                                    <span *ngIf="displayAcctInfo">
                                        Your registration will create a user profile within {{ accountOwnerName }}'s Lead Advantage Pro account ({{ accountId }}). If this is not what you intend, do not proceed. You may contact {{accountOwnerName}} at {{accountOwnerEmail}}<span *ngIf="accountOwnerPhone"> or {{ accountOwnerPhone | phone }}</span>.
                                    </span>
                                </p>
                                <mat-icon inline class="icon--close" (click)="closeNotice()">close</mat-icon>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card appearance="outlined" *ngIf="registrationAttempt && (registrationMessage$ | async)" class="regFormNotice" role="alert" [ngClass]="{
                            'alert': hasError,
                            'success': !hasError
                        }">
                        <mat-card-content>
                            <div class="flex flex-row gap-4 items-start text-white">
                                <mat-icon inline class="icon w-[5%]" aria-hidden="true">
                                    <ng-container *ngIf="!hasError; else errorIcon">done</ng-container>
                                    <ng-template #errorIcon>warning</ng-template>
                                </mat-icon>
                                <p class="w-[90%]" *ngIf="registrationMessage$ | async as regMessage" [innerHTML]="regMessage"></p>
                                <mat-icon inline class="icon--close w-[5%]" (click)="closeNotice()">close</mat-icon>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </ng-container>

                <form id="userRegistrationForm" name="userRegistrationForm" novalidate method="POST" autocomplete="off" *ngIf="!displayInvalidAcct" (submit)="submitForm()" [formGroup]="userRegistrationForm">
                    <p class=" text-xs p-2"><span class="requiredIndicator">*</span> denotes a required field.</p>
                    <mat-card appearance="outlined" class="mb-2">
                        <mat-card-content>
                            <mat-card-title class="text-center">Your ID and Password</mat-card-title>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>Username:</mat-label>
                                    <input matInput autocomplete="new-password" required name="login" maxlength="15" formControlName="login" />
                                    <mat-error *ngIf="login.hasError('required')">Username is <strong>required</strong>.</mat-error>
                                    <mat-error *ngIf="login.hasError('minlength') || login.hasError('maxlength') || login.hasError('pattern')">Username must be <strong>6-15 letters or numbers</strong>.</mat-error>
                                </mat-form-field>
                                <div class="w-1/2">
                                    <p class="py-2">Must be between 6-15 alphanumeric characters.</p>
                                </div>
                            </div>

                            <div class="flex flex-row gap-4">
                                <div class="w-1/2">
                                    <mat-form-field class="w-full">
                                        <mat-label>Password:</mat-label>
                                        <input matInput autocomplete="new-password" required name="password" type="password" formControlName="password" />
                                        <mat-error *ngIf="password.hasError('required')">Password is <strong>required</strong>.</mat-error>
                                        <mat-error *ngIf="password.hasError('pattern')">Password must meet the <strong>minimum strength</strong> as defined.</mat-error>
                                    </mat-form-field>

                                    <mat-form-field class="w-full">
                                        <mat-label>Confirm Password:</mat-label>
                                        <input matInput autocomplete="new-password" required name="confirmPwd" type="password" formControlName="confirmPwd" />
                                        <mat-error *ngIf="confirmPwd.hasError('required')">Must <strong>confirm</strong> Password.</mat-error>
                                        <mat-error *ngIf="confirmPwd.hasError('confirmedValidator')">Password must <strong>match</strong>.</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="w-1/2">
                                    <ul class="list-disc mx-2">
                                        <li>At least 8 characters long.</li>
                                        <li>Must contain at least 1 capital letter.</li>
                                        <li>Must contain at least 1 lower case letter.</li>
                                        <li>Must contain at least 1 number.</li>
                                        <li>Must contain at least 1 of the following special characters (!,&#64;,#,$,&,*).</li>
                                    </ul>
                                </div>
                            </div>

                        </mat-card-content>
                    </mat-card>

                    <mat-card appearance="outlined">
                        <mat-card-content>
                            <mat-card-title class="text-center">Contact Information</mat-card-title>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>Business Name:</mat-label>
                                    <input matInput name="business" maxlength="50" formControlName="company" />
                                </mat-form-field>
                            </div>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>First Name:</mat-label>
                                    <input matInput required name="fname" formControlName="fname" />
                                    <mat-error *ngIf="fname.hasError('required')">First Name is <strong>required</strong>.</mat-error>
                                    <mat-error *ngIf="fname.hasError('pattern')">First Name must contain <strong>letters, spaces,</strong> and <strong>hyphens</strong> only.</mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-1/2">
                                    <mat-label>Last Name:</mat-label>
                                    <input matInput required name="lname" formControlName="lname" />
                                    <mat-error *ngIf="lname.hasError('required')">Last Name is <strong>required</strong>.</mat-error>
                                    <mat-error *ngIf="lname.hasError('pattern')">Last Name must contain <strong>letters, spaces,</strong> and <strong>hyphens</strong> only.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>Email Address:</mat-label>
                                    <input matInput required type="email" name="email" formControlName="email" />
                                    <mat-error *ngIf="email.hasError('required')">Email Address is <strong>required</strong>.</mat-error>
                                    <mat-error *ngIf="email.hasError('email')">Enter a <strong>valid</strong> Email Address.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>Street Address 1:</mat-label>
                                    <input matInput required name="address1" formControlName="address1" />
                                    <mat-error *ngIf="address1.hasError('required')">Street Address 1 is <strong>required</strong>.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>Street Address 2:</mat-label>
                                    <input matInput name="address2" formControlName="address2" />
                                </mat-form-field>
                            </div>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>City:</mat-label>
                                    <input matInput required name="city" formControlName="city" />
                                    <mat-error *ngIf="city.hasError('required')">City is <strong>required</strong>.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>State:</mat-label>
                                    <mat-select required name="state" formControlName="state_id">
                                        <ng-container *ngFor="let state of statesList">
                                            <mat-option [value]="state.abbreviation">{{state.name}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error *ngIf="state_id.hasError('required')">State is <strong>required</strong>.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>Zip Code:</mat-label>
                                    <input matInput required name="zip" maxlength="5" appNumbersOnly formControlName="zip" />
                                    <mat-error *ngIf="zip.hasError('required')">Zip Code is <strong>required</strong>.</mat-error>
                                    <mat-error *ngIf="zip.hasError('pattern')">Enter a <strong>valid</strong> Zip Code.</mat-error>
                                </mat-form-field>
                                <div class="w-1/2">
                                    <p class="py-2">Please enter a five digit number.</p>
                                </div>
                            </div>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>Business Phone:</mat-label>
                                    <input matInput required type="tel" name="phone" maxlength="14" appPhoneNumberMask formControlName="phone" />
                                    <mat-error *ngIf="phone.hasError('required')">Business Phone is <strong>required</strong>.</mat-error>
                                    <mat-error *ngIf="phone.hasError('pattern')">Enter a <strong>valid</strong> Business Phone.</mat-error>
                                </mat-form-field>
                                <div class="w-1/2">
                                    <p class="py-2">Please enter a ten digit number.</p>
                                </div>
                            </div>


                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>Mobile Phone:</mat-label>
                                    <input matInput type="tel" name="mphone" maxlength="14" appPhoneNumberMask formControlName="mphone" />
                                    <mat-error *ngIf="mphone.hasError('pattern')">Enter a <strong>valid</strong> Mobile Phone.</mat-error>
                                </mat-form-field>
                                <div class="w-1/2">
                                    <p class="py-2">Please enter a ten digit number.</p>
                                </div>
                            </div>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>Fax Number:</mat-label>
                                    <input matInput type="tel" name="fphone" maxlength="14" appPhoneNumberMask formControlName="fphone" />
                                    <mat-error *ngIf="fphone.hasError('pattern')">Enter a <strong>valid</strong> Fax Number.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>National Producer Number (NPN):</mat-label>
                                    <input matInput name="npn" formControlName="npn" [readonly]="hasNoNPN.value == true" />
                                    <mat-error class="w-[200%]" *ngIf="npn.hasError('pattern')
                                            || npn.hasError('missingNPN')">Enter a <strong>valid</strong> NPN or click the checkbox if you do not have one.</mat-error>
                                </mat-form-field>
                                <div id="npnSearch" class="w-1/2">
                                    <ng-container *ngIf="!displayInvalidAcct && !displayRoleExpired">
                                        <a href="https://www.nipr.com/PacNpnSearch.htm" target="_blank">Find your NPN.</a>
                                        <mat-checkbox formControlName="hasNoNPN">I don't have an NPN</mat-checkbox>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-label>Website URL:</mat-label>
                                    <input matInput name="website" formControlName="website" />
                                </mat-form-field>
                            </div>

                        </mat-card-content>

                        <mat-card-actions *ngIf="(!this.userRegistrationForm.pristine && !hasError) || !disabledAccount" align="end">
                            <button [disabled]="inProgress" mat-button mat-flat-button color="primary" type="submit">Submit</button>
                        </mat-card-actions>

                    </mat-card>
                </form>
            </ng-template>

        </div>
    </div>
</div>
