import { Injectable, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { task } from "@data/calendar/calendar.interface";
import { dashboard } from "@data/dashboard/dashboard.interface";
import { LeadDetail } from "@data/lead/lead.interface";
import { VsigSoaComponent } from "@modules/lead/components/vsig-soa/vsig-soa.component";
import { F2fSoaComponent } from "@modules/lead/components/f2f-soa/f2f-soa.component";
import { LeadMissingRequirementsComponent } from "@modules/lead/components/lead-missing-requirements/lead-missing-requirements.component";
import { RemoteSoaComponent } from "@modules/lead/components/remote-soa/remote-soa.component";
import moment from 'moment';

@Injectable()
export class leadUtilityFunctions implements OnInit {

  constructor(
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {}
  public generateRequestPayload(user: any, agentESignatureID: string, lead: any, fomrData: any, type:string){
      let object = {
          "draft": "0",
          "lead_id": lead.lead_id,
          "app_id": "",
          "user_id": user.user_id,
          "client_name": lead.fname + " " + lead.lname,
          "client_email": lead.default_email.email ? lead.default_email.email : '',
          "client_dob": lead.dob,
          "client_zip": lead.default_address.zip,
          "process_type": type == 'remote' ? 'SOA' : "SOAF2F",
          "soa_id": "",
          "external_redirect_url": "",
          "notify_web_hook_url": "",
          "remote_invite_url": "",
          "alert_notice": "0",
          "alert_email": "1",
          "client_first_visit_date": "",
          "client_last_visit_date": "",
          "soa": {
            "lead_id": lead.lead_id,
            "user_id": user.user_id,
            "plan_id": "plan_id_1",
            "client_name": lead.fname + " " + lead.lname,
            "client_phone": lead.phone,
            "client_address": this.formatLeadAddress(lead.default_address),
            "rep_name": "",
            "rep_relation": "",
            "agent_name": user.fname + " " + user.lname,
            "agent_phone": user.default_phone.phone,
            "agent_email": user.default_email.email,
            "agent_address": user.default_address.address1 + " " + user.default_address.city + ", " + user.default_address.state_id + " " + user.default_address.zip,
            "contact_method": fomrData.contactMethod ? fomrData.contactMethod : "",
            "medicare_plans": null,
            "soa_just_in_time_reason": "",
            "appointment_date": fomrData.aptDate ? moment.utc(fomrData.aptDate).format('MM/DD/YYYY') : "",
            "access_date": "",
            "agent_esign_id": agentESignatureID
          }
      }
      return object;
  }

  private formatLeadAddress(leadAddresss: any) {
    var displayLeadAddress = "";
    if (leadAddresss && (leadAddresss.address1 && leadAddresss.address1.length > 0) && (leadAddresss.city && leadAddresss.city.length > 0) && (leadAddresss.state_id && leadAddresss.state_id.length > 0) && (leadAddresss.zip && leadAddresss.zip.length > 0)) {
      var displayLeadAddress2 = (leadAddresss.address2 && leadAddresss.address2.length > 0) ? leadAddresss.address2 + ", " : '';
      displayLeadAddress = leadAddresss.address1 + ", " + displayLeadAddress2 + leadAddresss.city + ", " + leadAddresss.state_id + " " + leadAddresss.zip;
    }
    return displayLeadAddress;
  }

  public convertCalendarResponse(object:task){
    let obj: dashboard = {
      "title": object.title,
      "start": object.start_time ? object.start_time : '',
      "id": object.appointment_id,
      "allDay": object.allday_flag,
      "end": object.end_time,
      "extendedProps": {
        "user_id": object.user_id,
        "fname": object.fname,
        "lname": object.lname,
        "lead_id": object.lead_id,
        "description": object.description,
        "importance": object.importance,
        "completed_date": object.completed_date,
        "appointment_type_id": object.appointment_type_id,
      }
    }
    return obj;
  }

  public openSOAModal(leadDetails: LeadDetail, soaType: string, checkReq: boolean = true) {
    window.scroll(0, 0);

    const leadMissingRequirements = {
      lead_id: leadDetails.lead_id,
      fname: !(leadDetails.fname.length),
      lname: !(leadDetails.lname.length),
      dob: !(leadDetails?.dob?.length),
      phone: !(leadDetails?.default_phone?.phone?.length),
      address1: !(leadDetails?.default_address?.address1?.length),
      city: !(leadDetails?.default_address?.city?.length),
      state: !(leadDetails?.default_address?.state_id?.length),
      zip: !(leadDetails?.default_address?.zip?.length),
    };

    const dialogOptions = {
      width: '60%',
    };

    if (checkReq &&
      !(
        leadMissingRequirements['fname']
        || leadMissingRequirements['lname']
        || leadMissingRequirements['dob']
        || leadMissingRequirements['phone']
        || leadMissingRequirements['address1']
        || leadMissingRequirements['city']
        || leadMissingRequirements['state']
        || leadMissingRequirements['zip']
      )
    ) {

      if (soaType == 'f2f')
        this._dialog.open(F2fSoaComponent, {...dialogOptions});

      else
        this._dialog.open(RemoteSoaComponent, { ...dialogOptions });

    } else if (!checkReq) {
      if (soaType == 'vsig')
        this._dialog.open(VsigSoaComponent, { ...dialogOptions });

    } else {
      this._dialog.open(LeadMissingRequirementsComponent, {
        ...dialogOptions,
        data: leadMissingRequirements
      });
    }
  }


}