import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastMsgService: ToastMsgService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status === 500) {
         //display red toast for 500 errors
         this.toastMsgService.showError(error.error.message);
        }
        return throwError(() => error);
      })
    );
  }
}
