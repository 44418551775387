import { Injectable } from '@angular/core';
import moment from 'moment';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MomentService {

  public convertedDataArray: any;
  constructor(private datePipe: DatePipe) { }

  public serverDateTime(date: any, time?: any) {
    if (time) {
      let hours = time.split(":")[0];
      let minutes = time.split(":")[1];
      let serverDate = moment(date).set({ h: hours, m: minutes }).toISOString();
      return serverDate;
    } else {
      let serverDate = moment(date).toISOString();
      return serverDate;
    }
  }

  //Convert UTC times
  public convertAndFormatZuluTimes(dateTime: any) {
    this.convertedDataArray = dateTime.map((dataObj: any) => {
      let convertedEndTime = this.datePipe.transform(dataObj.end_time, 'MMM d, y, h:mm a', '+0000', 'en-US');
      let convertedStartTime = this.datePipe.transform(dataObj.start_time, 'MMM d, y, h:mm a', '+0000', 'en-US');
      if (dataObj.start_time != '') {
        let dateStartTime = new Date(dataObj.start_time);
        let startTime =  moment(dateStartTime).tz('America/Chicago').format('HH:mm:ss');
        if (startTime == '00:00:00') {
          convertedStartTime = this.datePipe.transform(dataObj.start_time, 'MMM d, y');
        } else {
          convertedStartTime = this.datePipe.transform(dataObj.start_time, 'MMM d, y, h:mm a');
        }
        dataObj.start_time = convertedStartTime;
      }
      if (dataObj.end_time != '') {
        let dateEndTime = new Date(dataObj.end_time);
        let endTime = moment(dateEndTime).tz('America/Chicago').format('HH:mm:ss');
        if (endTime == '00:00:00') {
          convertedEndTime = this.datePipe.transform(dataObj.end_time, 'MMM d, y');
        } else {
          convertedEndTime = this.datePipe.transform(dataObj.end_time, 'MMM d, y, h:mm a');
        }
        dataObj.end_time = convertedEndTime;
      }
      return { ...dataObj, convertedStartTime, convertedEndTime };
    });
  }

}
