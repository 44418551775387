import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@app/service/auth.service';
import { Criteria, SearchCriteria } from '@data/quick-search/quick-search.interface';
import { HeaderService } from '@data/services/header/header.service';
import * as data from '@data/states.json';
import { CommonService } from '@shared/services/common/common.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { MONTH_ARRAY } from '@app/constants';
@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss'],
})
export class QuickSearchComponent implements OnInit {

  private account_id: any;
  private leadViewAssigned :boolean;
  private dropDownData:any=[];
  private dropDownDataSubs: Subscription;
  private isQuickSearch : boolean = false;
  private _componentDestroyed$ = new Subject<boolean>();

  public leadSearchForm: UntypedFormGroup;
  public state_data: any = [];

  @ViewChild('appDisp') public appDisp: any;
  @ViewChild('leadTag') public leadTag: any;
  @ViewChild('leadAgent') public leadAgent: any;
  @ViewChild('leadDisp') public leadDisp: any;
  @ViewChild('leadState') public leadState: any;

  public total_lead_dispos: any = [];
  public total_agents: any = [];
  public total_tags: any = [];
  public total_app_dispos: any = [];
  public maxDate: Date;
  public tab_index: number = 0;
  public message: any = {};
  public isLoading: boolean = false;
  public isDisabled: boolean = false;
  public dateRequired: boolean = false;
  public loggedInUserID: any = this.authService.getToken().user_id;
  public monthsArray:{id:number,name:string}[] = MONTH_ARRAY;
  constructor(
    private router: Router,
    private headerService: HeaderService,
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private authService: AuthService,
    private toasMsgService: ToastMsgService,
    public dialog: MatDialog,
    _commonService: CommonService,
  ) {
    this.leadViewAssigned = this.commonService.hasPermission('lead_view_assigned');
    this.leadSearchForm = this.fb.group({
        fname: [''],
        lname: [''],
        phone: [''],
        email: [''],
        city: [''],
        county: [''],
        state_id: [''],
        zip: [''],
        dob_month:[''],
        dobFrom: [''],
        dobTo: [''],
        leadDateFrom: [''],
        leadDateTo: [''],
        lead_dispo_id: [''],
        user_id: [null],
        lead_vendor_source_code: [''],
        tag_id: [''],
        app_dispo_id: [''],
        drug_list_updated_flag: [''],
        has_current_plan_flag: [''],
        drug_list_updated_date_from: ['', []],
        drug_list_updated_date_to: ['', []]
      },
      {
        validator: this.atLeastOne(Validators.required),
      }
    );

    /* get states data */
    this.state_data = (data as any).default;
    this.maxDate = new Date();

    this.account_id = _commonService.getAccountId();
  }

  public ngOnInit(): void {
    this.getDropDowndata();
  }

  public getDropDowndata() {
    this.isLoading = true;
    /* get quick search data from header */
    this.dropDownDataSubs = this.headerService.populateDropDowns()
      .subscribe({
        next : (response:any) =>{
          this.isLoading = false;

          if (response?.length) {
            this.dropDownData = response;

            if(this.dropDownData[0]['total_lead_dispos']) {
              this.total_lead_dispos = this.dropDownData[0]['total_lead_dispos'];
            }

            let obj = [{ "user_id": 0, "fname": "Unassigned", "lname": "" }];
            if (this.dropDownData[0]['total_agents']
              && this.dropDownData[0]['total_agents'].length > 0
              && this.leadViewAssigned) {
              this.total_agents = this.dropDownData[0]['total_agents'];
              this.total_agents = [...this.total_agents, ...obj]

            } else if (this.dropDownData[0]['total_agents'] == ''
              && this.leadViewAssigned) {
              this.total_agents = [];
              this.total_agents = [...this.total_agents, ...obj]

            } else if (this.dropDownData[0]['total_agents'] == ''
              && !this.leadViewAssigned) {
              this.total_agents = [];
              this.total_agents = [...this.total_agents, ...[{
                "user_id": this.authService.getToken().user_id,
                "fname": this.authService.user_details['fname'],
                "lname": this.authService.user_details['lname']
              }]];

            }

            if (this.dropDownData[0]['total_tags'])
              this.total_tags = this.dropDownData[0]['total_tags'];

            if (this.dropDownData[0]['total_app_dispos'] != '')
              this.total_app_dispos = this.dropDownData[0]['total_app_dispos'];

          } else {
            this.dropDownData = [];
          }
        },
        error : (err) =>{
          this.isLoading = false;
          this.message = {
            type: 'alert',
            text: err.error.message,
          };
          this.clearMessage(30000);
        },
        complete : () => { }
      });
  }

  public triggerEvent(event: any) {
    event.value = '';
  }

  public onTabChange(event: any) {
    this.tab_index = event.index;
    this.resetForm();
    this.isDisabled = (event.tab.textLabel == "Updated Rx + Current Plan");
    if (this.isDisabled) {
      const loggedInUser = this.total_agents.find((agent: { user_id: any; }) => agent.user_id == this.loggedInUserID);
      if (loggedInUser) {
      this.leadSearchForm.get('user_id')?.setValue([loggedInUser.user_id]);
      }
    }
  }


  public searchLead(formData: any) {
    if (this.tab_index === 0) {
      this.dateRequired = false;
      /* search by lead  */
      if (this.leadSearchForm.errors) {
        if (this.leadSearchForm.errors['atLeastOne']) {
          this.message = {
            type: 'alert',
            text: 'You are required to fill at least one field',
          };
          this.clearMessage(30000);
          return;
        }
      } else {
        let obj: Criteria = {
          account_id: { type: 'simple', value: [this.account_id] },
          fname: { type: 'simple', value: [formData.fname] },
          lname: { type: 'simple', value: [formData.lname] },
          phone: {
            type: 'simple',
            value: [formData.phone ? formData.phone.replace(/\W/g, '') : ''],
          },
          email: { type: 'simple', value: [formData.email] },
          city: { type: 'simple', value: [formData.city] },
          county: { type: 'simple', value: [formData.county] },
          state_id: { type: 'simple', value: formData.state_id },
          dob_month: { type: 'simple', value: [formData.dob_month] },
          zip: { type: 'simple', value: [formData.zip] },
          dob: {
            type: 'simple',
            value: [
              formData.dobFrom == ''
                ? '*'
                : this.commonService.formatYYYYMMDD(formData.dobFrom),
              formData.dobTo == ''
                ? '*'
                : this.commonService.formatYYYYMMDD(formData.dobTo),
            ],
          },
          lead_date: {
            type: 'range',
            value: [
              formData.leadDateFrom == ''
                ? '*'
                : this.commonService.formatYYYYMMDD(formData.leadDateFrom),
              formData.leadDateTo == ''
                ? '*'
                : this.commonService.formatYYYYMMDD(formData.leadDateTo),
            ],
          },
          lead_dispo_id: {
            type: 'simple',
            value: this.convertToString([formData.lead_dispo_id]),
          },
          user_id: {
            type: 'simple',
            value: formData.user_id && formData.user_id.length>0 ? this.convertToString([formData.user_id]) : [`${this.authService.getToken().user_id}`]
          },
          lead_vendor_source_code: {
            type: 'simple',
            value: [formData.lead_vendor_source_code],
          },
          tag_id: {
            type: 'simple',
            value: this.convertToString([formData.tag_id]),
          },
        };
        this._cleanupSearchCriteria(obj, formData, "quickSearch");
        this.quickSearch(obj);
      }
    } else if (this.tab_index === 1) {
      if (this.leadSearchForm.status != 'INVALID') {
      /* search by app */
        let obj: Criteria = {
          account_id: { type: 'simple', value: [this.account_id] },
          has_current_plan_flag: { type: "simple", value: [this.leadSearchForm.value.has_current_plan_flag] },
          user_id: {
            type: 'simple',
            value: formData.user_id ? this.convertToString([formData.user_id]) : [`${this.authService.getToken().user_id}`]
          },
          drug_list_updated_flag: { type: "simple", value: [this.leadSearchForm.value.drug_list_updated_flag] },
          drug_list_updated_date_from: { type: "simple", value: [this.commonService.formatYYYYMMDD(this.leadSearchForm.value.drug_list_updated_date_from)] },
          drug_list_updated_date_to: { type: "simple", value: [this.commonService.formatYYYYMMDD(this.leadSearchForm.value.drug_list_updated_date_to)] }
        };
        this._cleanupSearchCriteria(obj, formData, "leadPlanSearch");
        this.quickSearch(obj);
      }
    } else {
      /* search by app */
      if (formData['app_dispo_id'].length == 0 || !formData['app_dispo_id']) {
        this.message = {
          type: 'alert',
          text: 'Enter one or more application dispositions and click search.',
        };
        this.clearMessage(30000);
        return;
      } else {
        let obj: Criteria = {
          account_id: { type: 'simple', value: [this.account_id] },
          user_id: {
            type: 'simple',
            value: formData.user_id ? this.convertToString([formData.user_id]) : [`${this.authService.getToken().user_id}`]
          },
          app_dispo_id: {
            type: 'simple',
            value: this.convertToString([formData.app_dispo_id]),
          },
        };
        if (!formData.app_dispo_id) delete obj['app_dispo_id'];
        this._cleanupSearchCriteria(obj, formData, "appDispoSearch");
        this.quickSearch(obj);
      }
    }
  }

  private _cleanupSearchCriteria(obj: any, formData: any, searchType: string) {
    Object.keys(obj).forEach(key => {
      if (!formData[key]) {
        if (!formData.dobFrom && !formData.dobTo) {
          delete obj['dob'];
        }
        if (!formData.leadDateFrom && !formData.leadDateTo) {
          delete obj['lead_date']
        }
        if (this.leadViewAssigned && !formData.user_id && (searchType == 'quickSearch' || searchType == 'appDispoSearch')) {
          delete obj['user_id']
        }
        if (!this.account_id || isNaN(this.account_id)) {
          delete obj['account_id'];
        }
        if (key != "account_id" && key != "dob" && key != "lead_date" && key != 'user_id') {
          delete obj[key];
        }
      }
    });
    return obj;
  }

  /* method for quick search */
  private quickSearch(obj: any) {
    if(!this.isQuickSearch){
      let input: SearchCriteria = {
        search_type: 'simple',
        criteria: obj,
        field_list: '',
        page_number: 1,
        records_per_page: 200,
        include_deleted: 0,
      };
      this.headerService.sendSearchAlert(input);
    }
    this.isQuickSearch = true;
    this.closePopover();
    this.router.navigate(['/lead/search']);
  }

  /* method to close the popover */
  public closePopover() {
    this.dialog.closeAll();
  }

  public resetForm() {
    this.dateRequired = false;
    if (this.tab_index === 1) {
      this.isDisabled = true;
    }
    this.leadSearchForm = this.fb.group(
      {
        fname: [''],
        lname: [''],
        phone: [''],
        email: [''],
        city: [''],
        county: [''],
        state_id: [''],
        zip: [''],
        dob_month:[''],
        dobFrom: [''],
        dobTo: [''],
        leadDateFrom: [''],
        leadDateTo: [''],
        lead_dispo_id: [''],
        user_id: [''],
        lead_vendor_source_code: [''],
        tag_id: [''],
        app_dispo_id: [''],
        drug_list_updated_flag: [''],
        has_current_plan_flag: [''],
        drug_list_updated_date_from: ['', []],
        drug_list_updated_date_to: ['', []]
      },
      {
        validator: this.atLeastOne(Validators.required),
      }
    );

  }

  public leadPlanCurrent(value: any, drugUpdated: any) {
    if (drugUpdated == "") {
      this.isDisabled = false;
      this.dateRequired = false;
    }
  }

  public drugUpdate(value: any) {
    if (value != "") {
      this.dateRequired = true;
      this.isDisabled = false;
    }
  }
  public checkError = (controlName: string, errorName: string) => {
    return this.leadSearchForm.controls[controlName].hasError(errorName);
  }

  /* method for validate the at least one filed is required */
  atLeastOne =
    (validator: ValidatorFn) =>
      (group: UntypedFormGroup): ValidationErrors | null => {
        const hasAtLeastOne =
          group &&
          group.controls &&
          Object.keys(group.controls).some((k) => !validator(group.controls[k]));
        return hasAtLeastOne ? null : { atLeastOne: true };
      };

  /* method to convert into string */
  private convertToString(value: any) {
    return value.join().split(',');
  }

  /* clear notice message */
  public clearMessage(timer: number) {
    setTimeout(() => {
      this.message = this.toasMsgService.resetMessage();
    }, timer);
  }

  ngOnDestroy() {
    if (this.dropDownDataSubs)
      this.dropDownDataSubs.unsubscribe();
  }

}
