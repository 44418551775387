import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchCriteria } from '@data/quick-search/quick-search.interface';
import { environment } from '@env/environment';
import { catchError, throwError } from 'rxjs';
import { ICarrier } from './carrier.interface';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  constructor(
    private _http: HttpClient
  ) { }

  public getCarrier(id: string) {
    return this._http.get<ICarrier>(environment.api + 'carrier/' + id)
      .pipe(
        catchError((error) => { return throwError(() => error); })
      );
  }

  public getCarriers(payload: SearchCriteria) {
    return this._http.post<ICarrier[]>(environment.api + 'carrier/search/', payload)
      .pipe(
        catchError((error) => { return throwError(() => error); })
      );
  }

}
