<div id="phone-dialer-user-setup" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <div cdkDragHandle class="flex justify-between items-baseline pr-4">
        <h2 mat-dialog-title>Call Recording</h2>
        <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>

    <mat-divider></mat-divider>

    <div mat-dialog-content>
        <mat-tab-group>
            <mat-tab label="Dialers">
                <div class="pt-4">
                    <p>We have partnered with Phone.com to provide a call recording solution to meet your current and future compliance needs.</p>
                    
                    <ng-container *ngIf="isLoading; else phoneDialerSetting">
                        <app-sms-spinner></app-sms-spinner>
                    </ng-container>
                    
                    <ng-template #phoneDialerSetting>
                        <form name="phoneDialerUserSettingForm" id="phoneDialerUserSettingForm" [formGroup]="phoneDialerUserSettingForm" class="py-2">
                    
                            <mat-checkbox formControlName="phone_dialer_provider_active">
                                <span><strong>Do not show Phone.com dialer option</strong></span>
                            </mat-checkbox>
                    
                            <ol *ngIf="!phoneDialerUserSettingForm.get('phone_dialer_provider_active')?.value">
                                <li class="py-4">
                                    <p class="py-2">To get started with buit-in call recording, you will need to sign up for an account with Phone.com.</p>
                                    <a mat-button class="signupButton" mat-dialog-close (click)="redirectToDialerLandingPage()">Sign Up for a Phone.com Account</a>
                                </li>
                                <li class="py-4">
                                    <p class="py-2">Once you have a Phone.com account, you can connect it here to allow us to initiate outbound calls through the Phone.com dialer.</p>
                                    <a mat-button class="signupButton" mat-dialog-close (click)="redirectToDialerLandingPage()">Connect your Phone.com Account</a>
                                </li>
                                <li class="py-4">
                                    <p class="py-2">Finally, decide what should happen when clicking on phone numbers</p>
                                    <mat-radio-group formControlName="phone_dialer_provider_behavior_type_id" aria-label="Select an option">
                                        <mat-radio-button class="block" *ngFor="let behaviorType of phoneDialerBehaviorTypes" [value]="behaviorType.phone_dialer_provider_behavior_type_id">
                                            <strong>{{behaviorType.phone_dialer_provider_behavior_type_name}}</strong>
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </li>
                            </ol>
                        </form>
                    </ng-template>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div mat-dialog-actions *ngIf="phoneDialerUserSettingForm.dirty" align="end">
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-flat-button mat-button mat-dialog-close cdkFocusInitial (click)="updateUserPhoneDialerSettings()">Update</button>
    </div>
</div>