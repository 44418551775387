import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@app/service/auth.service';
import { leadUtilityFunctions } from '@data/services/lead/lead-utility-functions';
import { LeadService } from '@data/services/lead/lead.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import moment from 'moment';
import { Subscription, first } from 'rxjs';

@Component({
  selector: 'app-f2f-soa',
  templateUrl: './f2f-soa.component.html',
  styleUrls: ['./f2f-soa.component.scss']
})
export class F2fSoaComponent implements OnInit {
  private getSoAeSignatureSubs:Subscription;
  private getClientInviteSubs:Subscription;
  public f2fForms : FormGroup;
  public minDate : Date;
  public maxDate : string;
  public isButtonClick : boolean = false;
  constructor(
    private fb : FormBuilder,
    private auth: AuthService,
    private dialog: MatDialog,
    private leadService: LeadService,
    private toastMsgService: ToastMsgService,
    public luf : leadUtilityFunctions
  ) {
    this.f2fForms = this.fb.group({
      'aptDate' : ['', Validators.required],
      'contactMethod' : ['', Validators.required],
      'agentSignature' : ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.minDate = new Date();
    this.maxDate = moment(this.minDate).add(1, "year").format("MM/DD/YYYY");
  }

  public checkAptDate(date: string) {
    let futureDate = moment(date).format("MM/DD/YYYY")
    if (moment(futureDate).isAfter(this.maxDate)) {
      this.f2fForms.controls['aptDate'].setErrors({ invalid: true });
    }
  }
  public f2fSOA(){
    if(this.f2fForms.invalid){
      return;
    }else{
      this.isButtonClick = true;
      let fomrData = this.f2fForms.value;
     this.getSoAeSignatureSubs = this.leadService.getSoAeSignature(fomrData.agentSignature).pipe(first()).subscribe({
        next: (res: any) => {
          let user = this.auth.user_details;
          var agentESignatureID = res.esignature_id;
          let lead = this.leadService.lead_details
          let payload = this.luf.generateRequestPayload(user,agentESignatureID,lead,fomrData,'f2f');
          this.getClientInviteSubs = this.leadService.getClientInvite(payload).pipe(first()).subscribe({
            next: (res: any) => {
              this.isButtonClick = false;
              if (res.success == 0) {
                if (user.default_phone.phone) {
									this.toastMsgService.showError('The eSignature Scope Of Appointment was not emailed. ' + res.message);
								} else {
									this.toastMsgService.showError('To send eSOA you need to have a phone number in your agent profile. Click on your name in the upper right hand corner and select "Profile" from the drop down to add your agent phone number');
								}
              }else {
                if (res.remote_invite_url) {
									window.open(res.remote_invite_url);
								}
                //this.toastMsgService.showSuccess('The eSignature Scope Of Appointment was emailed.');
							}
              this.dialog.closeAll();
            }, error: err => {
              this.isButtonClick = false;
              this.toastMsgService.showError(err.error.message);
            }
          });
        }, error: err => {
          this.isButtonClick = false;
          this.toastMsgService.showError(err.error.message);
        }
      });
    }
  }

  public ngOnDestroy(){
    if (this.getSoAeSignatureSubs) this.getSoAeSignatureSubs.unsubscribe();
    if (this.getClientInviteSubs) this.getClientInviteSubs.unsubscribe();
  }

}
