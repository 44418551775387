import { Injectable } from '@angular/core';
import { throwError, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/service/auth.service';
import moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  private eventSubject = new Subject<any>();
  private deleteSubject = new Subject<any>();
  private titleSubject = new Subject<any>();
  constructor(private http: HttpClient, private auth: AuthService) { }

  public sendEventData(data: any) {
    this.eventSubject.next(data);
  }

  public getEventData(): Observable<any> {
    return this.eventSubject.asObservable();
  }

  public sendDeleteData(data: any) {
    this.deleteSubject.next(data);
  }

  public getDeleteData(): Observable<any> {
    return this.deleteSubject.asObservable();
  }

  public sendTitle(data: any) {
    this.titleSubject.next(data);
  }

  public getTitle(): Observable<any> {
    return this.titleSubject.asObservable();
  }

  public getCalendarEvents(data: any) {
    let criteria:any;
    if (data.type == 'calendar') {
      criteria = {
        "criteria": {
          "user_id": {
            "type": "simple",
            "value": [this.auth.getToken().user_id.toString()]
          },
          "start_time": {
            "type": "range",
            "value": [data.startDate, data.endDate]
          }
        },
        "field_list": "",
        "page_number": 1
      }
    } else if (data.type == 'appointment') {
      criteria = {
        "criteria": {
          "end_time": { type: "range", value: [data.selectedDate, data.selectedDate] },
          "start_time": { type: "range", value: [data.selectedDate, data.selectedDate] }
        },
        "orderby_direction": "desc",
      }

    } else if (data.type == 'task') {
      criteria = {
        "criteria":{
          "completed_date": { type: "simple", value: [""] },
          "end_time": { type: "simple", value: [""] },
          "start_time": { type: "range", value: [data.selectedDate, data.selectedDate] }
        },
        "orderby_direction": "asc",
      }
    } else if (data.type == 'unscheduled-task') {
      criteria = {
        "criteria": {
          "completed_date": { type: "simple", value: [""] },
          "start_time": { type: "simple", value: [data.selectedDate] }
        },
        "orderby_direction": "asc",
      }
    }
    else {
      criteria = {
        "criteria": {
          "user_id": {
            "type": "simple",
            "value": [this.auth.getToken().user_id.toString()]
          },
          "end_time": {
            "type": "simple",
            "value": [""]
          },
          "completed_date": {
            "type": "simple",
            "value": [""]
          }
        },
        "page_number": 1,
        "orderby_direction": "asc"
      }
    }
    criteria['search_type'] = "simple";
    if (data.type == 'appointment' || data.type == 'task' || data.type == 'unscheduled-task'){
      criteria['field_list'] = "lead_id,fname,lname,user_id,appointment_id,allday_flag,title,appointment_type_id,description,start_time,end_time,importance,completed_date";
    }else{
      criteria['field_list'] = "";
    }
    if(data.type != 'calendar'){criteria["orderby_field"] ="start_time";  }
    return this.http.post(
      environment.api + 'user/' + this.auth.getToken().user_id + '/appointment/search', criteria).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public addAppointment(params: any) {
    return this.http.post((environment.api) + `user/` + params.user_id + `/appointment/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public addLeadAppointment(params: any) {
    return this.http.post((environment.api) + `lead/${params.lead_id}/appointment/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public editAppointment(params: any, appointment_id: string) {
    return this.http.post((environment.api) + `appointment/` + appointment_id, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public leadSearch(params: any) {
    return this.http.post((environment.api) + `lead/search/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public deleteEvent(params: any) {
    return this.http.get((environment.api) + `appointment/` + params + `/delete/`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public dismissEvent(id: any) {
    let params = { completed_date: moment(new Date()).format("YYYY-MM-DD hh:mm") }
    return this.http.post((environment.api) + `appointment/` + id, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }
}
